// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { makeStyles, Grid, Tooltip, Typography, TextField, Icon } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { usePlansGroup } from './PlansGroupProvider';

import PlansGroupBundleItem from './PlansGroupBundleItem';

import { ReactComponent as IconLyft } from '../../static/lyft.svg';
import { ReactComponent as IconUber } from '../../static/uber.svg';
import { ReactComponent as IconHD } from '../../static/bunny.svg';

// -------------------------------- COMPONENT -------------------------------- //

export default function PlansGroupBundleList({ drivers = [], moves = [], plans = [], regions = [] }) {
  const cls = useStyles();

  const {
    selectedPlanDate,
    selectedPlanTime,
    setSelectedPlanTime,
    selectedDriverIds,
    selectedMoveIds,
    selectedBundles,
    setSelectedBundles,
    rideActive,
    setRideActive,
    rideFirst,
    setRideFirst,
    rideType,
    setRideType,
    getDefaultPlanTime,
  } = usePlansGroup();

  // Reset the planning time when the plan date changes
  React.useEffect(() => {
    const defaultPlanTime = getDefaultPlanTime(plans);
    setSelectedPlanTime(defaultPlanTime);
  }, [selectedPlanDate]);

  React.useEffect(() => {
    if (selectedDriverIds?.length || selectedMoveIds?.length) {
      const groupSize = Math.max(selectedDriverIds?.length, selectedMoveIds?.length);

      const selectedDrivers = drivers?.filter(d => selectedDriverIds?.includes(d?.id));
      const selectedMoves = moves?.filter(m => selectedMoveIds?.includes(m?.id));

      let newSelectedBundles = [];

      for (let i = 0; i < groupSize; i++) {
        const driver = selectedDrivers?.[i] || null;
        const move = selectedMoves?.[i] || null;
        const plan = plans?.find(p => p?.driver_id === driver?.id) || null;
        const region = regions?.find(r => r?.id === driver?.region_id) || null;

        newSelectedBundles[i] = {
          driver,
          move,
          plan,
          region,
        };
      }

      setSelectedBundles(newSelectedBundles);
    } else {
      setSelectedBundles([]);
    }

    // Conditionally reset the planning time if it's before the default time
    const defaultPlanTime = getDefaultPlanTime(plans);
    if (dayjs(selectedPlanTime).isBefore(defaultPlanTime)) setSelectedPlanTime(defaultPlanTime);

    // eslint-disable-next-line
  }, [selectedDriverIds, selectedMoveIds]);

  const handleTimeChange = event => {
    const value = event?.target?.value;
    const formattedValue = dayjs(`${selectedPlanDate} ${value}`, `YYYY-MM-DD HH:mm`).format();

    if (value && formattedValue !== `Invalid Date`) {
      const defaultPlanTime = getDefaultPlanTime(plans).format();
      if (formattedValue < defaultPlanTime) return setSelectedPlanTime(defaultPlanTime);
      setSelectedPlanTime(formattedValue);
    }
  };

  const handleToggleRide = event => {
    if (rideActive && rideFirst) {
      setRideActive(true);
      setRideFirst(false);
    }
    if (rideActive && !rideFirst) {
      setRideActive(false);
      setRideFirst(false);
    }
    if (!rideActive) {
      setRideActive(true);
      setRideFirst(true);
    }
  };

  const handleToggleRideType = event => {
    if (rideType === `auto`) setRideType(`lyft`);
    if (rideType === `lyft`) setRideType(`uber`);
    // if (rideType === `uber`) setRideType(`hopdrive`); // for when we add hopdrive to the rideshare mix
    if (rideType === `uber`) setRideType(`auto`);
    if (rideType === `hopdrive`) setRideType(`auto`);
  };

  /** Get ride button CSS classes */
  const getRideBtnClasses = () => {
    let classes = [cls.btn];

    if (rideType === `lyft`) classes.push(cls.btnLyft);
    else if (rideType === `uber`) classes.push(cls.btnUber);
    else if (rideType === `hopdrive`) classes.push(cls.btnHD);
    else classes.push(cls.btnAuto);

    return clsx(classes);
  };

  /** Get ride button border CSS classes */
  const getRideBtnBorderClasses = () => {
    let classes = [cls.btn];

    if (rideType === `lyft`) classes.push(cls.btnBorderLyft);
    else if (rideType === `uber`) classes.push(cls.btnBorderUber);
    else if (rideType === `hopdrive`) classes.push(cls.btnBorderHD);
    else classes.push(cls.btnBorderAuto);

    return clsx(classes);
  };

  /** Get the rideshare partner's icon */
  const RidePartnerIcon = () => {
    if (rideType === `lyft`) return <IconLyft className={cls.iconLyft} />;
    if (rideType === `uber`) return <IconUber className={cls.iconUber} />;
    if (rideType === `hopdrive`) return <IconHD className={cls.iconHD} />;
    return <Icon className={cls.iconAuto}>hdr_auto</Icon>;
  };

  /** Get bundle list footer CSS classes */
  const getBundleFooterClasses = () => {
    let classes = [cls.bundleListFooter];

    if (selectedDriverIds?.length !== selectedMoveIds?.length || selectedBundles?.length < 2) {
      classes.push(cls.bundleListFooterWarning);
    } else {
      classes.push(cls.bundleListFooterSuccess);
    }

    return clsx(classes);
  };

  /** Get the bundle footer icon */
  const getBundleFooterIcon = () => {
    if (selectedDriverIds?.length !== selectedMoveIds?.length || selectedBundles?.length < 2) {
      return `warning`;
    }
    return `check_circle`;
  };

  /** Get the bundle footer text */
  const getBundleFooterText = () => {
    if (selectedDriverIds?.length < selectedMoveIds?.length) {
      return `Select ${selectedMoveIds?.length - selectedDriverIds?.length} more driver${
        selectedMoveIds?.length - selectedDriverIds?.length > 1 ? `s` : ``
      }...`;
    }

    if (selectedDriverIds?.length > selectedMoveIds?.length) {
      return `Select ${selectedDriverIds?.length - selectedMoveIds?.length} more move${
        selectedDriverIds?.length - selectedMoveIds?.length > 1 ? `s` : ``
      }...`;
    }

    if (selectedDriverIds?.length === selectedMoveIds?.length && selectedBundles?.length < 2) {
      return `Select at least 2 drivers and 2 moves...`;
    }

    return `Group is ready to be saved!`;
  };

  return (
    <div className={cls.bundleList}>
      {selectedBundles?.length ? (
        <>
          <div className={cls.bundleListToolbar}>
            <div className={cls.bundleListToolbarContent}>
              <Grid container spacing={1} alignItems='center'>
                <Tooltip
                  title={`Change planned time (Earliest possible time is ${getDefaultPlanTime(plans).format(`LT`)})`}
                  placement='top-start'
                >
                  <Grid item xs>
                    <TextField
                      style={{ cursor: `pointer` }}
                      required
                      fullWidth
                      name='time'
                      type='time'
                      size='small'
                      variant='outlined'
                      value={dayjs(selectedPlanTime).format(`HH:mm`)}
                      onChange={handleTimeChange}
                    />
                  </Grid>
                </Tooltip>

                <Tooltip title='Change ride partner' placement='top'>
                  <Grid item>
                    <Button className={getRideBtnClasses()} onClick={handleToggleRideType}>
                      <RidePartnerIcon />
                    </Button>
                  </Grid>
                </Tooltip>

                <Tooltip title='Change ride position' placement='top'>
                  <Grid item>
                    <Button className={getRideBtnBorderClasses()} variant='outlined' onClick={handleToggleRide}>
                      {rideActive ? (rideFirst ? `Ride First` : `Ride Last`) : `No Ride`}
                    </Button>
                  </Grid>
                </Tooltip>
              </Grid>
            </div>

            <div className={cls.bundleListToolbarFade} />
          </div>

          <div className={cls.bundleListBody}>
            {selectedBundles?.map((b, i) => (
              <React.Fragment key={`plans-group-bundle-item-${i}`}>
                <PlansGroupBundleItem driver={b?.driver} move={b?.move} plan={b?.plan} region={b?.region} index={i} />
                <div className={cls.bundleListBodyDivider} />
              </React.Fragment>
            ))}
          </div>

          <div className={getBundleFooterClasses()}>
            <Icon className={cls.bundleListFooterIcon}>{getBundleFooterIcon()}</Icon>
            <Typography className={cls.bundleListFooterTxt}>{getBundleFooterText()}</Typography>
          </div>

          <div className={cls.bundleListBodyDivider} />
        </>
      ) : (
        <Typography className={cls.notFoundTxt}>No Drivers or Moves Selected</Typography>
      )}
    </div>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  bundleList: {
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  bundleListToolbar: {
    zIndex: 99,
    position: 'sticky',
    top: 0,
    width: '100%',
    height: 56,
  },
  bundleListToolbarContent: {
    width: '100%',
    height: 48,
    padding: 8,
    paddingBottom: 0,
    backgroundColor: theme.palette.background.paper,
  },
  bundleListToolbarFade: {
    width: '100%',
    height: 4,
    background: `linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.background.paper}00)`,
  },

  bundleListBody: {
    position: 'relative',
  },
  bundleListBodyDivider: {
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.dividerStrong,
  },

  bundleListFooter: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: 16,
  },
  bundleListFooterSuccess: {
    color: theme.palette.success.main,
  },
  bundleListFooterWarning: {
    color: theme.palette.warning.main,
  },
  bundleListFooterIcon: {
    marginRight: 8,
    fontSize: 24,
  },
  bundleListFooterTxt: {
    fontSize: 16,
    fontWeight: 500,
  },

  time: {
    width: '100%',
  },
  timeSelect: {
    height: 18,
    padding: theme.spacing(1, 1),
  },

  btn: {
    height: 40,
  },
  btnAuto: {
    background: `${theme.palette.auto.main} !important`,
  },
  btnLyft: {
    background: `${theme.palette.lyft.main} !important`,
  },
  btnUber: {
    background: `${theme.palette.uber.main} !important`,
  },
  btnHD: {
    background: `${theme.palette.primary.main} !important`,
  },
  btnBorderAuto: {
    borderColor: `${theme.palette.auto.main} !important`,
    color: `${theme.palette.auto.main} !important`,
  },
  btnBorderLyft: {
    borderColor: `${theme.palette.lyft.main} !important`,
    color: `${theme.palette.lyft.main} !important`,
  },
  btnBorderUber: {
    borderColor: `${theme.palette.uber.main} !important`,
    color: `${theme.palette.uber.main} !important`,
  },
  btnBorderHD: {
    borderColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
  },

  iconAuto: {
    display: 'block',
    fontSize: 24,
  },
  iconLyft: {
    display: 'block',
    height: 22,
  },
  iconUber: {
    display: 'block',
    height: 16,
  },
  iconHD: {
    display: 'block',
    height: 20,
  },

  notFoundTxt: {
    paddingTop: 32,
    paddingBottom: 32,
    lineHeight: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));
