// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles, TextField, MenuItem, InputAdornment, Icon, Typography, CircularProgress } from '@material-ui/core';

import { useLazyQuery } from '@apollo/client';
import { GET_CHASE_DRIVERS } from './gql';

import DriverAvatar from '../../components/DriverComponents/DriverAvatar';

// COMPONENT ---------------------------------------------------------------- //

export default function GPFormVehicleDriver({ formData, setFormData }) {
  const cls = useStyles();

  const [driverId, setDriverId] = React.useState(0);
  const [drivers, setDrivers] = React.useState([]);

  /** Lazy fetch */
  const [fetchDrivers, { loading, error }] = useLazyQuery(GET_CHASE_DRIVERS);

  /** Handle changing the driver ID */
  const handleDriverIdChange = e => {
    const newDriverId = e?.target?.value;

    setDriverId(newDriverId);
    setFormData({ ...formData, vehicleDriverId: newDriverId || 0 });
  };

  /** Handle fetching drivers */
  const handleFetchDrivers = async () => {
    try {
      const res = await fetchDrivers();
      if (res?.data?.drivers) setDrivers([...res?.data?.drivers]);
    } catch (err) {
      console.error(`Failed to fetch drivers:`, err);
    }
  };

  /** Handle opening the dropdown */
  const handleFocus = () => handleFetchDrivers();

  return (
    <div className={cls.root}>
      <label className={cls.textFieldLabel} htmlFor='driverId'>
        Who will be driving the chase vehicle (W-2 only)?<span className={cls.textFieldLabelRequired}> *</span>
      </label>

      <TextField
        loading={loading}
        disabled={loading || error}
        required
        select
        fullWidth
        name='driverId'
        variant='outlined'
        size='small'
        value={driverId}
        onChange={handleDriverIdChange}
        onFocus={handleFocus}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <Icon color='disabled' fontSize='small'>
                  face
                </Icon>
              )}
            </InputAdornment>
          ),
        }}
      >
        <MenuItem value={0}>
          <em className={cls.placeholder}>{loading ? `Loading...` : error ? `Error!` : `Select Chase Driver`}</em>
        </MenuItem>

        {drivers?.map(driver => (
          <MenuItem className={cls.menuItem} key={driver?.id} value={driver?.id}>
            <DriverAvatar driver={driver} />
            <Typography className={cls.driverName}>
              {driver?.user?.display_name} ({driver?.id})
            </Typography>
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    width: `100%`,
    paddingBottom: theme.spacing(4),
  },

  textFieldLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  textFieldLabelRequired: {
    color: theme.palette.error.main,
  },

  placeholder: {
    color: theme.palette.text.disabled,
  },
  menuItem: {
    display: `flex`,
    alignItems: `center`,
    gap: 12,
  },
}));
