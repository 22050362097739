// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import { makeStyles, useTheme, Typography, Icon } from '@material-ui/core';

import { getInitialsFromName } from '../../utils/GeneralUtils';
import {
  getDisplayNameFromDriver,
  getAvatarURLFromDriver,
  getPlanStatusColorFromDriverAndPlan,
} from '../../utils/DriverUtils';

// -------------------------------- COMPONENT -------------------------------- //

export default function DriverAvatar({ driver, plan, isSelected, ...props }) {
  const cls = useStyles();
  const theme = useTheme();

  return (
    <div className={cls.avatar} {...props}>
      {getAvatarURLFromDriver(driver) ? (
        <img
          className={cls.image}
          src={getAvatarURLFromDriver(driver)}
          alt={getDisplayNameFromDriver(driver) || `Unknown Driver`}
        />
      ) : (
        <div className={cls.image}>
          <Typography className={cls.initialsTxt}>
            {getInitialsFromName({ driver_name: getDisplayNameFromDriver(driver) })}
          </Typography>
        </div>
      )}

      {isSelected ? (
        <div className={cls.selected}>
          <Icon className={cls.selectedIcon}>check</Icon>
        </div>
      ) : null}

      <div
        className={cls.badge}
        style={{ background: getPlanStatusColorFromDriverAndPlan(driver, plan, null, theme) }}
      />
    </div>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  avatar: {
    position: 'relative',
    width: 48,
    height: 48,
  },
  image: {
    position: 'relative',
    objectFit: 'cover',
    width: 48,
    height: 48,
    borderRadius: '50%',
    background: theme.palette.fade[3],
  },
  initialsTxt: {
    position: 'absolute',
    top: '15%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    cursor: 'default',
    userSelect: 'none',
  },

  selected: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: '50%',
    background: '#000000a0',
  },
  selectedIcon: {
    fontSize: 24,
    color: theme.palette.text.contrast,
    textShadow: '0px 0px 4px #000000',
  },

  badge: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: `15px`,
    height: `15px`,
    border: '3px solid white',
    borderRadius: '50%',
    background: theme.palette.success.main,
  },
}));
