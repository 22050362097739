// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core';

import { useLazyQuery } from '@apollo/client';
import { GET_DATA_FOR_GROUP_PLANNING } from './gql';

import Timeline from '../../components/TimelineComponents/Timeline';
import TimelineToolbarBasic from '../../components/TimelineComponents/TimelineToolbarBasic';
import Toolbar from '../../components/Toolbar';

import { buildDraftOrder, buildChasePlan, convertPlanToComponentFormat } from './utils';

// COMPONENT ---------------------------------------------------------------- //

// Must take in formData props from location state (from /group-planning/form)

// Example move config
// const moveConfig = {
//   chaseVehicleCapacity: 10,
// };

// Example chase vehicle config
// const chaseVehicleConfig = {
//   loadId: "JH49D2",
// };

export default function GPTimeline({ location }) {
  const cls = useStyles();

  const {
    capacity,
    customerId,
    date,
    driverIds,
    editMode,
    endTime,
    laneIds,
    organizationId,
    regionId,
    startTime,
    vehicleDeliveryLocationId,
    vehicleDriverId,
    vehicleId,
    vehiclePickupLocationId,
  } = location?.state || {};
  console.log(`STATE:`, location?.state);

  const [timelineZoom, setTimelineZoom] = React.useState(1);
  const [plans, setPlans] = React.useState([]);

  const markers = [
    { id: `start`, label: `Start Time`, time: dayjs(`${date}T${startTime}:00`).format() },
    { id: `end`, label: `End Time`, time: dayjs(`${date}T${endTime}:00`).format() },
  ];

  const [getDataForGroupPlanning] = useLazyQuery(GET_DATA_FOR_GROUP_PLANNING, {
    variables: {
      driverIds: [vehicleDriverId, ...driverIds],
      laneIds,
      vehicleId,
      start: dayjs().subtract(1, `week`).startOf(`day`).format(),
      end: dayjs().add(1, `week`).endOf(`day`).format(),
    },
  });

  const fetchPlans = async () => {
    try {
      const res = await getDataForGroupPlanning();
      if (res) {
        console.log(`pre-draft: `, res?.data?.moves, res?.data?.chaseVehicle);
        const vehicleDriver = res?.data?.drivers?.find(driver => driver?.id === vehicleDriverId) || {};
        const drivers = res?.data?.drivers?.filter(driver => driver?.id !== vehicleDriverId) || [];
        const moves = res?.data?.moves || [];
        const chaseVehicle = res?.data?.chaseVehicle[0] || {};
        const draftOrder = buildDraftOrder(moves);
        draftOrder.map(move => console.log(`move lane: `, move.lane));
        const builtPlans = buildChasePlan(vehicleDriver, drivers, draftOrder, chaseVehicle);
        const convertedPlans = convertPlanToComponentFormat(builtPlans);

        setPlans(convertedPlans);
      }
    } catch (err) {
      console.log(`Failed to fetch drivers by driver ids:`, err);
    }
  };

  React.useEffect(() => {
    fetchPlans();
  }, [driverIds]);

  return (
    <div className={cls.root}>
      <div className={cls.toolbarWrapper}>
        <Toolbar back fullscreen shadow title={`Group Planning`} />
      </div>

      <div className={cls.toolbarPad} />

      <Timeline
        data={plans}
        dataType='static-plan'
        date={date}
        earliestTime={markers[0]?.time}
        header={<TimelineToolbarBasic title='Timeline' zoom={timelineZoom} setZoom={setTimelineZoom} />}
        latestTime={markers[1]?.time}
        markers={markers}
        zoom={timelineZoom}
      />
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },

  toolbarWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 300,
  },
  toolbarPad: {
    width: '100%',
    height: 56,
  },
}));
