//////////////////////// DEPENDENCIES ////////////////////////

import axios from 'axios';
import sdk from '@hopdrive/sdk';
import { toast } from 'react-toastify';
import { useTheme, makeStyles, Icon } from '@material-ui/core';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { useData } from '../../providers/DataProvider';
import { useTools } from '../../hooks/useTools';
import { getUserToken } from '../../utils/authHelper'

import {
  UPDATE_MOVE_RIDE_TYPE,
  UPSERT_RIDE,
  UPDATE_ATTEMPT_AND_MOVE_STATUS,
  UPDATE_ACTIVE_ATTEMPT_FAILED,
} from './gql';

import { ReactComponent as IconLyft } from '../../static/lyft.svg';
import { ReactComponent as IconUber } from '../../static/uber2.svg';
import { ReactComponent as IconHD } from '../../static/bunny.svg';

import { SimpleLogger } from '../../utils/SimpleLogger';
const { log } = new SimpleLogger({ prefix: 'useManageRide', enabled: true });

//////////////////////// HOOK ////////////////////////

export default function useManageRide() {
  const theme = useTheme();
  const cls = useStyles();
  const { capFirst } = useTools();

  const ctx = useData();
  const { getFormattedStatusFromRide, getFormattedRidesharePartner } = useTools();

  //////////////////////// FRONT-END ////////////////////////

  // Get the ride brand color based on partner
  const getRideColor = (ridePartner = `auto`) => {
    if (ridePartner === `lyft`) return theme.palette.lyft.main;
    if (ridePartner === `uber`) return theme.palette.uber.main;
    if (ridePartner === `hopdrive`) return theme.palette.primary.main;
    return theme.palette.auto.main;
  };

  // Get the lyftride status icon to show in the loading component
  const getRideStatusIcon = rideStatus => {
    if (rideStatus === `estimating`) return `attach_money`;
    if (rideStatus === `estimated`) return `price_check`;
    if (rideStatus === `deciding`) return `call_split`;
    if (rideStatus === `calling`) return `phone_in_talk`;
    return `more_horiz`;
  };

  // Get the attempt status color based on status
  const getAttemptStatusColor = (attemptStatus = null) => {
    if (attemptStatus === `accepted` || attemptStatus === `arrived` || attemptStatus === `pickedUp`)
      return theme.palette.warning.main;
    if (attemptStatus === `droppedOff`) return theme.palette.success.main;
    if (attemptStatus === `canceled` || attemptStatus === `failed`) return theme.palette.error.main;
    return theme.palette.text.disabled;
  };

  // Get the lyftride status icon to show in the loading component
  const getAttemptStatusIcon = (attemptStatus = null) => {
    if (attemptStatus === `estimating`) return `attach_money`;
    if (attemptStatus === `estimated`) return `price_check`;
    if (attemptStatus === `accepted` || attemptStatus === `arrived`) return `assignment`;
    if (attemptStatus === `pickedUp`) return `assignment_returned`;
    if (attemptStatus === `droppedOff`) return `assignment_turned_in`;
    if (attemptStatus === `canceled`) return `do_not_disturb`;
    if (attemptStatus === `failed`) return `cancel`;
    return `more_horiz`;
  };

  // Icon component for rideshare partners
  const RideIcon = (ridePartner = `auto`, classType) => {
    let className;
    if (classType === `btn`) className = cls[`btnIcon${getFormattedRidesharePartner(ridePartner)}`];

    if (ridePartner === `lyft`) return <IconLyft className={className} />;
    if (ridePartner === `uber`) return <IconUber className={className} />;
    if (ridePartner === `hopdrive`) return <IconHD className={className} />;
    return <Icon className={className}>hdr_auto</Icon>;
  };

  // Get style of component based on partner
  const getActionStyle = (ridePartner = `auto`, disabled) => {
    let background = theme.palette.action.disabledBackground;
    let color = theme.palette.action.disabled;

    if (!disabled) {
      background = getRideColor(ridePartner);
      color = theme.palette.text.contrast;
    }

    return { background, color };
  };

  // Get driver ids by looking at movesByLyftTriggerId
  const getDriverIds = move => {
    let driverIds = [];

    if (move && move.driver_id) driverIds.push(move.driver_id);
    if (move && move.movesByLyftTriggerId) {
      move.movesByLyftTriggerId.forEach(triggerMove => {
        if (triggerMove.driver_id && !driverIds.includes(triggerMove.driver_id)) driverIds.push(triggerMove.driver_id);
      });
    }

    return driverIds;
  };

  // Build a data object that is used to render the correct component
  const buildDataObject = (input = null, subData = null, selectedAttemptId = null) => {
    if (!input || !subData) return {};

    // Move & Lane
    const rideMove = getPropValue(input, `move`) || {};
    let pickup = getPropValue(rideMove, `lane.pickup`) || {};
    pickup.type = `db_loc`;
    pickup.point = `pickup`;
    let delivery = getPropValue(rideMove, `lane.delivery`) || {};
    delivery.type = `db_loc`;
    delivery.point = `delivery`;

    // Lyftride & Override
    const lyftride = subData.lyftrides[0] || null;
    let overrideLoc = null;
    if (lyftride) {
      overrideLoc = {
        id: `overrideLoc`,
        type: `override_loc`,
        name: `Driver Pickup`,
        latitude: lyftride.latitude,
        longitude: lyftride.longitude,
      };
    }

    // Attempts
    const attempts = getPropValue(lyftride, `attempts`) || [];
    const activeAttempt = getPropValue(lyftride, `activeAttempt`) || null;
    const recentAttempt =
      getPropValue(lyftride, `attempts`) && lyftride.attempts.length > 0 ? lyftride.attempts[0] : null;
    const currentAttempt = activeAttempt || recentAttempt || null;
    const selectedAttempt = attempts.find(attempt => attempt.id === selectedAttemptId) || {};

    // Attempt Lane
    let attemptPickup = null;
    let attemptDelivery = null;
    if (currentAttempt) {
      attemptPickup = {
        id: `attemptPickup`,
        type: `rideshare_loc`,
        point: `pickup`,
        name: `${capFirst(getPropValue(currentAttempt, `ride_type`))} Pickup`,
        address: getPropValue(currentAttempt, `rideshare_pickup_address`),
        latitude: getPropValue(currentAttempt, `rideshare_pickup_latitude`),
        longitude: getPropValue(currentAttempt, `rideshare_pickup_longitude`),
      };
      attemptDelivery = {
        id: `attemptDelivery`,
        type: `rideshare_loc`,
        point: `delivery`,
        name: `${capFirst(getPropValue(currentAttempt, `ride_type`))} Drop-Off`,
        address: getPropValue(currentAttempt, `rideshare_delivery_address`),
        latitude: getPropValue(currentAttempt, `rideshare_delivery_latitude`),
        longitude: getPropValue(currentAttempt, `rideshare_delivery_longitude`),
      };
    }

    // Statuses
    const rideStatus = getPropValue(lyftride, `status`) || null;
    const attemptStatus = getPropValue(currentAttempt, `status`) || null;

    return {
      rideMove,
      pickup,
      delivery,

      lyftride,
      overrideLoc,

      attempts,
      activeAttempt,
      recentAttempt,
      currentAttempt,
      selectedAttempt,

      attemptPickup,
      attemptDelivery,

      rideStatus,
      attemptStatus,
    };
  };

  //////////////////////// FUNCTIONS ////////////////////////

  /** Update the active ride partner */
  const updateRidePartner = async (ridePartner = `auto`, rideMoveId) => {
    let rideTypeSuccess = false;
    try {
      await ctx.apolloClient
        .mutate({
          mutation: UPDATE_MOVE_RIDE_TYPE,
          variables: { rideMoveId: rideMoveId, rideType: ridePartner },
        })
        .then(res => {
          if (getPropValue(res, `data.update_moves.affected_rows`) === 1) {
            log(
              `Successfully changed ride type to '${ridePartner}'!`,
              getPropValue(res, `data.update_moves.returning`)
            );
            rideTypeSuccess = true;
          } else {
            console.error(`Failed to update ride_type to '${ridePartner}':`, res);
            toast.error(`Failed to update ride_type to '${ridePartner}'!`);
          }
        })
        .catch(err => {
          console.error(`Failed to update ride_type to '${ridePartner}':`, err);
          toast.error(`Failed to update ride_type to '${ridePartner}'!`);
        });
    } catch (err) {
      console.error(`Failed to update ride_type to '${ridePartner}':`, err);
      toast.error(`Failed to update ride_type to '${ridePartner}'!`);
    }
    return rideTypeSuccess;
  };

  /** Call the ride based on the partner */
  const manuallyCallRide = async (ridePartner = `auto`, rideMoveId) => {
    toast.info(`Calling ${getFormattedRidesharePartner(ridePartner)} ride...`, { autoClose: 2000 });

    let rideTypeSuccess = updateRidePartner(ridePartner, rideMoveId);

    if (rideTypeSuccess) {
      log(`Attempting to call ${getFormattedRidesharePartner(ridePartner)}...`);
      try {
        await ctx.apolloClient
          .mutate({
            mutation: UPSERT_RIDE,
            variables: { rideMoveId: rideMoveId },
          })
          .then(res => {
            log(`Successfully called ${getFormattedRidesharePartner(ridePartner)}! Awaiting response...`);
            toast.success(`${getFormattedRidesharePartner(ridePartner)} ride called! Awaiting response...`);
          })
          .catch(err => {
            console.error(`Failed to insert ${getFormattedRidesharePartner(ridePartner)} ride:`, err);
            toast.error(`Failed to insert ${getFormattedRidesharePartner(ridePartner)} ride!`);
          });
      } catch (err) {
        console.error(`Failed to insert ${getFormattedRidesharePartner(ridePartner)} ride:`, err);
        toast.error(`Failed to insert ${getFormattedRidesharePartner(ridePartner)} ride!`);
      }
    }
  };

  /** Cancel the ride and tell the rideshare partner */
  const manuallyCancelRide = async (ridePartner = `auto`, rideshareId) => {
    if (
      window.confirm(
        `Are you sure you want to cancel this ${getFormattedRidesharePartner(
          ridePartner
        )}? This action cannot be undone and may incur a cancellation fee.`
      )
    ) {
      try {
        const token = await getUserToken();
        await axios({
          method: 'POST',
          url: '/.netlify/functions/handleCancelRideHail',
          data: {
            rideshareId: rideshareId,
            rideType: ridePartner,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
          .then(res => {
            log(`Successfully sent request to cancel ${getFormattedRidesharePartner(ridePartner)} ride:`, res.data);
            toast.success(`Successfully sent request to cancel ${getFormattedRidesharePartner(ridePartner)} ride!`);
          })
          .catch(err => {
            console.error(`Failed to cancel ${getFormattedRidesharePartner(ridePartner)} ride:`, err);
            toast.error(`Failed to cancel ${getFormattedRidesharePartner(ridePartner)}!`);
          });
      } catch (err) {
        console.error(`Failed to cancel ${getFormattedRidesharePartner(ridePartner)} ride:`, err);
        toast.error(`Failed to cancel ${getFormattedRidesharePartner(ridePartner)}!`);
      }
    }
  };

  /** Fail the ride */
  const manuallyFailRide = async (ridePartner = `auto`, rideMoveId, activeAttemptId) => {
    if (
      window.confirm(
        `Are you sure this ${getFormattedRidesharePartner(
          ridePartner
        )} ride has failed to complete? This action cannot be undone and should only be used as a last resort.`
      )
    ) {
      ctx.apolloClient
        .mutate({
          mutation: UPDATE_ACTIVE_ATTEMPT_FAILED,
          variables: {
            rideMoveId: rideMoveId,
            attemptId: activeAttemptId,
          },
        })
        .then(res => {
          if (res.data.update_lyftrideattempts) {
            toast.success(`Successfully set current ride to failed!`);
          }
        })
        .catch(err => {
          console.error(`Failed to set current ride attempt to failed:`, err);
          toast.error(`Failed to set current ride attempt to failed: ${err.message}`);
        });
    }
  };

  /** Update the ride status */
  const manuallyChangeStatus = async (status, attempt) => {
      try {
        const attemptObj = `{
          updatedat: "now()",
          status: "${status}",
          ride_cost: ${attempt.ride_cost},
          ride_duration: ${attempt.ride_duration},
          ride_distance: ${attempt.ride_distance},
          rideshare_delivery_latitude: ${attempt.rideshare_delivery_latitude},
          rideshare_delivery_longitude: ${attempt.rideshare_delivery_longitude},
          rideshare_delivery_address: "${attempt.rideshare_delivery_address}"
        }`;
        const moveObj = `{
          ride_type: "lyft",
          updatedat: "now()",
          status: "${status}",
          delivery_arrived: "now()",
          delivery_successful: "now()"
        }`;
        const tripSystemUpdate = await sdk.ridehail.updateTripSystem(attemptObj, moveObj, attempt.rideshare_id);
        if (tripSystemUpdate.success) {
          toast.success(`Successfully set current ride status to '${getFormattedStatusFromRide({ status })}'!`);
        } else {
          toast.error(`Failed to update ride status!`);
        }
      } catch (err) {
        console.error(`Failed to Update Ride Status:`, err);
        toast.error(`Failed to update ride status!`)
      }

      if (status === 'droppedOff') {
        try {
          const rideAccessorialsInsert = await sdk.accessorials.applyRideAccessorials(attempt.rideshare_id);
          if (rideAccessorialsInsert.success) {
            toast.success(`Successfully applied ride accessorials!`);
          } else {
            toast.error(`Failed to apply ride accessorials!`);
          }
        } catch (err) {
          console.error(`Failed to apply ride accessorials:`, err);
        }
      }
  };

  return {
    getRideColor,
    getRideStatusIcon,
    getAttemptStatusColor,
    getAttemptStatusIcon,
    getDriverIds,
    RideIcon,
    getActionStyle,
    buildDataObject,
    updateRidePartner,
    manuallyCallRide,
    manuallyCancelRide,
    manuallyFailRide,
    manuallyChangeStatus,
  };
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  btnIconLyft: {
    minHeight: 24,
    maxHeight: 24,
    marginRight: theme.spacing(1),
  },
  btnIconUber: {
    minHeight: 24,
    maxHeight: 24,
    marginRight: theme.spacing(1),
  },
  btnIconHopDrive: {
    minHeight: 20,
    maxHeight: 20,
    marginRight: theme.spacing(1),
  },
  btnIconAuto: {
    fontSize: 24,
    marginRight: theme.spacing(1),
  },
}));
