// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import Step from './Step';

// COMPONENT -------------------------------------------------- //

export default function StepGroup({ step = {} }) {
  const cls = useStyles();

  const { id, label, description, steps } = step;

  return (
    <div className={cls.label}>
      <div className={cls.title}>{label ? <Typography className={cls.titleTxt}>{label}</Typography> : null}</div>

      {label && description ? <Spacer size='xs' /> : null}

      <div className={cls.subtitle}>
        {description ? <Typography className={cls.subtitleTxt}>{description}</Typography> : null}
      </div>

      {label || description ? <Spacer size='xl' /> : null}

      {steps && steps?.length
        ? steps?.map((step, i) => {
            return (
              <React.Fragment key={`step-id-${step?.id}`}>
                <Step step={step} />
              </React.Fragment>
            );
          })
        : null}
    </div>
  );
}

// COMPONENT -------------------------------------------------- //
const useStyles = makeStyles(theme => ({
  title: {
    display: 'block',
  },
  titleTxt: {
    display: 'inline',
    fontSize: 21,
    fontWeight: 600,
  },

  subtitle: {
    display: 'block',
  },
  subtitleTxt: {
    display: 'inline',
    fontSize: 16,
    fontWeight: 400,
  },
}));
