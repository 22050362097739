// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Icon, Typography } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import { useLazyQuery } from '@apollo/client';
import { GET_CHASE_VEHICLES } from './gql';

import useGroupPlanning from './useGroupPlanning';

import { Modal } from '../../components/ModalComponents';

import AddNewAssignedChaseVehicleModal from '../ChaseVehicles/AddNewAssignedChaseVehicleModal';

// COMPONENT ---------------------------------------------------------------- //

export default function GPFormVehicle({ formData, setFormData }) {
  const cls = useStyles();

  const { getVehicleName, getVehicleVin, getVehicleCapacity } = useGroupPlanning();

  const [vehicleId, setVehicleId] = React.useState(0);
  const [vehicles, setVehicles] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);

  /** Lazy fetch */
  const [fetchVehicles, { loading, error }] = useLazyQuery(GET_CHASE_VEHICLES, {
    variables: {
      start: dayjs().subtract(1, `week`).startOf(`day`).format(),
      end: dayjs().add(1, `week`).endOf(`day`).format(),
    },
  });

  /** Handle changing the vehicle ID */
  const handleVehicleIdChange = id => {
    const foundVehicle = vehicles?.find(vehicle => vehicle?.id === id);

    setVehicleId(id);
    setFormData({
      ...formData,
      capacity: foundVehicle?.chasevehicle?.capacity || foundVehicle?.capacity || 1,
      customerId: foundVehicle?.customer?.id || 0,
      organizationId: foundVehicle?.organization?.id || 0,
      regionId: foundVehicle?.region?.id || 0,
      vehicleId: id || 0,
    });
  };

  /** Handle fetching vehicles */
  const handleFetchVehicles = async () => {
    try {
      const res = await fetchVehicles();
      if (res?.data?.chasevehiclesassigned) setVehicles([...res?.data?.chasevehiclesassigned]);
    } catch (err) {
      console.error(`Failed to fetch vehicles:`, err);
    }
  };

  /** Handle opening the modal */
  const handleModalOpen = () => setModalOpen(true);

  // Fetch vehicles on mount
  React.useEffect(() => {
    handleFetchVehicles();
  }, []);

  return (
    <div className={cls.root}>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} width='sm'>
        <AddNewAssignedChaseVehicleModal onClose={() => setModalOpen(false)} />
      </Modal>

      <label className={cls.textFieldLabel} htmlFor='vehicleId'>
        Which chase vehicle will be used for this group?<span className={cls.textFieldLabelRequired}> *</span>
      </label>

      <div className={cls.container}>
        {loading ? (
          <Loading position='absolute' />
        ) : (
          <div className={cls.list}>
            {vehicles?.map(vehicle => (
              <div key={vehicle?.id} className={cls.item} onClick={() => handleVehicleIdChange(vehicle?.id)}>
                <div>
                  <Typography className={cls.itemTxt1}>{getVehicleName(vehicle)}</Typography>
                  <Typography className={cls.itemTxt2}>
                    {vehicle?.plan_date ? dayjs(vehicle?.plan_date).format(`MM/DD/YYYY`) : `No Date Specified`}
                  </Typography>
                  <Typography className={cls.itemTxt2}>
                    {vehicle?.customer ? vehicle?.customer?.name : `Unknown Customer`}
                  </Typography>
                  <Typography className={cls.itemTxt2}>
                    {vehicle?.region ? vehicle?.region?.name : `Unknown Region`}
                  </Typography>
                </div>

                <div className={cls.badge1}>
                  <Typography className={cls.badgeTxt}>{getVehicleVin(vehicle)}</Typography>
                </div>

                <div className={cls.badge2}>
                  <Typography className={cls.badgeTxt}>Capacity: {getVehicleCapacity(vehicle)}</Typography>
                </div>

                {vehicleId === vehicle?.id ? (
                  <div className={cls.selected}>
                    <Icon className={cls.selectedIcon}>check</Icon>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div>

      <Typography className={cls.link} onClick={handleModalOpen}>
        Click here to assign a vehicle from existing chase vehicles
      </Typography>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    width: `100%`,
    paddingBottom: theme.spacing(4),
  },

  textFieldLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  textFieldLabelRequired: {
    color: theme.palette.error.main,
  },

  placeholder: {
    color: theme.palette.text.disabled,
  },
  menuItem: {
    display: `flex`,
    flexDirection: `column`,
    alignItems: `flex-start`,
  },

  menuItemTxt1: {
    lineHeight: 1,
    fontSize: 16,
  },
  menuItemTxt2: {
    marginTop: 4,
    lineHeight: 1,
    fontSize: 12,
    color: theme.palette.text.secondary,
  },

  container: {
    position: `relative`,
    overflowY: `auto`,
    width: `100%`,
    minHeight: `80px`,
    maxHeight: `480px`,
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
  },

  list: {
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    flexWrap: `wrap`,
    gap: theme.spacing(1),
    width: `100%`,
    padding: theme.spacing(2),
  },
  item: {
    position: `relative`,
    overflow: `hidden`,
    display: `flex`,
    alignItems: `center`,
    gap: theme.spacing(1),
    flex: `1 1 360px`,
    height: `fit-content`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.dark,
    cursor: `pointer`,
    transition: `all 0.1s`,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  itemTxt1: {
    lineHeight: 1.25,
    fontSize: 14,
    userSelect: `none`,
  },
  itemTxt2: {
    marginTop: 4,
    lineHeight: 1,
    fontSize: 12,
    userSelect: `none`,
    color: theme.palette.text.secondary,
  },

  badge1: {
    position: `absolute`,
    top: 6,
    right: 6,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.fade[3],
  },
  badge2: {
    position: `absolute`,
    top: 30,
    right: 6,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.fade[3],
  },
  badgeTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.contrastPrimary,
  },

  selected: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '#000000a0',
  },
  selectedIcon: {
    fontSize: 24,
    color: theme.palette.text.contrast,
    textShadow: '0px 0px 4px #000000',
  },

  link: {
    width: `fit-content`,
    marginTop: 4,
    fontSize: 12,
    color: theme.palette.info.main,
    cursor: `pointer`,
    '&:hover': {
      textDecoration: `underline`,
    },
  },
}));
