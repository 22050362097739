import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, Column } from '../../components/TableComponents';
import { Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import EditChaseVehicleContent from './EditChaseVehicleModal';
import DeleteChaseVehicleContent from './DeleteChaseVehicleModal';
import { useTools } from '../../hooks/useTools';
import { Modal } from '../../components/ModalComponents';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export default function ChaseVehicleTable({ chaseVehicles }) {
  const cls = useStyles();
  const [editOpen, setEditOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [editInput, setEditInput] = React.useState(null);
  const [deleteInput, setDeleteInput] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [filteredChaseVehicles, setFilteredChaseVehicles] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedVehicle, setSelectedVehicle] = React.useState(null);

  const { goToRoute } = useTools();

  const handleEditOpen = chaseVehicle => {
    setEditOpen(true);
    setEditInput(chaseVehicle);
  };

  const handleDeleteOpen = chaseVehicle => {
    setDeleteOpen(true);
    setDeleteInput(chaseVehicle);
  };

  const handleLocalFilters = () => {
    let filteredData = [...chaseVehicles];

    if (chaseVehicles?.length) {
      if (search?.length) {
        const searchLower = search.toLowerCase();
        filteredData = filteredData?.filter(
          fd =>
            `${fd?.vin}`?.toLowerCase().includes(searchLower) ||
            `${fd?.make}`?.toLowerCase().includes(searchLower) ||
            `${fd?.model}`?.toLowerCase().includes(searchLower) ||
            `${fd?.capacity}`?.toLowerCase().includes(searchLower)
        );
      }
    }

    setFilteredChaseVehicles(filteredData);
  };

  const handleMenuOpen = (event, vehicle) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedVehicle(vehicle);
  };

  const handleMenuClose = event => {
    event?.stopPropagation();
    setAnchorEl(null);
    setSelectedVehicle(null);
  };

  React.useEffect(() => {
    handleLocalFilters();
  }, [chaseVehicles, search]);

  return (
    <div className={cls.wrapper}>
      <Table
        data={filteredChaseVehicles}
        defaultOrderBy={'vin'}
        defaultOrder={'asc'}
        tableAriaLabel={`chase-vehicles`}
        stickyHeader={true}
        search={search}
        setSearch={setSearch}
        clickableRows={true}
        onRowClick={chaseVehicle => goToRoute(`/chase-vehicles/${chaseVehicle?.id}`)}
      >
        <Column name='vin' label='Vin' />
        <Column name='make' label='Make' />
        <Column name='model' label='Model' />
        <Column name='capacity' label='Capacity' />

        <Column
          name='actions'
          label='Actions'
          align='right'
          renderer={chaseVehicle => (
            <>
              <IconButton size='small' onClick={e => handleMenuOpen(e, chaseVehicle)}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                keepMounted
                id={`chase-vehicle-actions-menu`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && selectedVehicle?.id === chaseVehicle.id}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={e => {
                    handleEditOpen(chaseVehicle);
                    handleMenuClose(e);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={e => {
                    handleDeleteOpen(chaseVehicle);
                    handleMenuClose(e);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </>
          )}
        />
      </Table>
      <Modal open={editOpen} width='sm'>
        <EditChaseVehicleContent input={editInput} onClose={() => setEditOpen(false)} />
      </Modal>
      <Modal open={deleteOpen} width='sm'>
        <DeleteChaseVehicleContent input={deleteInput} onClose={() => setDeleteOpen(false)} />
      </Modal>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    overflowY: `auto`,
    borderBottom: theme.border[0],
    backgroundColor: theme.palette.background.paper,
  },

  btn: {
    marginRight: theme.spacing(1),
  },
}));
