import React from 'react';
import { Table, Column } from '../../components/TableComponents';

export default function AssignedChaseVehicleTable({ chaseVehicles }) {
  const [search, setSearch] = React.useState(``);
  const [filteredChaseVehicles, setFilteredChaseVehicles] = React.useState([]);

  const capitalizeFirst = value => {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : value;
  };

  const handleLocalFilters = () => {
    let filteredData = [...chaseVehicles];

    if (chaseVehicles?.length) {
      if (search?.length) {
        const searchLower = search.toLowerCase();
        filteredData = filteredData?.filter(
          fd =>
            `${fd?.vin}`?.toLowerCase().includes(searchLower) ||
            `${fd?.make}`?.toLowerCase().includes(searchLower) ||
            `${fd?.model}`?.toLowerCase().includes(searchLower) ||
            `${fd?.capacity}`?.toLowerCase().includes(searchLower) ||
            `${fd?.customer_id}`?.toLowerCase().includes(searchLower) ||
            `${fd?.first_move_id}`?.includes(searchLower) ||
            `${fd?.last_move_id}`?.includes(searchLower)
        );
      }
    }

    setFilteredChaseVehicles(filteredData);
  };

  React.useEffect(() => {
    handleLocalFilters();
  }, [chaseVehicles, search]);

  return (
    <div>
      <Table
        data={filteredChaseVehicles}
        tableAriaLabel={`assigned-chase-vehicles`}
        stickyHeader={true}
        search={search}
        setSearch={setSearch}
      >
        <Column name='status' label='Status' value={chaseVehicle => capitalizeFirst(chaseVehicle?.status)} />
        <Column name='vin' label='Vin' />
        <Column name='make' label='Make' />
        <Column name='model' label='Model' />
        <Column name='capacity' label='Capacity' />
        <Column name='first_move_id' label='First Move ID' />
        <Column name='last_move_id' label='Last Move ID' />
        <Column name='customer_id' label='Customer ID' />
        <Column name='organization_id' label='Organization ID' />
        <Column name='region_id' label='Region ID' />
      </Table>
    </div>
  );
}
