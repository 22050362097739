//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { makeStyles, Grid, Icon, Tooltip, Typography } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import useManageRide from './useManageRide';

import { OverrideStatusModal } from './index';

//////////////////////// COMPONENT ////////////////////////

export default function ManageRideActions({ input, attempt, partner, noAction = false, loading, error }) {
  const cls = useStyles();

  const { getFormattedRidesharePartner } = useTools();

  // State management
  const [disabled, setDisabled] = React.useState(false);
  const [action, setAction] = React.useState(null);

  const { RideIcon, getActionStyle, manuallyCallRide, manuallyCancelRide, manuallyFailRide, manuallyChangeStatus } =
    useManageRide();

  const checkDisabled = () => {
    if (loading || error) return true;
    return false;
  };

  //////////////////////// OVERRIDE MODAL ////////////////////////

  const [overrideModal, setOverrideModal] = React.useState({ open: false, input: null, output: null });
  const handleOverrideModalOpen = (input = null) => {
    setOverrideModal({ ...overrideModal, open: true, input: input });
  };
  const handleOverrideModalClose = async (output = null, attempt = {}) => {
    if (output) {
      setDisabled(true);
      setAction(`status`);
      await manuallyChangeStatus(output.status, attempt);
      setAction(null);
      setDisabled(false);
      setOverrideModal({ ...overrideModal, open: false, output: output });
    } else setOverrideModal({ ...overrideModal, open: false, output: null });
  };

  //////////////////////// RENDER - ACTIONS ////////////////////////

  // Manually call the ride
  const ManualCallAction = () => {
    if (
      partner &&
      getPropValue(input, `move.id`) &&
      getPropValue(attempt, `status`) !== `accepted` &&
      getPropValue(attempt, `status`) !== `arrived` &&
      getPropValue(attempt, `status`) !== `pickedUp` &&
      getPropValue(attempt, `status`) !== `droppedOff`
    ) {
      return (
        <Tooltip title={`Manually call the ride for the driver now.`} placement='top'>
          <Grid item>
            <Button
              disabled={disabled || noAction || checkDisabled()}
              loading={action === `call`}
              onClick={async () => {
                setDisabled(true);
                setAction(`call`);
                await manuallyCallRide(partner, input.move.id);
                setAction(null);
                setDisabled(false);
              }}
              style={getActionStyle(partner, disabled || noAction)}
            >
              {RideIcon(partner, `btn`)}
              Call&nbsp;{getFormattedRidesharePartner(partner)}&nbsp;Ride
            </Button>
          </Grid>
        </Tooltip>
      );
    }
    return null;
  };

  // Manually cancel the ride attempt
  const ManualCancelAction = () => {
    if (
      partner &&
      getPropValue(input, `move.id`) &&
      getPropValue(attempt, `id`) &&
      (attempt.status === `pending` || attempt.status === `accepted` || attempt.status === `arrived`)
    ) {
      return (
        <Tooltip title={`Manually cancel the latest ride attempt.`} placement='top'>
          <Grid item>
            <Button
              disabled={disabled || noAction || checkDisabled()}
              loading={action === `cancel`}
              color='error'
              onClick={async () => {
                setDisabled(true);
                setAction(`cancel`);
                await manuallyCancelRide(partner, attempt.rideshare_id);
                setAction(null);
                setDisabled(false);
              }}
            >
              <Icon className={cls.btnIcon}>do_not_disturb</Icon>
              Cancel&nbsp;{attempt.ride_type}&nbsp;Ride
            </Button>
          </Grid>
        </Tooltip>
      );
    }
    return null;
  };

  // Manually fail the ride attempt
  const ManualFailAction = () => {
    if (
      partner &&
      getPropValue(input, `move.id`) &&
      getPropValue(attempt, `id`) &&
      attempt.status !== `canceled` &&
      attempt.status !== `failed` &&
      attempt.status !== `pending` &&
      attempt.status !== `accepted` &&
      attempt.status !== `arrived`
    ) {
      return (
        <Tooltip title={`Manually fail the latest ride attempt.`} placement='top'>
          <Grid item>
            <Button
              disabled={disabled || noAction || checkDisabled()}
              loading={action === `fail`}
              color='error'
              onClick={async () => {
                setDisabled(true);
                setAction(`fail`);
                await manuallyFailRide(partner, input.move.id, attempt.id);
                setAction(null);
                setDisabled(false);
              }}
            >
              <Icon className={cls.btnIcon}>cancel</Icon>
              Mark&nbsp;as&nbsp;Failed
            </Button>
          </Grid>
        </Tooltip>
      );
    }
    return null;
  };

  // Manually set the ride attempt and move status
  const ManualUpdateStatusAction = () => {
    if (getPropValue(input, `move.id`) && getPropValue(attempt, `id`)) {
      return (
        <Tooltip title={`Manually set the latest ride attempt status.`} placement='top'>
          <Grid item>
            <Button
              disabled={disabled || noAction || checkDisabled()}
              loading={action === `status`}
              color='secondary'
              onClick={() => handleOverrideModalOpen()}
            >
              <Icon className={cls.btnIcon}>build</Icon>
              Override&nbsp;Status
            </Button>
          </Grid>
        </Tooltip>
      );
    }
    return null;
  };

  //////////////////////// RENDER - RETURN ////////////////////////

  return (
    <>
      <Typography className={cls.titleTxt}>Take an Action</Typography>
      <Typography className={cls.subtitleTxt}>Use the actions below to help manage the ride.</Typography>

      <Spacer size='xs' />

      <Grid container spacing={2}>
        <ManualCallAction />
        <ManualCancelAction />
        <ManualFailAction />
        <ManualUpdateStatusAction />
      </Grid>

      {/* MODALS */}
      <OverrideStatusModal open={overrideModal.open} onClose={handleOverrideModalClose} attempt={attempt}/>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  titleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  subtitleTxt: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  btnIcon: {
    marginRight: theme.spacing(1),
  },
}));
