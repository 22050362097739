//////////////////////// DEPENDENCIES ////////////////////////

import React, { Fragment } from 'react';
import { useMutation } from '@apollo/client';
import { useNotificationsGlobals } from '../../../providers/NotificationsProvider';
import { UPDATE_NOTIFICATION_ASSIGNMENT, UPDATE_NOTIFICATION_RESOLVED } from '../gql';
import dayjs from 'dayjs';
import { makeStyles, Icon, Typography, Grid, Chip } from '@material-ui/core';
import { toast } from 'react-toastify';
import Spacer from '../../../components/Spacer';
import { useTools } from '../../../hooks/useTools';
import { Link } from 'react-router-dom';
import { ClickAwayListener } from '@mui/base';
import { Button } from '@hopdrive/storybook';
import Loading from '../../Loading';
import { getUserEmail } from '../../../utils/authHelper';

//////////////////////// COMPONENT ////////////////////////

export default function NotificationDrawer({
  notificationDrawerOpen,
  setNotificationDrawerOpen,
  loading,
  data,
  error,
  filteredNotifications,
  setFilteredNotifications,
  userEmail,
}) {
  const cls = useStyles();
  const { goToNotificationDetails } = useTools();
  const [updateNotificationAssignment] = useMutation(UPDATE_NOTIFICATION_ASSIGNMENT);
  const [updateNotificationResolved] = useMutation(UPDATE_NOTIFICATION_RESOLVED);
  const { addToReadList, isDueDatePast, setIsDueDatePast, checkDueDate } = useNotificationsGlobals();

  const sortedNotifications = filteredNotifications.sort((a, b) => {
    let ta = a.type;
    let tb = b.type;

    if (ta < tb) {
      return -1;
    }

    if (ta > tb) {
      return 1;
    }

    return 0;
  });

  React.useEffect(() => {
    if (checkDueDate && sortedNotifications && filteredNotifications) {
      sortedNotifications.map(note => {
        checkDueDate(note);
        // console.log(note.overdue)
      });
    } else return loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filteredNotifications]);


  const handleClickAway = () => {
    setNotificationDrawerOpen(false);
  };

  const updateNotification = async notification => {
    try {
      const variables = {
        id: notification.id,
        assignedto: userEmail || null,
        assignedby: userEmail || null,
        assignedat: dayjs().format() || null,
      };
      const res = await updateNotificationAssignment({ variables });
      if (res && res.data && res.data.update_notifications && res.data.update_notifications.affected_rows > 0) {
        toast.success(`Successfully updated notification!`);
      }
    } catch (err) {
      toast.error(`Failed to update notification!`);
      console.error(`Failed to update notification:`, err);
    }
  };

  const updateNotificationResolution = async notification => {
    try {
      const variables = {
        id: notification.id,
        resolvedby: userEmail,
        resolvedat: dayjs().format(),
      };
      const res = await updateNotificationResolved({ variables });
      if (res && res.data && res.data.update_notifications && res.data.update_notifications.affected_rows > 0) {
        toast.success(`Successfully updated notification!`);
      }
    } catch (err) {
      toast.error(`Failed to update notification!`);
      console.error(`Failed to update notification:`, err);
    }
  };

  const setReadGoToDetails = notification => {
    addToReadList(notification.id);
    setNotificationDrawerOpen(false);
    setFilteredNotifications([]);
    goToNotificationDetails(notification.id);
  };

  if (loading) return <Loading fixed />;

  return (
    <>
      {loading ? null : (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={cls.drawer}>
            <div className={cls.notificationBox}>
              <div className={cls.titleBox}>
                <Link to='/notifications'>
                  <Typography className={cls.tableLink} style={{ fontWeight: '400' }}>
                    <Icon>arrow_forward</Icon>
                    <Spacer column />
                    View All Notifications
                  </Typography>
                </Link>
              </div>

              {sortedNotifications.map((notification, index) => (
                <div className={cls.row} key={notification.id}>
                  <Grid container>
                    <Grid item xs={2} onClick={() => setReadGoToDetails(notification)}>
                      <Typography style={{ color: '#323232' }}>
                        {notification.type === 'action' ? (
                          <Icon style={{ color: 'red' }}>pending_actions</Icon>
                        ) : (
                          <Icon style={{ color: '#3371FF' }}>inform_icon</Icon>
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '11rem' }}
                      onClick={() => setReadGoToDetails(notification)}
                    >
                      <Typography noWrap style={{ color: '#323232', fontWeight: 'bold' }}>
                        {notification.title}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={2} onClick={() => setReadGoToDetails(notification)}></Grid>
                    <Grid
                      item
                      xs={10}
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '11rem' }}
                      onClick={() => setReadGoToDetails(notification)}
                    >
                      <Typography noWrap style={{ color: '#323232' }}>
                        {notification.body}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container style={{ justifyContent: 'center' }}>
                    {notification.duedate ? (
                      <Grid item xs={3} style={{ textAlign: 'center' }}>
                        {notification.overdue ? (
                          <Chip
                            style={{ marginTop: '10px' }}
                            variant={'outlined'}
                            size='small'
                            color={'primary'}
                            label={'Overdue'}
                          />
                        ) : (
                          <Chip
                            style={{ marginTop: '10px' }}
                            variant={'outlined'}
                            size='small'
                            label={'Due     ' + dayjs(notification.duedate).format('MM/DD')}
                          />
                        )}
                        &nbsp;&nbsp;
                      </Grid>
                    ) : null}
                    {notification.assignedto === null &&
                    notification.resolvedby === null &&
                    notification.type === 'action' ? (
                      <Grid item xs={9} style={{ textAlign: 'center' }}>
                        <Button
                          style={{ marginTop: '5px', fontSize: '12px' }}
                          color='primary'
                          onClick={() => updateNotification(notification)}
                        >
                          Accept Assignment
                        </Button>
                      </Grid>
                    ) : notification.assignedto === userEmail && notification.resolvedby === null ? (
                      <Grid item xs={9} style={{ textAlign: 'center' }}>
                        <Button
                          style={{ marginTop: '5px', fontSize: '12px' }}
                          color='#850101'
                          fontSize='12px'
                          onClick={() => updateNotificationResolution(notification)}
                        >
                          Mark As Resolved
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </div>
              ))}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  searchbar: {
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  drawer: {
    zIndex: 250,
    position: 'absolute',
    display: 'flex',
    top: theme.spacing(5),
    right: theme.spacing(0),
    padding: theme.spacing(2),
    width: 'auto',
    height: '500%',
  },

  notificationBox: {
    position: 'relative',
    flex: 1,
    minWidth: 300,
    maxWidth: 300,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    borderRadius: theme.shape.paperRadius,
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
  titleBox: {
    zIndex: 251,
    position: 'sticky',
    top: 0,
    borderBottom: theme.border[0],
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  titleTxt: {
    lineHeight: 2,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 500,
    color: '#323232',
  },
  closeIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },
  tableLink: {
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
    fontWeight: 'bold',
    color: 'black',
  },
  row: {
    position: 'relative',
    padding: theme.spacing(1),
    borderBottom: theme.border[0],
    borderRight: theme.border[0],
    background: theme.palette.background.paper,
    '&:hover, &:focus': {
      background: theme.palette.action.hover,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
}));
