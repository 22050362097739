// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { useMutation, useQuery } from '@apollo/client';

import Spacer from '../../components/Spacer';
import { ModalContent, ModalHeader, ModalFooter, ModalAction } from '../../components/ModalComponents';
import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';

import { ADD_CHASE_VEHICLE_ASSIGNMENT, GET_CHASE_VEHICLES, GET_CUSTOMERS, GET_REGIONS } from './gql';

// COMPONENT ---------------------------------------------------------------- //

export default function AddNewAssignedChaseVehicleModal({ onClose }) {
  const cls = useStyles();

  const [date, setDate] = React.useState(dayjs().format(`YYYY-MM-DD`));
  const [selectedChaseVehicle, setSelectedChaseVehicle] = React.useState(``);
  const [selectedCustomer, setSelectedCustomer] = React.useState(``);
  const [selectedRegion, setSelectedRegion] = React.useState(``);

  const [chaseVehicles, setChaseVehicles] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [regions, setRegions] = React.useState([]);

  const {
    data: chaseVehiclesData,
    loading: chaseVehiclesLoading,
    error: chaseVehiclesError,
  } = useQuery(GET_CHASE_VEHICLES);
  const { data: customersData, loading: customersLoading, error: customersError } = useQuery(GET_CUSTOMERS);
  const { data: regionsData, loading: regionsLoading, error: regionsError } = useQuery(GET_REGIONS);

  React.useEffect(() => {
    if (chaseVehiclesData) {
      setChaseVehicles(chaseVehiclesData.chasevehicles);
    }
  }, [chaseVehiclesData]);

  React.useEffect(() => {
    if (customersData) {
      setCustomers(customersData.customers);
    }
  }, [customersData]);

  React.useEffect(() => {
    if (regionsData) {
      setRegions(regionsData.regions);
    }
  }, [regionsData]);

  const [assignChaseVehicle] = useMutation(ADD_CHASE_VEHICLE_ASSIGNMENT, {
    onCompleted: () => {
      onClose();
    },
  });

  const handleDateChange = date => {
    const newDate = dayjs(date).format(`YYYY-MM-DD`);
    setDate(newDate);
  };

  const handleAssign = React.useCallback(() => {
    assignChaseVehicle({
      variables: {
        input: {
          capacity: selectedChaseVehicle?.capacity || 0,
          chase_vehicle_id: selectedChaseVehicle?.id || null,
          customer_id: selectedCustomer?.id || null,
          make: selectedChaseVehicle?.make || null,
          model: selectedChaseVehicle?.model || null,
          organization_id: selectedCustomer?.organization_id || null,
          plan_date: date || null,
          region_id: selectedRegion?.id || selectedCustomer?.location?.region_id || null,
          status: 'received',
          vin: selectedChaseVehicle?.vin || null,
        },
      },
    });
  }, [date, selectedChaseVehicle, selectedCustomer, selectedRegion]);

  if (customersLoading || regionsLoading) return <DefaultLoadingFallback message={`Fetching data...`} />;

  if (customersError || regionsError) {
    console.error(customersError || regionsError);
    return <DefaultErrorFallback message={`Error fetching data!`} />;
  }

  return (
    <>
      <ModalHeader handleClose={onClose}>Assign Chase Vehicle</ModalHeader>

      <ModalContent>
        <Typography>Select an existing chase vehicle to assign to a customer or region.</Typography>

        <Spacer />

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={cls.date}
            required
            autoOk
            name='date'
            minDate={dayjs().format()}
            format='MM/dd/yyyy'
            orientation='portrait'
            variant='inline'
            inputVariant='outlined'
            size='small'
            label='Plan Date'
            value={dayjs().format()}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>

        <Spacer />

        <Autocomplete
          id='chase-vehicle-select'
          options={chaseVehicles}
          getOptionLabel={option => `${option.vin} - ${option.make} ${option.model}` || ''}
          value={chaseVehicles.find(chaseVehicle => chaseVehicle.id === selectedChaseVehicle.id) || null}
          onChange={(event, newValue) => {
            setSelectedChaseVehicle(newValue ? newValue : '');
          }}
          renderInput={params => (
            <TextField {...params} required label='Vehicle' variant='outlined' size='small' fullWidth />
          )}
        />

        <Spacer />

        <Autocomplete
          id='customer-select'
          options={customers}
          getOptionLabel={option => option.name || ''}
          value={customers.find(customer => customer.id === selectedCustomer.id) || null}
          onChange={(event, newValue) => {
            setSelectedCustomer(newValue ? newValue : '');
          }}
          renderInput={params => (
            <TextField {...params} required label='Customer' variant='outlined' size='small' fullWidth />
          )}
        />

        <Spacer />

        <Autocomplete
          id='region-select'
          options={regions}
          getOptionLabel={option => option.name || ''}
          value={regions.find(region => region.id === selectedRegion.id) || null}
          onChange={(event, newValue) => {
            setSelectedRegion(newValue ? newValue : '');
          }}
          renderInput={params => (
            <TextField {...params} required label='Region' variant='outlined' size='small' fullWidth />
          )}
        />
      </ModalContent>

      <ModalFooter>
        <ModalAction
          color='primary'
          disabled={!selectedChaseVehicle || (!selectedCustomer && !selectedRegion)}
          onClick={handleAssign}
        >
          Assign
        </ModalAction>

        <ModalAction onClick={onClose}>Cancel</ModalAction>
      </ModalFooter>
    </>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  date: {
    width: `100%`,
  },
}));
