// DEPENDENCIES -------------------------------------------------- //

import React from 'react';

import { Spacer } from '@hopdrive/storybook';

import StepGroup from './StepGroup';
import StepInput from './StepInput';
import StepSelect from './StepSelect';
import StepMultibutton from './StepMultibutton';

import { getValueByStepId } from '@hopdrive/workflows';
import { useWorkflow } from './WorkflowProvider';

// COMPONENT -------------------------------------------------- //

export default function Step({ step = {} }) {
  const { workflowSteps, handleStepHide } = useWorkflow();

  // Check if we need to hide the component before rendering it
  if (!handleStepHide || (handleStepHide && !handleStepHide(step))) {
    // Get the step value from the workflow steps if it has one
    const overrideValue = getValueByStepId(workflowSteps, step?.id);

    // Read the step type and render the correct component
    switch (step?.type) {
      // GROUP //
      case 'group':
        return (
          <>
            <StepGroup step={step} />
            <Spacer size='xl' />
          </>
        );

      // INPUT //
      case 'input':
        return (
          <>
            <StepInput step={step} overrideValue={overrideValue} />
            <Spacer size='xl' />
          </>
        );

      // MULTIBUTTON //
      case 'multibutton':
        return (
          <>
            <StepMultibutton step={step} overrideValue={overrideValue} />
            <Spacer size='xl' />
          </>
        );

      // SELECT //
      case 'select':
        return (
          <>
            <StepSelect step={step} overrideValue={overrideValue} />
            <Spacer size='xl' />
          </>
        );

      // DEFAULT //
      // Always render an input when all else fails
      default:
        return (
          <>
            <StepInput step={step} overrideValue={overrideValue} />
            <Spacer size='xl' />
          </>
        );
    }
  }

  // Fallback to render nothing
  return null;
}
