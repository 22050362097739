// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { makeStyles, Typography, Icon } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import { useLazyQuery } from '@apollo/client';
import { GET_MOVE_LANES_BY_CUSTOMER, GET_MOVE_LANES_BY_REGION } from './gql';

const maxLanes = 10;

// COMPONENT ---------------------------------------------------------------- //

export default function GPFormLanes({ formData, setFormData }) {
  const cls = useStyles();

  const [laneIds, setLaneIds] = React.useState([]);
  const [lanes, setLanes] = React.useState([]);

  /** Lazy fetch */
  const [fetchMoveLanes, { loading, error }] = useLazyQuery(
    formData?.customerId ? GET_MOVE_LANES_BY_CUSTOMER : GET_MOVE_LANES_BY_REGION
  );

  /** Handle adding a lane ID */
  const handleLaneIdAdd = id => {
    const newLaneIds = [...laneIds, id];
    if (newLaneIds?.length > maxLanes) {
      toast.warning(`You cannot assign more lanes than ${maxLanes}!`);
      return;
    }
    handleSetState(newLaneIds);
  };

  /** Handle removing a lane ID */
  const handleLaneIdRemove = id => {
    const newLaneIds = laneIds.filter(laneId => laneId !== id);
    handleSetState(newLaneIds);
  };

  /** Handle setting lanes state */
  const handleSetState = newLaneIds => {
    setLaneIds(newLaneIds);
    setLanes(() =>
      lanes?.sort((a, b) => {
        if (newLaneIds?.includes(a?.id) && !newLaneIds?.includes(b?.id)) return -1;
        if (!newLaneIds?.includes(a?.id) && newLaneIds?.includes(b?.id)) return 1;
        if (a?.pickup?.name?.toLowerCase() < b?.pickup?.name?.toLowerCase()) return -1;
        if (a?.pickup?.name?.toLowerCase() > b?.pickup?.name?.toLowerCase()) return 1;
        if (a?.delivery?.name?.toLowerCase() < b?.delivery?.name?.toLowerCase()) return -1;
        if (a?.delivery?.name?.toLowerCase() > b?.delivery?.name?.toLowerCase()) return 1;
        return 0;
      })
    );
    setFormData({ ...formData, laneIds: newLaneIds });
  };

  /** Handle clicking a lane */
  const handleLaneClick = id => {
    if (laneIds?.includes(id)) handleLaneIdRemove(id);
    else handleLaneIdAdd(id);
  };

  /** Handle fetching move lanes */
  const handleFetchMoveLanes = async () => {
    try {
      let variables = formData?.customerId ? { customerId: formData?.customerId } : { regionId: formData?.regionId };
      variables = {
        ...variables,
        start: dayjs().subtract(1, `week`).startOf(`day`).format(),
        end: dayjs().add(1, `week`).endOf(`day`).format(),
      };
      const res = await fetchMoveLanes({ variables });
      if (res?.data?.moves) {
        let foundLaneIds = res?.data?.moves?.map(move => move?.lane?.id);
        foundLaneIds = [...new Set(foundLaneIds)];

        let foundLanes = foundLaneIds?.map(laneId => {
          return {
            ...res?.data?.moves?.find(move => move?.lane?.id === laneId)?.lane,
            moveIds: res?.data?.moves?.filter(move => move?.lane?.id === laneId)?.map(move => move?.id),
          };
        });
        foundLanes = foundLanes?.filter(Boolean);
        foundLanes = foundLanes?.sort((a, b) => {
          if (a?.pickup?.name < b?.pickup?.name) return -1;
          if (a?.pickup?.name > b?.pickup?.name) return 1;
          if (a?.delivery?.name < b?.delivery?.name) return -1;
          if (a?.delivery?.name > b?.delivery?.name) return 1;
          return 0;
        });

        setLanes([...foundLanes]);
      }
    } catch (err) {
      console.error(`Failed to fetch lanes from moves:`, err);
    }
  };

  // Fetch move lanes on mount
  React.useEffect(() => {
    handleFetchMoveLanes();
  }, []);

  // Clear, then refetch move lanes on vehicle change
  React.useEffect(() => {
    setLaneIds([]);
    setLanes([]);
    handleFetchMoveLanes();
  }, [formData?.vehicleId]);

  return (
    <div className={cls.root}>
      <label className={cls.label}>
        Which lanes would you like to assign to this group?<span className={cls.required}> *</span>
      </label>

      <div className={cls.container}>
        {loading ? (
          <Loading position='absolute' />
        ) : (
          <div className={cls.list}>
            {lanes?.map(lane => (
              <div key={lane?.id} className={cls.item} onClick={() => handleLaneClick(lane?.id)}>
                <div>
                  <Typography className={cls.itemTxt1}>{lane?.pickup?.name}</Typography>
                  <Typography className={cls.itemTxt1}>to {lane?.delivery?.name}</Typography>
                  <Typography className={cls.itemTxt2}>
                    {Math.round((lane?.duration_sec || 0) / 60)} min | {lane?.distance_miles || 0} miles
                  </Typography>
                </div>

                {lane?.moveIds?.length ? (
                  <div className={cls.badge}>
                    <Typography className={cls.badgeTxt}>
                      {lane?.moveIds?.length} Move{lane?.moveIds?.length === 1 ? `` : `s`}
                    </Typography>
                  </div>
                ) : null}

                {laneIds?.includes(lane?.id) ? (
                  <div className={cls.selected}>
                    <Icon className={cls.selectedIcon}>check</Icon>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: `relative`,
    width: `100%`,
    paddingBottom: theme.spacing(4),
  },

  label: {
    fontSize: 14,
    fontWeight: 600,
  },
  required: {
    color: theme.palette.error.main,
  },

  container: {
    position: `relative`,
    overflowY: `auto`,
    width: `100%`,
    minHeight: `80px`,
    maxHeight: `480px`,
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
  },

  list: {
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    flexWrap: `wrap`,
    gap: theme.spacing(1),
    width: `100%`,
    padding: theme.spacing(2),
  },
  item: {
    position: `relative`,
    overflow: `hidden`,
    display: `flex`,
    alignItems: `center`,
    gap: theme.spacing(1),
    flex: `1 1 360px`,
    height: `fit-content`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.dark,
    cursor: `pointer`,
    transition: `all 0.1s`,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  itemTxt1: {
    lineHeight: 1.25,
    fontSize: 14,
    userSelect: `none`,
  },
  itemTxt2: {
    marginTop: 4,
    lineHeight: 1,
    fontSize: 12,
    userSelect: `none`,
    color: theme.palette.text.secondary,
  },

  badge: {
    position: `absolute`,
    top: 6,
    right: 6,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.fade[3],
  },
  badgeTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.contrastPrimary,
  },

  selected: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '#000000a0',
  },
  selectedIcon: {
    fontSize: 24,
    color: theme.palette.text.contrast,
    textShadow: '0px 0px 4px #000000',
  },
}));
