import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { ExportToCsv } from 'export-to-csv';
import dayjs from 'dayjs';

import Loading from '../../components/Loading';
import { DefaultEmptyFallback, DefaultErrorFallback } from '../../components/Fallbacks';
import { AccordionTable, AccordionRow, TableSort } from '../../components/AccordionTable';

import { GET_ACCESSORIALS } from './gql';
import { useTools } from '../../hooks/useTools';
import AccessorialReportFooter from './AccessorialReportFooter';

const log = false;

const defaultOrder = `desc`;
const defaultOrderBy = `ID`;

const cap = str => {
  if (str) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
};

const checkNeg = num => {
  if (num > 0) return num;
  else return 0;
};

const round = (num, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  const output = Math.round(num * multiplier) / multiplier;
  return output;
};

export default function AccessorialReportWrapper(props) {
  const cls = useStyles();
  const { goToMoveDetails } = useTools();
  const { code, customerIdArray, start, end, regionId } = props;

  // STATES ////////////////////////////////////////////////////////////////////////
  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  // HOOK ////////////////////////////////////////////////////////////////////////
  const { loading, error, data } = useQuery(GET_ACCESSORIALS(code, customerIdArray, regionId), {
    variables: {
      code: code,
      customerIdArray: customerIdArray,
      start: start,
      end: end,
      regionId: regionId,
    },
  });

  // LOGIC ////////////////////////////////////////////////////////////////////////
  const applyFilters = data => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(o => {
        if (
          (o.move_id && (o.move_id + ``).toLocaleLowerCase().includes(search)) ||
          (o.code && (o.code + ``).toLocaleLowerCase().includes(search)) ||
          (o.move && o.move.driver_name && o.move.driver_name.toLocaleLowerCase().includes(search)) ||
          (o.move &&
            o.move.customer &&
            o.move.customer.name &&
            o.move.customer.name.toLocaleLowerCase().includes(search))
          // (o.gateway_transaction_id && o.gateway_transaction_id.toLocaleLowerCase().includes(search))
        ) {
          return true;
        } else return false;
      });
    }
  };

  const generateCSV = accessorials => {
    const createCsvRow = accessorial => {
      return {
        MOVE_ID: accessorial.move_id,
        DATE: dayjs(accessorial.createdat).format(`MM/DD/YYYY`),
        CODE: accessorial.code ? cap(accessorial.code) : `-`,
        CUSTOMER_NAME: accessorial.move && accessorial.move.customer ? accessorial.move.customer.name : `-`,
        DRIVER_NAME: accessorial.move ? accessorial.move.driver_name : `-`,
        STATUS: accessorial.status || `-`,
        CUSTOMER_CHARGE: `$${checkNeg(accessorial.ar_amount).toFixed(2)}` || `-`,
        DRIVER_PAY: `$${checkNeg(accessorial.ap_amount).toFixed(2)}` || `-`,
        TOTAL: accessorial.cost ? `$${checkNeg(accessorial.cost).toFixed(2)}` : accessorial.cost === 0 ? `$0.00` : `-`,
      };
    };
    const csvRows = accessorials.map(accessorial => createCsvRow(accessorial));
    const csvOptions = {
      //TODO: Change File Name for Multiple Customers
      filename: `${
        customerIdArray ? accessorials[0].move.customer.name.replace(/ /g, '_') : `All`
      }_Accessorials_from_${start}_to_${end}`,
      showTitle: true,
      title: `${customerIdArray ? accessorials[0].move.customer.name : `All`} Accessorials from ${start} to ${end}`,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  const getTableActions = accessorials => {
    return [{ label: `Generate\xa0CSV`, handler: () => generateCSV(accessorials) }];
  };

  const getRowActions = accessorial => {
    return [{ label: `Go\xa0To\xa0Move\xa0Details`, handler: () => goToMoveDetails(accessorial.arinvoice.id) }];
  };

  // RENDER ////////////////////////////////////////////////////////////////////////
  if (loading) return <Loading fixed />;
  if (error) {
    console.log('Error Fetching Accessorials:', error);
    Sentry.captureException(error);
    return <DefaultErrorFallback message='ERROR FETCHING ACCESSORIALS' />;
  }
  if (data && data.accessorials.length > 0) {
    const filteredAccessorials = applyFilters(data.accessorials);
    const subtotalAccessorials = filteredAccessorials.filter(item => item.cost > 0);
    var amount = {};

    log && console.log('Filtered Accessorials:', filteredAccessorials);

    amount.subtotal = round(
      subtotalAccessorials.length > 0
        ? subtotalAccessorials.map(item => item.cost).reduce((total, current) => total + current)
        : 0,
      2
    );

    amount.total = checkNeg(amount.subtotal);

    const headers = [
      { id: `MOVE_ID`, alignLeft: true, numeric: true, label: `Move ID` },
      { id: `DATE`, alignLeft: true, numeric: false, label: `Date` },
      { id: `CODE`, alignLeft: true, numeric: false, label: `Code` },
      { id: `CUSTOMER_NAME`, alignLeft: true, numeric: false, label: `Customer` },
      { id: `DRIVER_NAME`, alignLeft: true, numeric: false, label: `Driver` },
      { id: `STATUS`, alignLeft: true, numeric: false, label: `Status` },
      { id: `CUSTOMER_CHARGE`, alignLeft: false, numeric: true, label: `Customer Charge` },
      { id: `DRIVER_PAY`, alignLeft: false, numeric: true, label: `Driver Pay` },
      { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total Cost` },
    ];

    const rows = filteredAccessorials.map(accessorial => {
      return {
        MOVE_ID: accessorial.move_id,
        DATE: dayjs(accessorial.createdat).format(`MM/DD/YYYY`),
        CODE: accessorial.code ? cap(accessorial.code) : `-`,
        CUSTOMER_NAME: accessorial.move && accessorial.move.customer ? accessorial.move.customer.name : `-`,
        DRIVER_NAME: accessorial.move ? accessorial.move.driver_name : `-`,
        STATUS: accessorial.status || `-`,
        CUSTOMER_CHARGE: `$${checkNeg(accessorial.ar_amount).toFixed(2)}` || `-`,
        DRIVER_PAY: `$${checkNeg(accessorial.ap_amount).toFixed(2)}` || `-`,
        TOTAL: accessorial.cost ? `$${checkNeg(accessorial.cost).toFixed(2)}` : accessorial.cost === 0 ? `$0.00` : `-`,
        accessorial: accessorial,
      };
    });

    return (
      <>
        <AccordionTable
          title={`${rows.length} Accessorials`}
          headers={headers}
          rows={rows}
          actions={getTableActions(data.accessorials)}
          search={search}
          defaultOrder={defaultOrder}
          defaultOrderBy={defaultOrderBy}
          order={order}
          orderBy={orderBy}
          tablePage={tablePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          setSearch={setSearch}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setTablePage={setTablePage}
          setRowsPerPage={setRowsPerPage}
          setExpandedRowId={() => console.log('non-expandable')}
          className={cls.table}
          // refetch={refetch}
          refreshPersistAs='accessorial_report'
        >
          {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
            .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
            .map(row => (
              <AccordionRow
                key={`arreport-payment-${row.accessorial.id}`}
                rowId={row.accessorial.id}
                setExpandedRowId={() => console.log('non-expandable')}
                columns={[
                  { align: 'left', value: row.MOVE_ID },
                  { align: 'left', value: row.DATE },
                  { align: 'left', value: row.CODE },
                  { align: 'left', value: row.CUSTOMER_NAME },
                  { align: 'left', value: row.DRIVER_NAME },
                  { align: 'left', value: row.STATUS },
                  { align: 'right', value: row.CUSTOMER_CHARGE },
                  { align: 'right', value: row.DRIVER_PAY },
                  { align: 'right', value: row.TOTAL },
                ]}
                actions={getRowActions(row.accessorial)}
                onClick={() => goToMoveDetails(row.MOVE_ID)}
                className={cls.row}
              >
                <div />
              </AccordionRow>
            ))}
        </AccordionTable>

        <div className={cls.break} />

        <AccessorialReportFooter amount={amount} />
      </>
    );
  } else return <DefaultEmptyFallback message='NO ACCESSORIALS FOUND' />;
}

// STYLE //////////////////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
