import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { GET_CHASE_VEHICLES } from './gql';
import ChaseVehicleTable from './ChaseVehicleTable';
import { LinearProgress } from '@material-ui/core';
import { DefaultErrorFallback } from '../../components/Fallbacks';

export default function ChaseVehicleContent() {
  const { loading, error, data } = useQuery(GET_CHASE_VEHICLES);

  return (
    <>
      {loading ? <LinearProgress /> : null}
      {error ? <DefaultErrorFallback message={`Failed to fetch chase vehicles`} /> : null}
      {!loading && !error && data?.chasevehicles?.length ? (
        <ChaseVehicleTable chaseVehicles={data.chasevehicles} />
      ) : null}
    </>
  );
}
