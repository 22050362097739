//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import {
  makeStyles,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Switch,
} from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../ModalComponents';
import { getUserEmail } from '../../utils/authHelper';
import { Spacer } from '@hopdrive/storybook';

const log = true;

//////////////////////// COMPONENT ////////////////////////

function ARPriceOverrideContent(props) {
  const cls = useStyles();

  const { onClose, input } = props;

  React.useEffect(() => {
    if (input?.priceOverride && input?.priceOverride?.active) {
      const existingPriceOverride = input?.priceOverride ? input.priceOverride : null;
      const existingAllowedCharges = existingPriceOverride?.config?.allowed_charges
        ? existingPriceOverride.config.allowed_charges
        : [];
      setOverrideAmount(existingPriceOverride.amount);
      setSelectedOptions(existingAllowedCharges);
      if (existingAllowedCharges.includes('all')) {
        setToggleSelectAll(true);
      } else {
        setToggleSelectAll(false);
      }
    }
  }, [input]);

  const [overrideAmount, setOverrideAmount] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [toggleSelectAll, setToggleSelectAll] = React.useState(false);
  const [overrideActive, setOverrideActive] = React.useState(true);

  const [upsertPriceOverride] = useMutation(UPSERT_PRICE_OVERRIDE);
  const { data, loading, error } = useQuery(GET_NONSALABLE_PRODUCTS);

  React.useEffect(() => {
    if (data) {
      log && console.log('nonsalable products: ', data, data.products);
      const selectableOptions = data.products
        .filter(product => product.name !== 'move' && product.name !== 'processing_fee' && product.name !== 'spoilage' && product.name !== 'discount')
        .map(product => product.name);
      setOptions(selectableOptions);
    } else {
      log && console.log('no nonsalable products found');
      setOptions([]);
    }
    if (error) {
      console.error('Error loading nonsalable products: ', error);
    }
  }, [data, loading, error]);

  const handleInputChange = event => {
    setOverrideAmount(event.target.value);
  };

  const handleCheckOne = value => {
    log && console.log('Checkbox current values: ', selectedOptions);
    log && console.log('Clicked Value: ', value);
    if (selectedOptions.includes(value)) {
      let newSelections = selectedOptions.filter(selection => selection !== value);
      setSelectedOptions(newSelections);
    } else {
      let newSelections = selectedOptions.concat([value]);
      setSelectedOptions(newSelections);
    }
  };

  const handleCheckAll = event => {
    if (!toggleSelectAll) {
      setToggleSelectAll(true);
      setSelectedOptions(['all']);
    } else {
      setToggleSelectAll(false);
      setSelectedOptions([]);
    }
  };

  const handleClose = (output = null) => {
    setSelectedOptions([]);
    setOverrideAmount(0);
    setToggleSelectAll(false);
    if (onClose) onClose(output);
  };

  const handleSubmit = () => {
    const user = getUserEmail();
    const moveId = input.moveId || null;
    if (!moveId) {
      console.log('cannot insert price override: no move id');
      return;
    }
    const priceOverrideConfig = {
      allowed_charges: selectedOptions,
    };
    const priceOverrideObject = {
      active: overrideActive,
      amount: overrideAmount,
      config: priceOverrideConfig,
      move_id: moveId,
      created_by: user,
      updated_by: user,
      created_at: 'now()',
      updated_at: 'now()',
    };
    log && console.log('priceOverrideObject: ', priceOverrideObject);
    upsertPriceOverride({
      variables: { priceOverride: priceOverrideObject },
    });
    handleClose(null);
  };

  const icon = <CheckBoxOutlineBlank fontSize='small' />;
  const checkedIcon = <CheckBox fontSize='small' />;
  const headerText = input?.priceOverride ? 'Edit Price Override' : 'Add Price Override';

  return (
    <>
      <ModalHeader handleClose={handleClose}>{headerText}</ModalHeader>
      <ModalContent
        subtitle={`When the move's transport cost is calculated, it will be set as the entered amount, ignoring any raterules. Only the accessorials selected can be charged to the customer.`}
      >
        <Grid container spacing={0} alignItems='center'>
          <Grid item xs={2}>
            <Typography className={cls.displayTxt}>Override Amount:&nbsp;&nbsp;&nbsp;</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='outlined-basic'
              key='override-amount-input'
              variant='outlined'
              size='small'
              type='number'
              value={overrideAmount}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              disabled={!overrideActive}
            />
          </Grid>
          <Grid item xs={4}>
            {input?.priceOverride ? (
              <>
                <FormControlLabel
                  label='Active'
                  labelPlacement='start'
                  control={<Switch checked={overrideActive} onChange={() => setOverrideActive(!overrideActive)} />}
                />
                <FormHelperText style={{ marginLeft: '16px' }}>Switch off to remove price override</FormHelperText>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Spacer />
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <Typography className={cls.displayTxt}>Allowed Accessorials:&nbsp;&nbsp;&nbsp;</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              style={{ marginLeft: '6px' }}
              label='Check All'
              onClick={e => {
                e.preventDefault(); // prevent blur
                handleCheckAll();
              }}
              control={
                <Checkbox
                  color='primary'
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={toggleSelectAll}
                  disabled={!overrideActive}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          {options.map(option => {
            return (
              <Grid item xs={4}>
                <FormControlLabel
                  style={{ marginLeft: '6px' }}
                  label={<span style={{ textTransform: 'capitalize' }}>{option}</span>}
                  onClick={e => {
                    e.preventDefault(); // prevent blur
                    handleCheckOne(option);
                  }}
                  control={
                    <Checkbox
                      color='primary'
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selectedOptions.includes(option) || selectedOptions.includes('all')}
                      disabled={!overrideActive}
                    />
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </ModalContent>
      <ModalFooter>
        <ModalAction disabled={!overrideAmount} color='primary' onClick={() => handleSubmit()}>
          Submit
        </ModalAction>
        <ModalAction onClick={handleClose}>Cancel</ModalAction>
      </ModalFooter>
    </>
  );
}

//////////////////////// GQL ////////////////////////
const GET_NONSALABLE_PRODUCTS = gql`
  query get_nonsalable_products_price_override_modal {
    products(where: { salable: { _eq: false } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;

const UPSERT_PRICE_OVERRIDE = gql`
  mutation upsert_price_override($priceOverride: arpriceoverrides_insert_input!) {
    insert_arpriceoverrides(
      objects: [$priceOverride]
      on_conflict: {
        constraint: arpriceoverrides_move_id_key
        update_columns: [active, amount, config, updated_at, updated_by]
      }
    ) {
      affected_rows
      returning {
        id
        active
        amount
        config
        move_id
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
  },
  displayTxt: {
    fontSize: 15,
    fontWeight: 600,
  },
  switchLableTxt: {
    textAlign: 'left',
    fontSize: 15,
    fontWeight: 600,
  },
  inline: {
    display: 'inline-block',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default ARPriceOverrideContent;
