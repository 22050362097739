// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Container, Grid, Typography } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

import Toolbar from '../../components/Toolbar';

import GPFormDate from './GPFormDate';
import GPFormDrivers from './GPFormDrivers';
import GPFormLanes from './GPFormLanes';
import GPFormTime from './GPFormTime';
import GPFormVehicle from './GPFormVehicle';
import GPFormVehicleDriver from './GPFormVehicleDriver';
import GPFormVehicleLocation from './GPFormVehicleLocation';

// COMPONENT ---------------------------------------------------------------- //

export default function GPForm() {
  const cls = useStyles();

  const { goToGroupPlanningTimeline } = useTools();

  const [formData, setFormData] = React.useState({
    editMode: false,
    date: dayjs().format(`YYYY-MM-DD`),
    startTime: dayjs().add(15, `minute`).ceil(15, `minute`).format(`HH:mm`),
    endTime: dayjs().add(15, `minute`).add(8, `hour`).ceil(15, `minute`).format(`HH:mm`),
    capacity: 1,
  });

  /** Handle building the plan */
  const handleBuildPlan = () => {
    console.log(`Form Data:`, formData);
    goToGroupPlanningTimeline(formData);
  };

  // Clear some form data on vehicle change
  React.useEffect(() => {
    let newFormData = { ...formData };
    if (newFormData?.laneIds) delete newFormData.laneIds;
    if (newFormData?.driverIds) delete newFormData.driverIds;
    if (newFormData?.vehiclePickupLocationId) delete newFormData.vehiclePickupLocationId;
    if (newFormData?.vehicleDeliveryLocationId) delete newFormData.vehicleDeliveryLocationId;
    setFormData(() => newFormData);
  }, [formData?.vehicleId]);

  return (
    <div className={cls.root}>
      <Toolbar fullscreen title='Group Planning'>
        <Grid container>
          <Grid item xs />
          <Grid item>
            <Button size='large' color='primary' onClick={handleBuildPlan}>
              Build Plan
            </Button>
          </Grid>
        </Grid>
      </Toolbar>

      <Container className={cls.container} maxWidth='md'>
        <Typography className={cls.sectionTitle}>Start Building Your Plan</Typography>

        <GPFormDate formData={formData} setFormData={setFormData} />

        <GPFormTime formData={formData} setFormData={setFormData} />

        <GPFormVehicle formData={formData} setFormData={setFormData} />

        <GPFormVehicleDriver formData={formData} setFormData={setFormData} />

        {formData?.customerId || formData?.regionId ? (
          <>
            <Typography className={cls.sectionTitle}>
              Assign Lanes from Unassigned Moves ({formData?.laneIds?.length || 0}/10)
            </Typography>

            <GPFormLanes formData={formData} setFormData={setFormData} />
          </>
        ) : null}

        {formData?.capacity - 1 > 0 ? (
          <>
            <Typography className={cls.sectionTitle}>
              Assign Drivers ({formData?.driverIds?.length || 0}/{formData?.capacity - 1})
            </Typography>

            <GPFormDrivers formData={formData} setFormData={setFormData} />
          </>
        ) : null}

        {formData?.customerId ? (
          <>
            <Typography className={cls.sectionTitle}>Additional Options</Typography>

            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <GPFormVehicleLocation type='pickup' formData={formData} setFormData={setFormData} />
              </Grid>

              <Grid item md={6} xs={12}>
                <GPFormVehicleLocation type='delivery' formData={formData} setFormData={setFormData} />
              </Grid>
            </Grid>
          </>
        ) : null}
      </Container>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  container: {
    width: `100%`,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },

  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontSize: 20,
    fontWeight: 600,
  },
}));
