//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, useTheme, Grid, Typography, Tooltip, Icon } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import { useScheduler } from '../providers/SchedulerProvider';
import { useRides } from '../hooks/useRides';
import useMoveActions from '../hooks/useMoveActions';
import Spacer from '../../../components/Spacer';
import IconAction from '../../../components/IconAction';

import { MoveTileTooltip } from '../index';

import { ReactComponent as IconLyft } from '../../../static/lyft.svg';
import { ReactComponent as IconUber } from '../../../static/uber.svg';
import { ReactComponent as IconHD } from '../../../static/bunny.svg';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

//////////////////////// COMPONENT ////////////////////////

export default function ScheduleDetailsPanelRide({ enrichedRide = null, isAfter = false }) {
  const theme = useTheme();
  const cls = useStyles();

  const { isPlanEditable, refreshPlanAndSetSuggestionFlag } = useScheduler();
  const { enrichRideLaneAsync } = useRides();
  const { handleUnplanRide, actions } = useMoveActions(enrichedRide, isAfter);

  // Check if there is an actual enrichedRide (render null instead)
  if (!enrichedRide) return null;

  // Extract the move, moveWithOverrides, lane and customer from the enrichedDrive
  const rideWithOverrides = enrichedRide.withOverrides || null;
  const rideLane = rideWithOverrides.lane || null;
  let rideSuccessful = true;
  if (
    enrichedRide.googleEnrichmentStatus !== `successful` &&
    enrichedRide.arEnrichmentStatus !== `successful` &&
    enrichedRide.apEnrichmentStatus !== `successful`
  ) {
    rideSuccessful = false;
  }

  // Rebuild the lane if the enrichment statuses are unsuccessful
  const handleRebuildLane = () => {
    const pickupId = getPropValue(rideLane, 'pickup.id');
    const deliveryId = getPropValue(rideLane, 'delivery.id');
    const pickupName = getPropValue(rideLane, 'pickup.name');
    const deliveryName = getPropValue(rideLane, 'delivery.name');
    enrichRideLaneAsync(
      enrichedRide,
      pickupId,
      deliveryId,
      pickupName,
      deliveryName,
      () => {
        refreshPlanAndSetSuggestionFlag(enrichedRide, false);
      },
      () => {
        refreshPlanAndSetSuggestionFlag(enrichedRide, false);
      }
    );
  };

  const getHeaderIdentifier = () => {
    if (enrichedRide.id) return `#${enrichedRide.id}`;
    if (!rideSuccessful) return `(Lane Error)`;
    if (enrichedRide.isPlaceholder) return `(Suggested)`;
    return `(Unsaved)`;
  };

  const getRideColor = () => {
    if (rideWithOverrides.ride_type === `lyft`) return theme.palette.lyft.main;
    if (rideWithOverrides.ride_type === `uber`) return theme.palette.uber.main;
    if (rideWithOverrides.ride_type === `hopdrive`) return theme.palette.default.light;
    return theme.palette.auto.main;
  };

  const RideIcon = ({ ridePartner = null }) => {
    if (ridePartner === `lyft`) return <IconLyft className={cls.iconLyft} />;
    if (ridePartner === `uber`) return <IconUber className={cls.iconUber} />;
    if (ridePartner === `hopdrive`) return <IconHD className={cls.iconHD} />;
    return <Icon className={cls.iconAuto}>hdr_auto</Icon>;
  };

  return (
    <>
      {enrichedRide && !enrichedRide.isPlaceholder && rideWithOverrides ? (
        <div className={cls.panel} style={{ fontSize: 12 }}>
          <Grid container spacing={1} alignItems='center' wrap='nowrap'>
            <Grid item>
              <div className={cls.iconBox} style={{ background: getRideColor() }}>
                <RideIcon ridePartner={rideWithOverrides.ride_type} />
              </div>
            </Grid>
            <Grid item xs>
              <Typography className={cls.title}>
                Ride {isAfter ? `After` : `Before`}{' '}
                {getHeaderIdentifier()}
              </Typography>
            </Grid>
            <Grid item>
              <IconAction actions={actions} tooltip='Ride Actions' />
            </Grid>
          </Grid>

          <Spacer />

          <MoveTileTooltip enrichedMove={enrichedRide} useOverrides />

          <Spacer />

          <Grid container spacing={2}>
            {!rideSuccessful ? (
              <Grid item xs>
                <Button fullWidth size='large' color='error' onClick={() => handleRebuildLane()}>
                  Rebuild Lane
                </Button>
              </Grid>
            ) : null}

            <Grid item xs>
              <Tooltip title={`Remove this ride from the driver's schedule.`}>
                <div>
                  <Button
                    disabled={!isPlanEditable || rideWithOverrides.status}
                    fullWidth
                    size='large'
                    onClick={() => handleUnplanRide()}
                  >
                    Unplan Ride {isAfter ? `After` : `Before`}
                  </Button>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  panel: {
    position: 'relative',
    padding: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  title: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
  },
  iconBox: {
    position: 'relative',
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.25),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  iconLyft: {
    height: 18,
    marginBottom: -2,
    color: theme.palette.text.contrast,
  },
  iconUber: {
    height: 14,
    marginTop: 1,
    marginBottom: 1,
    color: theme.palette.text.contrast,
  },
  iconHD: {
    height: 18,
    marginBottom: -2,
    color: theme.palette.text.contrast,
  },
  iconAuto: {
    fontSize: 20,
    marginTop: -1,
    marginBottom: -3,
    color: theme.palette.text.contrast,
  },
}));
