import React from 'react';
import { makeStyles, Grid, TextField, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import CustomerMultiselect from '../../components/CustomerMultiselect/CustomerMultiselect';
import RegionSelect from '../../components/RegionSelect';

export default function AccessorialReportFilter(props) {
  const cls = useStyles();

  const {
    code,
    range,
    start,
    end,
    selectedRegion,
    onCodeChange,
    setCustomerIdArray,
    onRangeChange,
    onDateChange,
    onRegionChange,
    disablePickers,
  } = props;

  const handleRegionChange = event => {
    if (event.target.value !== 0) onRegionChange(event.target.value);
    else onRegionChange(0);
  };
  const handleCodeChange = event => {
    if (event.target.value !== `all`) onCodeChange(event.target.value);
    else onCodeChange(null);
  };

  const handleRangeChange = event => {
    onRangeChange(event.target.value);
  };

  const handleDateChange = name => event => {
    onDateChange(event, name);
  };

  return (
    <>
      <div className={cls.main}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
          <Grid item md={2} sm={6} xs={12}>
              <RegionSelect
                allowAll
                label='Region Filter'
                value={selectedRegion || 0}
                onChange={handleRegionChange}
              />
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <CustomerMultiselect
                label='Customer Filter'
                handleChangeCustomers={setCustomerIdArray}
              />
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <TextField
                select
                fullWidth
                label='Code Filter'
                placeholder='Select an accessorial code...'
                variant='outlined'
                margin='dense'
                value={code || `all`}
                onChange={handleCodeChange}
                className={cls.input}
              >
                <MenuItem value={`all`}>All</MenuItem>
                <MenuItem value={`fuel`}>Fuel</MenuItem>
                <MenuItem value={`tow`}>Tow</MenuItem>
                <MenuItem value={`delay`}>Delay</MenuItem>
                <MenuItem value={`surge`}>Surge</MenuItem>
              </TextField>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <TextField
                select
                fullWidth
                label='Date Range'
                placeholder='Select a date range...'
                variant='outlined'
                margin='dense'
                value={range || `day`}
                onChange={handleRangeChange}
                className={cls.input}
              >
                <MenuItem value={`day`}>Today</MenuItem>
                <MenuItem value={`week`}>Past Week</MenuItem>
                <MenuItem value={`month`}>Past Month</MenuItem>
                <MenuItem value={`year`}>Past Year</MenuItem>
                <MenuItem value={`custom`}>Custom Range</MenuItem>
              </TextField>
            </Grid>

            <Grid item md={2} sm={3} xs={6}>
              <DatePicker
                disabled={disablePickers}
                autoOk
                maxDate={end}
                orientation='portrait'
                format='MM/dd/yyyy'
                label='Start Date'
                margin='dense'
                inputVariant='outlined'
                value={start}
                onChange={handleDateChange(`start`)}
                className={cls.date}
              />
            </Grid>

            <Grid item md={2} sm={3} xs={6}>
              <DatePicker
                disabled={disablePickers}
                autoOk
                minDate={start}
                orientation='portrait'
                format='MM/dd/yyyy'
                label='End Date'
                margin='dense'
                inputVariant='outlined'
                value={end}
                onChange={handleDateChange(`end`)}
                className={cls.date}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  input: {
    margin: 0,
  },
  date: {
    width: '100%',
    margin: 0,
  },
}));
