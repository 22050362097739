// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, Typography } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import { useLazyQuery } from '@apollo/client';
import { GET_DRIVERS } from './gql';

import DriverAvatar from '../../components/DriverComponents/DriverAvatar';

/** Sort & filter drivers list */
const cleanseDrivers = (drivers, filteredId, selectedIds, selectedRegionId) => {
  return drivers
    ?.filter(d => d?.id !== filteredId)
    ?.sort((a, b) => {
      if (selectedIds?.includes(a?.id) && !selectedIds?.includes(b?.id)) return -1;
      if (!selectedIds?.includes(a?.id) && selectedIds?.includes(b?.id)) return 1;
      if (a?.region?.id === selectedRegionId && b?.region?.id !== selectedRegionId) return -1;
      if (a?.region?.id !== selectedRegionId && b?.region?.id === selectedRegionId) return 1;
      if (a?.region?.name < b?.region?.name) return -1;
      if (a?.region?.name > b?.region?.name) return 1;
      if (a?.user?.display_name?.toLowerCase() < b?.user?.display_name?.toLowerCase()) return -1;
      if (a?.user?.display_name?.toLowerCase() > b?.user?.display_name?.toLowerCase()) return 1;
      return 0;
    });
};

// COMPONENT ---------------------------------------------------------------- //

export default function GPFormDrivers({ formData, setFormData }) {
  const cls = useStyles();

  const [driverIds, setDriverIds] = React.useState([]);
  const [drivers, setDrivers] = React.useState([]);

  /** Lazy fetch */
  const [fetchDrivers, { loading, error }] = useLazyQuery(GET_DRIVERS);

  /** Handle adding a driver ID */
  const handleDriverIdAdd = id => {
    let newDriverIds = [...driverIds, id];
    if (newDriverIds?.length > formData?.capacity - 1) {
      toast.warning(`You cannot assign more drivers than the vehicle's capacity!`, {
        toastId: `gp-form-driver-capacity`,
      });
      return;
    }
    handleSetState(newDriverIds);
  };

  /** Handle removing a driver ID */
  const handleDriverIdRemove = id => {
    const newDriverIds = driverIds.filter(driverId => driverId !== id);
    handleSetState(newDriverIds);
  };

  /** Handle setting drivers state */
  const handleSetState = newDriverIds => {
    setDriverIds(newDriverIds);
    setDrivers(() => cleanseDrivers(drivers, formData?.vehicleDriverId, newDriverIds, formData?.regionId));
    setFormData({ ...formData, driverIds: newDriverIds });
  };

  /** Handle clicking a driver */
  const handleDriverClick = id => {
    if (driverIds?.includes(id)) handleDriverIdRemove(id);
    else handleDriverIdAdd(id);
  };

  /** Handle fetching drivers */
  const handleFetchDrivers = async () => {
    try {
      const res = await fetchDrivers();
      if (res?.data?.drivers) {
        let newDrivers = [...res?.data?.drivers];
        newDrivers = cleanseDrivers(newDrivers, formData?.vehicleDriverId, [], formData?.regionId);
        newDrivers = newDrivers?.filter(driver => driver?.id !== formData?.vehicleDriverId);
        setDrivers(newDrivers);
      }
    } catch (err) {
      console.error(`Failed to fetch drivers:`, err);
    }
  };

  // Fetch drivers on mount
  React.useEffect(() => {
    handleFetchDrivers();
  }, []);

  // Clear, then refetch drivers on vehicle change
  React.useEffect(() => {
    setDriverIds([]);
    setDrivers([]);
    handleFetchDrivers();
  }, [formData?.vehicleId, formData?.vehicleDriverId]);

  return (
    <div className={cls.root}>
      <label className={cls.label}>
        Which drivers would you like to assign to this group?<span className={cls.required}> *</span>
      </label>

      <div className={cls.container}>
        {loading ? (
          <Loading position='absolute' />
        ) : (
          <div className={cls.list}>
            {drivers?.map(driver => (
              <div key={driver?.id} className={cls.item} onClick={() => handleDriverClick(driver?.id)}>
                <DriverAvatar driver={driver} isSelected={driverIds?.includes(driver?.id)} />

                <div>
                  <Typography className={cls.itemTxt1}>
                    {driver?.user?.display_name} ({driver?.id})
                  </Typography>
                  <Typography className={cls.itemTxt2}>{driver?.region?.name}</Typography>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: `relative`,
    width: `100%`,
    paddingBottom: theme.spacing(4),
  },

  label: {
    fontSize: 14,
    fontWeight: 600,
  },
  required: {
    color: theme.palette.error.main,
  },

  container: {
    position: `relative`,
    overflowY: `auto`,
    width: `100%`,
    minHeight: `80px`,
    maxHeight: `480px`,
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
  },

  list: {
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    flexWrap: `wrap`,
    gap: theme.spacing(1),
    width: `100%`,
    padding: theme.spacing(2),
  },
  item: {
    position: `relative`,
    overflow: `hidden`,
    display: `flex`,
    alignItems: `center`,
    gap: theme.spacing(1),
    flex: `1 1 256px`,
    height: `fit-content`,
    padding: theme.spacing(0.5, 2, 0.5, 0.5),
    borderRadius: 48,
    backgroundColor: theme.palette.background.dark,
    cursor: `pointer`,
    transition: `all 0.1s`,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  itemTxt1: {
    lineHeight: 1,
    fontSize: 14,
    userSelect: `none`,
  },
  itemTxt2: {
    marginTop: 4,
    lineHeight: 1,
    fontSize: 12,
    userSelect: `none`,
    color: theme.palette.text.secondary,
  },
}));
