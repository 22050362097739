//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Tooltip, IconButton, Icon, Menu, MenuItem, Divider } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function IconAction({
  className = null,
  actions = [],
  tooltip = null,
  tooltipPlacement = null,
  ...rest
}) {
  const cls = useStyles();

  const [actionsRef, setActionsRef] = React.useState(null);
  const handleActionsOpen = event => {
    setActionsRef(event.currentTarget);
  };
  const handleActionsClose = () => {
    setActionsRef(null);
  };

  const handleItemHandler = handler => {
    handleActionsClose();
    if (handler) handler();
  };

  const getClassNames = () => {
    let classNames = [cls.iconBtn];
    if (className) classNames.push(className);
    return classNames.join(` `);
  };

  return (
    <>
      <Tooltip placement={tooltipPlacement || `top`} title={tooltip || `Actions`}>
        <IconButton className={getClassNames()} onClick={handleActionsOpen} {...rest}>
          <Icon>settings</Icon>
        </IconButton>
      </Tooltip>

      {actions && actions.length > 0 ? (
        <Menu
          keepMounted
          id={`toolbar-actions-menu`}
          anchorEl={actionsRef}
          open={Boolean(actionsRef)}
          onClose={handleActionsClose}
        >
          {actions.map((action, i) => {
            if (!action || action.hide) return null;
            if (!action.handler) return <Divider key={`bundle-drive-action-${i}`} className={cls.divider} />;
            return action.component ? (
              action.component(handleActionsClose)
            ) : (
              <MenuItem
                key={`toolbar-action-${i}`}
                disabled={action.disabled}
                onClick={() => handleItemHandler(action.handler || null)}
              >
                {action.label || `Action ${i + 1}`}
              </MenuItem>
            );
          })}
        </Menu>
      ) : null}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  iconBtn: {
    padding: theme.spacing(1),
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
}));
