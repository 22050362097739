//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import clsx from 'clsx';
import { useTheme, makeStyles, Grid, Typography, Tabs, Tab, Icon } from '@material-ui/core';

import Spacer from './Spacer';
import IconBack from './IconBack';
import IconClearFilters from './IconClearFilters';
import IconRefetch from './IconRefetch';
import IconAction from './IconAction';

//////////////////////// COMPONENT ////////////////////////

export default function Toolbar({
  children,
  zIndex = 10,
  position = `sticky`,
  fullscreen = false,
  border = true,
  shadow = false,
  back = false,
  title = null,
  refetch = null,
  refetchTooltip = null,
  onClear = null,
  onClearTooltip = null,
  actions = null,
  actionsTooltip = null,
  tabs = [],
  activeTab = 0,
  onActiveTabChange = () => {},
}) {
  const theme = useTheme();
  const cls = useStyles();

  const handleTabChange = (event, value) => {
    const foundTab = tabs.find(tab => tab.id === value);
    if (onActiveTabChange) onActiveTabChange(value);
    if (foundTab?.handler) foundTab.handler();
  };

  return (
    <div
      className={fullscreen ? cls.fullBar : cls.floatingBar}
      style={{
        zIndex: zIndex,
        position: fullscreen ? position : `relative`,
        borderBottom: fullscreen && !shadow && border ? theme.border[0] : `none`,
        boxShadow: fullscreen && shadow ? theme.shadow.medium : `none`,
      }}
    >
      <div className={fullscreen ? cls.fullBarContainer : cls.floatingBarContainer}>
        <Grid container spacing={2} alignItems='center'>
          {back ? (
            <Grid item>
              <IconBack className={cls.backBtn} tooltipPlacement={fullscreen ? `bottom` : `top`} />
            </Grid>
          ) : null}

          {title ? (
            <Grid item>
              {!back && fullscreen ? <Spacer column size='xs' /> : null}

              {Array.isArray(title) ? (
                title.map((str, i) => {
                  if (i === title?.length - 1)
                    return (
                      <Typography key={`toolbar-title-${i}`} className={cls.title}>
                        {str}
                      </Typography>
                    );
                  return (
                    <Typography key={`toolbar-title-${i}`} className={cls.titleAlt}>
                      {str}&nbsp;/&nbsp;
                    </Typography>
                  );
                })
              ) : (
                <Typography className={cls.title}>{title}</Typography>
              )}

              {fullscreen ? <Spacer column size='sm' /> : null}
            </Grid>
          ) : null}

          <Grid item xs>
            {children}
          </Grid>

          {refetch || onClear || (actions && actions.length > 0) ? (
            <Grid item>
              {refetch ? (
                <IconRefetch
                  refetch={refetch}
                  tooltip={refetchTooltip}
                  tooltipPlacement={fullscreen ? `bottom` : `top`}
                />
              ) : (
                <IconRefetch disabled={true} />
              )}

              {onClear ? (
                <IconClearFilters
                  onClear={onClear}
                  tooltip={onClearTooltip}
                  tooltipPlacement={fullscreen ? `bottom` : `top`}
                />
              ) : null}

              {actions && actions.length > 0 ? (
                <IconAction
                  actions={actions}
                  tooltip={actionsTooltip}
                  tooltipPlacement={fullscreen ? `bottom` : `top`}
                />
              ) : (
                <IconAction disabled={true} />
              )}
            </Grid>
          ) : null}
        </Grid>
      </div>

      {tabs?.length ? (
        <Tabs
          className={cls.tabs}
          centered
          variant='fullWidth'
          indicatorColor='primary'
          textColor='primary'
          value={activeTab}
          onChange={handleTabChange}
        >
          {tabs?.map((tab, i) => (
            <Tab
              key={`toolbar-tab-${tab?.id}`}
              className={clsx(cls.tab, activeTab === tab?.id && cls.activeTab)}
              style={{ borderRight: i < tabs?.length - 1 ? theme.border[0] : `none` }}
              disabled={tab?.disabled}
              label={tab?.label}
              icon={<Icon style={{ marginBottom: -2 }}>{tab?.icon}</Icon>}
              iconPosition='start'
            />
          ))}
        </Tabs>
      ) : null}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  fullBar: {
    top: 0,
  },
  fullBarContainer: {
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
  },
  floatingBar: {
    top: 0,
  },
  floatingBarContainer: {},

  title: {
    display: 'inline',
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  titleAlt: {
    display: 'inline',
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  backBtn: {
    marginRight: theme.spacing(-1),
  },

  tabs: {
    borderTop: theme.border[0],
    background: theme.palette.background.paper,
  },
  tab: {
    minHeight: 56,
    maxHeight: 56,
    padding: 0,
    paddingTop: 2,
  },
  activeTab: {
    background: `${theme.palette.primary.main}10`,
  },
}));
