// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { makeStyles, TextField, InputAdornment, Icon } from '@material-ui/core';

// COMPONENT ---------------------------------------------------------------- //

export default function GPFormTime({ formData, setFormData }) {
  const cls = useStyles();

  const [startTime, setStartTime] = React.useState(dayjs().add(15, `minute`).ceil(15, `minute`).format(`HH:mm`));
  const [endTime, setEndTime] = React.useState(
    dayjs().add(15, `minute`).add(8, `hour`).ceil(15, `minute`).format(`HH:mm`)
  );

  const handleStartTimeChange = e => {
    const value = e?.target?.value;
    const minimumStartTime = dayjs().format(`HH:mm`);
    const minimumEndTime = dayjs(`${formData?.date}T${value}`).add(2, `hour`).format(`HH:mm`);
    const currentMinimumEndTime = dayjs(`${formData?.date}T${endTime}`).subtract(2, `hour`).format(`HH:mm`);

    if (value > currentMinimumEndTime) {
      toast.warning(`End time was adjusted to be 2 hours after the start time.`, {
        toastId: `gp-form-start-time`,
      });
      setStartTime(value);
      setEndTime(minimumEndTime);
      setFormData({ ...formData, startTime: value, endTime: minimumEndTime });
    } else if (value >= minimumStartTime) {
      setStartTime(value);
      setFormData({ ...formData, startTime: value });
    } else {
      toast.warning(`Start time was adjusted to be the current time.`, {
        toastId: `gp-form-start-time`,
      });
      setStartTime(minimumStartTime);
      setFormData({ ...formData, startTime: minimumStartTime });
    }
  };

  const handleEndTimeChange = e => {
    const value = e?.target?.value;
    const minimumEndTime = dayjs(`${formData?.date}T${startTime}`).add(2, `hour`).format(`HH:mm`);

    if (value >= minimumEndTime) {
      setEndTime(value);
      setFormData({ ...formData, endTime: value });
    } else {
      toast.warning(`End time was adjusted to be 2 hours after the start time.`, {
        toastId: `gp-form-end-time`,
      });
      setEndTime(minimumEndTime);
      setFormData({ ...formData, endTime: minimumEndTime });
    }
  };

  return (
    <div className={cls.root}>
      <div className={cls.flex}>
        <div className={cls.flexItemStretch}>
          <label className={cls.textFieldLabel} htmlFor='startTime'>
            What is the planned start time for this group?<span className={cls.textFieldLabelRequired}> *</span>
          </label>

          <TextField
            required
            fullWidth
            type='time'
            name='startTime'
            variant='outlined'
            size='small'
            value={startTime}
            onChange={handleStartTimeChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Icon color='disabled' fontSize='small'>
                    play_arrow
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className={cls.flexItemStretch}>
          <label className={cls.textFieldLabel} htmlFor='endTime'>
            What is the cutoff time for planning this group?<span className={cls.textFieldLabelRequired}> *</span>
          </label>

          <TextField
            required
            fullWidth
            type='time'
            name='endTime'
            variant='outlined'
            size='small'
            value={endTime}
            onChange={handleEndTimeChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Icon color='disabled' fontSize='small'>
                    stop
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    width: `100%`,
    paddingBottom: theme.spacing(4),
  },

  textFieldLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  textFieldLabelRequired: {
    color: theme.palette.error.main,
  },

  flex: {
    display: `flex`,
    gap: theme.spacing(2),
  },
  flexItemStretch: {
    flex: 1,
  },
}));
