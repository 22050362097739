import React from 'react';
import { makeStyles, FormGroup, FormControlLabel, Grid, Typography, Checkbox } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent } from '../../../components/ModalComponents';
import Spacer from '../../../components/Spacer';
import { useSettings } from '../providers/SettingsProvider';

//////////////////////// COMPONENT ////////////////////////

export function DebugModal() {
  const cls = useStyles();

  const {
    debugModalOpen,
    closeDebugModal,

    // GENERAL //
    enableMoveIds,
    enableDebugTooltips,
    enableGeneralLogs,

    setEnableMoveIds,
    setEnableDebugTooltips,
    setEnableGeneralLogs,

    // PROVIDERS //
    enableSettingsLogs,
    enablePlanLogs,
    enableTimelineLogs,
    enableSchedulerLogs,
    enableDriverLogs,
    enableRegionLogs,
    enableUnassignedMovesLogs,
    enableCanceledMovesLogs,

    setEnableSettingsLogs,
    setEnablePlanLogs,
    setEnableTimelineLogs,
    setEnableSchedulerLogs,
    setEnableDriverLogs,
    setEnableRegionLogs,
    setEnableUnassignedMovesLogs,
    setEnableCanceledMovesLogs,

    // HOOKS //
    enableEnrichmentLogs,
    enableRideLogs,
    enablePersistLogs,
    enableDragDropLogs,

    setEnableEnrichmentLogs,
    setEnableRideLogs,
    setEnablePersistLogs,
    setEnableDragDropLogs,
  } = useSettings();

  const handleClose = () => {
    closeDebugModal();
  };

  return (
    <Modal open={debugModalOpen || false}>
      <ModalHeader handleClose={handleClose}>Debug Settings</ModalHeader>
      <ModalContent subtitle='Customize the debug settings of the plans page.'>
        <FormGroup>
          <Typography className={cls.headTxt}>General</Typography>

          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={enableMoveIds}
                onChange={e => {
                  localStorage.setItem(`plans-move-ids`, !enableMoveIds);
                  setEnableMoveIds(e.target.checked);
                }}
              />
            }
            label='Show Move IDs'
          />

          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={enableDebugTooltips}
                onChange={e => {
                  localStorage.setItem(`plans-debug-tooltips`, !enableDebugTooltips);
                  setEnableDebugTooltips(e.target.checked);
                }}
              />
            }
            label='Show Debug Tooltips'
          />

          {/* <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={enableGeneralLogs}
                onChange={e => {
                  localStorage.setItem(`plans-general-logs`, !enableGeneralLogs);
                  setEnableGeneralLogs(e.target.checked);
                }}
              />
            }
            label='Enable General Logs'
          /> */}

          <Spacer />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className={cls.headTxt}>Provider Logs</Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableSettingsLogs}
                    onChange={e => {
                      localStorage.setItem(`plans-settings-logs`, !enableSettingsLogs);
                      setEnableSettingsLogs(e.target.checked);
                    }}
                  />
                }
                label='Enable SettingsProvider Logs'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enablePlanLogs}
                    onChange={e => {
                      localStorage.setItem(`plans-plan-logs`, !enablePlanLogs);
                      setEnablePlanLogs(e.target.checked);
                    }}
                  />
                }
                label='Enable PlansProvider Logs'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableTimelineLogs}
                    onChange={e => {
                      localStorage.setItem(`plans-timeline-logs`, !enableTimelineLogs);
                      setEnableTimelineLogs(e.target.checked);
                    }}
                  />
                }
                label='Enable TimelineProvider Logs'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableSchedulerLogs}
                    onChange={e => {
                      localStorage.setItem(`plans-scheduler-logs`, !enableSchedulerLogs);
                      setEnableSchedulerLogs(e.target.checked);
                    }}
                  />
                }
                label='Enable SchedulerProvider Logs'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableDriverLogs}
                    onChange={e => {
                      localStorage.setItem(`plans-driver-logs`, !enableDriverLogs);
                      setEnableDriverLogs(e.target.checked);
                    }}
                  />
                }
                label='Enable DriversProvider Logs'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableRegionLogs}
                    onChange={e => {
                      localStorage.setItem(`plans-region-logs`, !enableRegionLogs);
                      setEnableRegionLogs(e.target.checked);
                    }}
                  />
                }
                label='Enable RegionsProvider Logs'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableUnassignedMovesLogs}
                    onChange={e => {
                      localStorage.setItem(`plans-unassigned-moves-logs`, !enableUnassignedMovesLogs);
                      setEnableUnassignedMovesLogs(e.target.checked);
                    }}
                  />
                }
                label='Enable UnassignedMovesProvider Logs'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableCanceledMovesLogs}
                    onChange={e => {
                      localStorage.setItem(`plans-canceled-moves-logs`, !enableCanceledMovesLogs);
                      setEnableCanceledMovesLogs(e.target.checked);
                    }}
                  />
                }
                label='Enable CanceledMovesProvider Logs'
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className={cls.headTxt}>Hook Logs</Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableEnrichmentLogs}
                    value='enableEnrichmentLogs'
                    onChange={e => {
                      localStorage.setItem(`plans-enrichment-logs`, !enableEnrichmentLogs);
                      setEnableEnrichmentLogs(e.target.checked);
                    }}
                  />
                }
                label='Enable useEnrichedPlans Logs'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableRideLogs}
                    value='enableRideLogs'
                    onChange={e => {
                      localStorage.setItem(`plans-ride-logs`, !enableRideLogs);
                      setEnableRideLogs(e.target.checked);
                    }}
                  />
                }
                label='Enable useRides Logs'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enablePersistLogs}
                    value='enablePersistLogs'
                    onChange={e => {
                      localStorage.setItem(`plans-persist-logs`, !enablePersistLogs);
                      setEnablePersistLogs(e.target.checked);
                    }}
                  />
                }
                label='Enable useSchedulerPersist Logs'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableDragDropLogs}
                    value='enableDragDropLogs'
                    onChange={e => {
                      localStorage.setItem(`plans-drag-and-drop-logs`, !enableDragDropLogs);
                      setEnableDragDropLogs(e.target.checked);
                    }}
                  />
                }
                label='Enable useDragAndDrop Logs'
              />
            </Grid>
          </Grid>
        </FormGroup>
      </ModalContent>
    </Modal>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  headTxt: {
    marginBottom: 8,
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
}));
