// DEPENDENCIES ---------------------------------------------------------------- //

// HOOK ------------------------------------------------------------------------ //

export default function useGroupPlanning() {
  /** Get the chase vehicle name */
  const getVehicleName = vehicle => {
    let vehicleStr = ``;

    const make = vehicle?.chasevehicle?.make || vehicle?.make;
    const model = vehicle?.chasevehicle?.model || vehicle?.model;

    if (make && model) vehicleStr = `${make} ${model}`;
    else if (make) vehicleStr = make;
    else if (model) vehicleStr = model;
    else vehicleStr = `Unknown`;

    return vehicleStr;
  };

  /** Get the chase vehicle vin */
  const getVehicleVin = vehicle => {
    const vin = vehicle?.chasevehicle?.vin || vehicle?.vin;
    return vin || `Unknown`;
  };

  /** Get the chase vehicle capacity */
  const getVehicleCapacity = vehicle => {
    const capacity = vehicle?.chasevehicle?.capacity || vehicle?.capacity;
    return capacity || 0;
  };

  return { getVehicleName, getVehicleVin, getVehicleCapacity };
}
