//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

import BigButtonGroup from '../BigButtonGroup';
import BigButton from '../BigButton';

import { ReactComponent as IconLyft } from '../../static/lyft.svg';
import { ReactComponent as IconUber } from '../../static/uber.svg';
import { ReactComponent as IconHD } from '../../static/bunny.svg';

//////////////////////// COMPONENT ////////////////////////

export default function ManageRidePartner({ partner, onPartnerChange }) {
  const cls = useStyles();

  const { getFormattedRidesharePartner } = useTools();

  return (
    <>
      <Typography className={cls.titleTxt}>Select a Ride Partner</Typography>
      <Typography className={cls.subtitleTxt}>
        Use the 'CALL' action to manually call the ride now and set its rideshare partner to{' '}
        {getFormattedRidesharePartner(partner)}.
      </Typography>

      <Spacer size='xs' />

      <BigButtonGroup onChange={onPartnerChange} preselect={partner}>
        <BigButton
          id='auto'
          title={`AUTO`}
          tip='Automatically determine the best choice for the rideshare partner (may take a moment to call).'
        />
        <BigButton id='lyft' icon={<IconLyft className={cls.iconLyft} />} />
        <BigButton id='uber' icon={<IconUber className={cls.iconUber} />} />
        <BigButton id='hopdrive' icon={<IconHD className={cls.iconHD} />} disabled />
      </BigButtonGroup>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  titleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  subtitleTxt: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  iconLyft: {
    height: 36,
    marginBottom: -4,
  },
  iconUber: {
    height: 28,
  },
  iconHD: {
    height: 36,
    marginBottom: -4,
  },
}));
