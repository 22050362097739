// DEPENDENCIES ---------------------------------------------------------------- //

import { gql } from '@apollo/client';

// GRAPHQL --------------------------------------------------------------------- //

export const GET_CHASE_DRIVERS = gql`
  query getChaseDrivers {
    drivers(
      where: {
        tax_class: { _eq: "W-2" }
        user: { active: { _eq: true } }
        status: { _in: ["training", "online", "offline"] }
      }
      order_by: { user: { display_name: asc } }
    ) {
      id
      config
      status
      verification
      region {
        id
        name
      }
      user {
        id
        avatar_url
        display_name
      }
    }
  }
`;

export const GET_CHASE_VEHICLES = gql`
  query getChaseVehicles($start: timestamptz!, $end: timestamptz!) {
    chasevehiclesassigned(
      where: { _or: [{ plan_date: { _gte: $start, _lte: $end } }, { plan_date: { _is_null: true } }] }
      order_by: { plan_date: asc_nulls_last }
    ) {
      id
      capacity
      config
      make
      model
      plan_date
      vin
      chasevehicle {
        id
        capacity
        make
        model
        vin
      }
      customer {
        id
        name
      }
      organization {
        id
        name
      }
      region {
        id
        name
      }
    }
  }
`;

export const GET_DATA_FOR_GROUP_PLANNING = gql`
  query getDataForGroupPlanning(
    $driverIds: [bigint!]!
    $laneIds: [bigint!]!
    $vehicleId: bigint!
    $start: timestamptz!
    $end: timestamptz!
  ) {
    drivers(where: { id: { _in: $driverIds } }, order_by: { user: { display_name: asc } }) {
      id
      config
      status
      verification
      region {
        id
        name
      }
      user {
        id
        avatar_url
        display_name
      }
    }

    moves(
      where: {
        active: { _eq: 1 }
        cancel_status: { _is_null: true }
        consumer_type: { _is_null: true }
        customer_id: { _is_null: false }
        lane_id: { _in: $laneIds }
        move_type: { _eq: "drive" }
        pickup_time: { _gte: $start, _lte: $end }
        plan_id: { _is_null: true }
        _or: [{ driver_id: { _is_null: true } }, { status: { _is_null: true } }]
      }
      order_by: { deliver_by: asc_nulls_last }
    ) {
      id
      deliver_by
      cancel_status
      config
      dealer_contact
      driver_app_version
      driver_status
      manual_flag
      move_details
      move_type
      parent_move_id
      reference_num
      status
      vehicle_color
      vehicle_make
      vehicle_model
      vehicle_stock
      vehicle_vin
      vehicle_year
      customer {
        id
        name
        organization {
          id
          name
        }
      }
      hangtags {
        id
        rear_code
        type
      }
      lane {
        id
        distance_miles
        duration_sec
        pickup {
          id
          name
          region {
            id
            name
          }
        }
        delivery {
          id
          name
          region {
            id
            name
          }
        }
        inverse {
          id
          distance_miles
          duration_sec
          pickup {
            id
            name
            region {
              id
              name
            }
          }
          delivery {
            id
            name
            region {
              id
              name
            }
          }
        }
      }
    }
    chaseVehicle:chasevehiclesassigned(where: { id: { _eq: $vehicleId } }) {
      id
      config
      make
      model
      vin
    }
  }
`;

export const GET_DRIVERS = gql`
  query getDrivers {
    drivers(
      where: { user: { active: { _eq: true } }, status: { _in: ["training", "online", "offline"] } }
      order_by: { user: { display_name: asc } }
    ) {
      id
      config
      status
      verification
      region {
        id
        name
      }
      user {
        id
        avatar_url
        display_name
      }
    }
  }
`;

export const GET_LOCATIONS = gql`
  query getLocations($customerId: bigint!) {
    locations(where: { customer_id: { _eq: $customerId } }, order_by: { name: asc }) {
      id
      address
      name
      region {
        id
        name
      }
    }
  }
`;

export const GET_MOVE_LANES_BY_CUSTOMER = gql`
  query getMoveLanes($customerId: bigint!, $start: timestamptz!, $end: timestamptz!) {
    moves(
      where: {
        active: { _eq: 1 }
        cancel_status: { _is_null: true }
        consumer_type: { _is_null: true }
        customer_id: { _eq: $customerId }
        lane_id: { _is_null: false }
        move_type: { _eq: "drive" }
        pickup_time: { _gte: $start, _lte: $end }
        plan_id: { _is_null: true }
        _or: [{ driver_id: { _is_null: true } }, { status: { _is_null: true } }]
      }
      order_by: { lane: { id: asc } }
    ) {
      id
      lane {
        id
        distance_miles
        duration_sec
        pickup {
          id
          address
          name
          timezone
          region {
            id
            name
          }
        }
        delivery {
          id
          address
          name
          timezone
          region {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_MOVE_LANES_BY_REGION = gql`
  query getMoveLanesByRegion($regionId: bigint!, $start: timestamptz!, $end: timestamptz!) {
    moves(
      where: {
        active: { _eq: 1 }
        cancel_status: { _is_null: true }
        consumer_type: { _is_null: true }
        lane_id: { _is_null: false }
        move_type: { _eq: "drive" }
        pickup_time: { _gte: $start, _lte: $end }
        plan_id: { _is_null: true }
        _and: [
          { _or: [{ driver_id: { _is_null: true } }, { status: { _is_null: true } }] }
          {
            _or: [
              { lane: { pickup: { region_id: { _eq: $regionId } } } }
              { lane: { delivery: { region_id: { _eq: $regionId } } } }
            ]
          }
        ]
      }
      order_by: { lane: { id: asc } }
    ) {
      id
      lane {
        id
        distance_miles
        duration_sec
        pickup {
          id
          address
          name
          timezone
          region {
            id
            name
          }
        }
        delivery {
          id
          address
          name
          timezone
          region {
            id
            name
          }
        }
      }
    }
  }
`;
