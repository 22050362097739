//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import Switch from '../../Switch';
import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';

// OPTION PATHS //
const option = {
  preferredRideshare: `config.ride_type_override`,
  passthroughSurge: `config.passthrough_rideshare_surges`,
};

// DEFAULTS //
const getDefaultPreferredRideshare = (overrideRef, inheritedRef) => {
  return (
    getPropValue(overrideRef, option?.preferredRideshare) || getPropValue(inheritedRef, option?.preferredRideshare)
  );
};
const getDefaultPassthroughSurge = (overrideRef, inheritedRef) => {
  if (getPropValue(overrideRef, option?.passthroughSurge) === false) return false;
  if (getPropValue(overrideRef, option?.passthroughSurge) === true) return true;
  return getPropValue(inheritedRef, option?.passthroughSurge);
};

//////////////////////// COMPONENT ////////////////////////

export default function Rideshare({
  settingId,
  overrideRef,
  inheritedRef,
  updateMutableRef = () => {},
  checkMutableRefField = () => {},
  deleteMutableRefField = () => {},
  isReset,
  resetText,
  resetTooltip,
}) {
  const cls = useStyles();

  // Manage state of options
  const [preferredRideshare, setPreferredRideshare] = React.useState(
    getDefaultPreferredRideshare(overrideRef, inheritedRef)
  );
  const [passthroughSurge, setPassthroughSurge] = React.useState(getDefaultPassthroughSurge(overrideRef, inheritedRef));

  // Manage updates to the mutable ref when state is changed
  React.useEffect(() => {
    if (isReset) {
      setPreferredRideshare(getDefaultPreferredRideshare(null, inheritedRef));
      setPassthroughSurge(getDefaultPassthroughSurge(null, inheritedRef));
    }
  }, [isReset]);

  // Handle preferred rideshare change
  const handlePreferredRideshareChange = e => {
    // Get the value
    const value = e?.target?.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { ride_type_override: value } };
    setPreferredRideshare(value);
    updateMutableRef(changes);
  };

  // Handle passthrough surge change
  const handlePassthroughSurgeChange = () => {
    // Get the value
    const value = !passthroughSurge;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { passthrough_rideshare_surges: value } };
    setPassthroughSurge(value);
    updateMutableRef(changes);
  };

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`Rideshare`}
        tip={`The rideshare module allows us to control any rideshare preferences or rideshare contract obligations we may have with the client.`}
      />

      <SettingsOption
        contained
        title={`Preferred Ride Partner`}
        description={`Select the preferred rideshare service for this client. When planning an 'auto' ride, this partner will be selected by default for this client.`}
        value={preferredRideshare}
        checkReset={() => checkMutableRefField(option?.preferredRideshare)}
        onReset={() =>
          deleteMutableRefField(option?.preferredRideshare, () =>
            setPreferredRideshare(getPropValue(inheritedRef, option?.preferredRideshare))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <FormControl>
          <RadioGroup value={preferredRideshare} onChange={handlePreferredRideshareChange}>
            <FormControlLabel label='None' value='' control={<Radio className={cls.radio} color='primary' />} />
            <FormControlLabel label='Lyft' value='lyft' control={<Radio className={cls.radio} color='primary' />} />
            <FormControlLabel label='Uber' value='uber' control={<Radio className={cls.radio} color='primary' />} />
            <FormControlLabel
              label='HopDrive'
              value='hopdrive'
              control={<Radio className={cls.radio} color='primary' disabled />}
            />
          </RadioGroup>
        </FormControl>
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Surge Charge Accountability`}
        description={`Activate/Deactivate rideshare surge charges for this client. Surge charges are incurred when the actual rideshare cost is higher than anticipated. When active, the client will cover any rideshare surge charges related to their moves. When inactive, HopDrive will cover the extra cost and waive all rideshare surge charges for this client.`}
        value={passthroughSurge}
        checkReset={() => checkMutableRefField(option?.passthroughSurge)}
        onReset={() =>
          deleteMutableRefField(option?.passthroughSurge, () =>
            setPassthroughSurge(getPropValue(inheritedRef, option?.passthroughSurge))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <Switch color='primary' checked={passthroughSurge} onChange={() => handlePassthroughSurgeChange()} />
      </SettingsOption>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},
  radio: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));
