// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core';

import { useKeyPress } from '../../hooks/useKeyPress';

import {
  getCurrentPosition,
  getCurrentTime,
  getLastScrollPosition,
  setLastScrollPosition,
} from '../../utils/TimelineUtils';

import { useTimeline } from './TimelineProvider';
import TimelineIndex from './TimelineIndex';
import TimelineArea from './TimelineArea';

// COMPONENT ---------------------------------------------------------------- //

export default function TimelineContent() {
  const cls = useStyles();

  const {
    currentPosition,
    date,
    intervalCallback,
    isCurrentDay,
    multiplier,
    refreshMs,
    scrollKey,
    setCurrentPosition,
    setCurrentTime,
    setIsCurrentDay,
    timelineRef,
    timezoneOverride,
    zoom,
  } = useTimeline();

  // KEYPRESSES //
  const goToNowKeypress = useKeyPress('n');

  /** Handle the timeline scroll */
  const handleTimelineScroll = () => {
    const scrollValue = timelineRef?.current?.scrollLeft;
    if (scrollValue) setLastScrollPosition(scrollKey, scrollValue, zoom, multiplier);
  };

  /** Scroll to the current position or the last scroll position */
  const scrollToDefaultPosition = () => {
    if (timelineRef?.current) {
      if (isCurrentDay) {
        timelineRef.current.scrollLeft = currentPosition - 240;
      } else {
        const position = getLastScrollPosition(scrollKey, zoom, multiplier);
        timelineRef.current.scrollLeft = position;
      }
    }
  };

  /** Update the current position and time with a callback */
  const updateInterval = () => {
    setCurrentPosition(getCurrentPosition(date, timezoneOverride, zoom, multiplier));
    setCurrentTime(getCurrentTime(timezoneOverride));
    if (intervalCallback) intervalCallback();
  };

  // On mount, scroll to default position
  React.useEffect(() => {
    scrollToDefaultPosition();
  }, []);

  // On keypress or timeline date change, scroll to default position
  React.useEffect(() => {
    scrollToDefaultPosition();
  }, [goToNowKeypress, date]);

  // Check if the timeline date is today when it changes
  React.useEffect(() => {
    setIsCurrentDay(dayjs(date).isSame(dayjs(), `day`));
  }, [date]);

  // Timer interval to update the marker position
  // Reset upon changing base timeline properties
  const timer = React.useRef();
  React.useEffect(() => {
    // Call the initial update on change
    // Then, clear the previous interval (if it exists)
    // Then, set the new interval (if applicable)
    updateInterval();
    if (timer?.current) clearInterval(timer.current);
    if (isCurrentDay && refreshMs > 0) timer.current = setInterval(() => updateInterval(), refreshMs);

    // Clear the interval on unmount
    return () => clearInterval(timer.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentDay, refreshMs, date, zoom, multiplier]);

  return (
    <div ref={timelineRef} className={cls.timelineWrapper} onScroll={handleTimelineScroll}>
      <div className={cls.timeline}>
        <TimelineIndex />
        <TimelineArea />
      </div>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  timelineWrapper: {
    position: 'relative',
    overflow: 'auto',
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  timeline: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    width: 'max-content',
    height: 'max-content',
  },
}));
