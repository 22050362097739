import React, { createContext, useContext, useState } from 'react';
import sdk from '@hopdrive/sdk';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { SimpleLogger } from '../../../utils/SimpleLogger';
import { useTools } from '../../../hooks/useTools';
import { useSettings } from './SettingsProvider';
import { usePlans } from './PlansProvider';
import { useDrivers } from './DriversProvider';
import { useUnassignedMoves } from './UnassignedMovesProvider';
import { useEnrichedPlans } from '../hooks/useEnrichedPlans';
import { useRides } from '../hooks/useRides';
import { useSchedulerPersist } from '../hooks/useSchedulerPersist';
import { Typography, Chip } from '@material-ui/core';
import { ConfirmationModal, ifConfirmed } from '../../../components/ModalComponents/ConfirmationModal';
import CancelMoveModal from '../../../components/CancelMoveModal';

const SchedulerContext = createContext({});

let isInitialLoad = false;

function SchedulerProvider({ children }) {
  const { getDriveTypeFromMove, getDriverTagsFromAttributes } = useTools();
  const { timelineDate, isTimelineDateToday, enableSchedulerLogs } = useSettings();
  const {
    buildEnrichedPlan,
    buildEnrichedMove,
    applyMoveChanges,
    revertMoveChanges,
    withOverrides,
    refreshEnrichedMoves,
    reclassMoves,
  } = useEnrichedPlans();
  const { handleUpsertMoves } = useSchedulerPersist();
  const { plans, state: plansState, refetch: refetchPlansFromServer } = usePlans();
  const { unassignedMoves } = useUnassignedMoves();
  const { handleEnrichedPlanRides, deleteInvalidPlaceholderRides, injectPlaceholderRideMoves } = useRides();
  const { getDriverById } = useDrivers();

  const [selectedPlan, setSelectedPlan] = useState();
  const [workingPlan, setWorkingPlan] = useState();
  const [movesToPlanArray, setMovesToPlanArray] = useState([]);
  const [movesToUnplanArray, setMovesToUnplanArray] = useState([]);
  const [selectedEnrichedMoveId, setSelectedEnrichedMoveId] = useState();
  const [planLocations, setPlanLocations] = useState([]);
  const [rideModal, setRideModal] = useState(false);
  const [isSchedulerVisible, setIsSchedulerVisible] = useState(false);
  const [isUnassignedListVisible, setIsUnassignedListVisible] = useState(false);
  const [isBufferVisible, setIsBufferVisible] = useState(true);
  const [isAddMoveButtonVisible, setIsAddMoveButtonVisible] = useState(true);
  const [isPlanEditable, setIsPlanEditable] = useState(false);
  const [isPlanSavable, setIsPlanSavable] = useState(false);
  const [cancelModal, setCancelModal] = React.useState({ open: false, move: null });
  const [notSavableMessage, setNotSavableMessage] = React.useState(null);
  const [unsavableMoveIds, setUnsavableMoveIds] = React.useState([]);

  const { log } = new SimpleLogger({ prefix: 'SchedulerProvider', enabled: enableSchedulerLogs });

  React.useEffect(() => {
    // Check for any moves that are queued up to be planned and plan them
    // Expects movesToPlanArray to be an array of move objects (not enrichedMoves)
    if (
      !isInitialLoad &&
      workingPlan &&
      workingPlan.enrichedMoves &&
      workingPlan.enrichedMoves.length >= 0 &&
      movesToPlanArray &&
      Array.isArray(movesToPlanArray) &&
      movesToPlanArray.length > 0
    ) {
      // Make a workign copy of the queue array so we can modify it
      let movesToPlanArrayCopy = [...movesToPlanArray];
      movesToPlanArrayCopy.reduceRight((_, move) => {
        const plannedMoves = scheduleUnplannedMoveWithSiblings(move);
        // Remove this move from the queue once it's planned
        movesToPlanArrayCopy.pop();
        log(
          `Processed queued move plan for move ${move.id}. Resulted in move(s) ${JSON.stringify(
            plannedMoves.map(o => o.id)
          )} appended to the scheduler.`
        );
      }, null);
      // Update the state variable for the queue
      setMovesToPlanArray(movesToPlanArrayCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingPlan, movesToPlanArray]);

  // useEffect to find all locations on the current plan and check savable status of the working plan
  React.useEffect(() => {
    if (isSchedulerVisible && selectedPlan) {
      try {
        if (isInitialLoad) {
          log('Initial load of the working plan...', workingPlan);
          if (workingPlan) {
            runScheduler('Initial Load');
            isInitialLoad = false;
          } else {
            log('Working plan undefined', workingPlan);
          }
        }

        // Set a boolean for the whole plan being uneditable in certain cases
        let newIsEditable = checkPlanIsInPast();
        setIsPlanEditable(newIsEditable);

        if (workingPlan && workingPlan.enrichedMoves && workingPlan.enrichedMoves.length) {
          applyMoveStatusChange();

          // Set planLocations
          setPlanLocations(findEnrichedPlanLocations(workingPlan));

          // Check if plan is savable by
          let ridesAreSavable = checkIfPlanRidesAreValid();
          let planIsSavable = checkIfDBPlanChangeIsValid();
          let unplannedMovesAreSavable = checkIfUnsavedMovesAreValid();

          //If any of the checks on the plan's savablity fail, it needs to be marked as non-savable
          if (ridesAreSavable === false || unplannedMovesAreSavable === false || planIsSavable === false) {
            setIsPlanSavable(false);
          } else {
            setIsPlanSavable(true);
            setNotSavableMessage(null);
          }
        }
      } catch (err) {
        //If an error occurs while checking if plan is savable, set to unsavable to err on the side of caution
        setIsPlanEditable(false);
        setIsPlanSavable(false);
        setNotSavableMessage(`An error occured. Please refresh the plan before making any changes.`);
        // toast.info(`An Error Occurred- please close and reopen the schedule screen.`);
        log(`Error checking if plan is savable- setting to unsavable for safety`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingPlan, unassignedMoves, plansState, isSchedulerVisible, selectedPlan]);

  const applyMoveStatusChange = () => {
    // Detect server side changes to the current plan being worked and
    // if they are detected, under certain conditions, show a toast message then
    // prevent the user from saving their current plan.
    if (plansState === 'query.successful') {
      const databasePlan = plans.find(p => p.id === selectedPlan.id);

      // Try to get the most recent date from either the plan.updatedat or
      // from the updatedat on each of the plan's child moves. Since we are
      // doing date match that could get squirrely, put it all in a try/catch
      // and just default to the plan.updatedat instead if it fails for any
      // reason at all.
      if (!databasePlan) return;
      let dbPlanUpdatedAt = databasePlan.updatedat;
      let latestUpdatedMove = null;
      for (var i; i < databasePlan.moves.length; i++) {
        let moveUpdatedat = databasePlan.move[i].updatedat ? databasePlan.move[i].updatedat : null;
        if (moveUpdatedat && moveUpdatedat > dbPlanUpdatedAt) {
          dbPlanUpdatedAt = moveUpdatedat;
          latestUpdatedMove = databasePlan.move[i];
        }
      }

      //If update was from tookan_sync, do not disable saving
      let lastSyncedMove = {};
      for (var j; j < databasePlan.moves.length; j++) {
        let moveSyncedWithTookan = databasePlan.move[j].synced_with_tookan
          ? databasePlan.move[j].synced_with_tookan
          : null;
        if (moveSyncedWithTookan) {
          lastSyncedMove = databasePlan.move[j];
        }
      }
      const updatedEnrichedMove = selectedPlan.enrichedMoves.find(em => em.id === lastSyncedMove.id);

      // Check if the last move changed by Tookan had a status update
      // Replace that move's status with its new status and rerun the scheduler
      if (latestUpdatedMove && updatedEnrichedMove && latestUpdatedMove.status !== updatedEnrichedMove.status) {
        applyMoveChangesThenRunScheduler(updatedEnrichedMove, { status: lastSyncedMove.status });
      }
    }
  };

  //Checks if newly built rides are in a broken state. If they are, plan cannot be saved
  const checkIfPlanRidesAreValid = () => {
    if (!workingPlan || !workingPlan.enrichedMoves || !workingPlan.enrichedMoves.length) return true;
    let planIsSavable = true;
    workingPlan.enrichedMoves.forEach(enrichedMove => {
      const moveWithOverrides = enrichedMove.withOverrides || {};
      if (moveWithOverrides.move_type === `ride` && !enrichedMove.isPlaceholder) {
        if (enrichedMove.googleEnrichmentStatus !== `successful`) planIsSavable = false;
        if (enrichedMove.arEnrichmentStatus === `failure`) planIsSavable = false;
        if (enrichedMove.apEnrichmentStatus === `failure`) planIsSavable = false;
      }
    });
    if (!planIsSavable) {
      setNotSavableMessage(
        'A ride in your plan has failed to build correctly. Please remove the invalid ride move to save the plan'
      );
    }
    return planIsSavable;
  };

  /**
   * check if the unsaved moves in the plan are in the unplanned moves array- if they are not then the moves have been planned elsewhere or deactivated/canceled
   * @returns Boolean
   */
  const checkIfUnsavedMovesAreValid = () => {
    if (
      !workingPlan ||
      !workingPlan.enrichedMoves ||
      !workingPlan.enrichedMoves.length ||
      !unassignedMoves ||
      !unassignedMoves.length
    )
      return true;
    const unassignedMoveIds = unassignedMoves.map(move => move.id);
    //Get Move Ids of Unsaved enriched moves
    const unsavedWorkingPlanMoves = workingPlan.enrichedMoves.filter(enrichedMove =>
      enrichedMove.move ? enrichedMove.move.plan_id === null : false
    );
    const unsavedWorkingPlanMoveIds = unsavedWorkingPlanMoves.map(move => move.id);
    let planIsSavable = true;
    let invalidUnsavedWorkingPlanMoveIds = [];
    unsavedWorkingPlanMoveIds.forEach(id => {
      //If the unsaved move in the working plan is not in the array of unassigned moves the plan cannot be saved
      if (!unassignedMoveIds.includes(id)) {
        invalidUnsavedWorkingPlanMoveIds.push(id);
        planIsSavable = false;
      }
    });
    setUnsavableMoveIds(invalidUnsavedWorkingPlanMoveIds);
    if (!planIsSavable) {
      setNotSavableMessage(
        'A move in your plan has been saved to a different plan. Unplan this move to enable saving.'
      );
    }
    return planIsSavable;
  };

  const checkIfDBPlanChangeIsValid = () => {
    if (!plansState || !plans || !workingPlan || plansState !== 'query.successful') {
      //If there is no plans state or the plans query is loading, do not prevent saving due to database changes to the plan
      return true;
    }
    // if (plansState === 'query.successful' && selectedPlan && workingPlan && isSchedulerVisible) {
    const databasePlan = plans.find(p => p.id === selectedPlan.id);

    // Try to get the most recent date from either the plan.updatedat or
    // from the updatedat on each of the plan's child moves. Since we are
    // doing date match that could get squirrely, put it all in a try/catch
    // and just default to the plan.updatedat instead if it fails for any
    // reason at all.
    if (!databasePlan) return;
    let dbPlanUpdatedAt = databasePlan.updatedat;

    let workingPlanUpdatedAt = workingPlan.plan ? workingPlan.plan.updatedat : null;

    // Check if the plan from the database is different from the working plan
    if (dbPlanUpdatedAt > workingPlanUpdatedAt) {
      log(`Plans refreshed from the server. The current plan changed while on the scheduler.`, {
        databasePlan,
        dbPlanUpdatedAt,
        workingPlanUpdatedAt,
      });
      setNotSavableMessage(
        'The plan you are working on has just been saved. Please refresh the plan before making any changes.'
      );
      return false;
    } else {
      return true;
    }
  };

  const checkPlanIsInPast = () => {
    if (timelineDate.format(`YYYY-MM-DD`) < dayjs().format(`YYYY-MM-DD`)) {
      setNotSavableMessage('This plan cannot be saved because it has already been worked.');
      return false;
    } else {
      return true;
    }
  };
  // Handle the cancel modal opening
  const handleCancelModalOpen = (input = null) => {
    // Set cancel modal state
    setCancelModal({ open: true, input: input || null });
  };

  // Handle the cancel modal closing
  const handleCancelModalClose = async (output = null) => {
    // TODO: Handle removing "canceled" moves from the enrichedMoves array (the same way unplanning works)
    // If there is an output, use it to set the cancel status of the move (in overrides)
    if (output) {
      let overrides = {
        cancel_status: output.cancelStatus,
        cancel_reason: output.cancelReason,
        move_failed: null,
        payable: true,
        chargeable: true,
      };
      if (
        output.cancelStatus === `canceled` ||
        output.cancelStatus === `started` ||
        output.cancelStatus === `delivered`
      )
        overrides.move_failed = isTimelineDateToday ? dayjs().utc().format() : null;

      // Find the enrichedMove in the workingPlan
      let foundEnrichedMove = null;
      if (workingPlan && workingPlan.enrichedMoves && workingPlan.enrichedMoves.length > 0) {
        foundEnrichedMove = workingPlan.enrichedMoves.find(em => em.id === output.move.id);
      }

      // Unplan the move if its "canceled" cancel_status
      if (output.cancelStatus === `canceled`) {
        overrides.payable = false;
        overrides.chargeable = false;
        // unplanScheduledDrive(foundEnrichedMove);
      }

      // Apply the overrides
      await applyMoveChangesThenRunScheduler(foundEnrichedMove, overrides);
    }

    // Reset cancel modal state
    setCancelModal({ open: false, input: null });
  };

  const runScheduler = (reason, enrichedMovesOverride = undefined, overrideWorkingPlan = undefined) => {
    if (!workingPlan) {
      log(`runScheduler skipped because there is no working plan yet`);
      return;
    }

    let localWorkingPlan = overrideWorkingPlan || workingPlan;
    if (enrichedMovesOverride) localWorkingPlan.enrichedMoves = enrichedMovesOverride;

    log(
      `Changes to the working plan triggered another pass through the scheduler. Reason: ${reason}`,
      localWorkingPlan
    );

    refreshEnrichedMoves(localWorkingPlan.enrichedMoves);

    deleteInvalidPlaceholderRides(localWorkingPlan.enrichedMoves, refreshEnrichedMoves);

    handleEnrichedPlanRides(
      localWorkingPlan,
      () => {
        refreshEnrichedMoves(localWorkingPlan.enrichedMoves);
        refreshPlan(localWorkingPlan);
        log(`Changing ride lane: enriching found ride lane.`, localWorkingPlan);
      },
      () => {
        refreshEnrichedMoves(localWorkingPlan.enrichedMoves);
        refreshPlan(localWorkingPlan);
        log(`Changing ride lane: enriching new ride lane.`, localWorkingPlan);
      },
      deactivateRide,
      isInitialLoad
    );

    injectPlaceholderRideMoves(
      localWorkingPlan.enrichedMoves,
      () => {
        refreshEnrichedMoves(localWorkingPlan.enrichedMoves);
        refreshPlan(localWorkingPlan);
        log(`Refreshing plan: enriching found ride lane.`, localWorkingPlan);
      },
      () => {
        refreshEnrichedMoves(localWorkingPlan.enrichedMoves);
        refreshPlan(localWorkingPlan);
        log(`Refreshing plan: enriching new ride lane.`, localWorkingPlan);
      }
    );

    reclassMoves(localWorkingPlan.enrichedMoves);

    refreshPlan(localWorkingPlan);
  };

  const getInvalidDropIndexes = bundledEnrichedDriveMoves => {
    log(
      `[InvalidDropIndexes] Checking for invalid drop indexes in ${JSON.stringify(
        bundledEnrichedDriveMoves.map(m => m.id)
      )}`
    );

    // Get a list of drop indexes that are off limits
    const invalidIndexes = [];
    bundledEnrichedDriveMoves.forEach((enrichedDriveMove, index) => {
      const prior = bundledEnrichedDriveMoves[index - 1] || { move: {} };
      // const next = bundledEnrichedDriveMoves[index + 1] || { move: {} };

      let invalid = false;
      if (enrichedDriveMove && enrichedDriveMove.linkedEnrichedMove && enrichedDriveMove.linkedEnrichedMove.move) {
        if (prior && prior.move) {
          if (enrichedDriveMove.linkedEnrichedMove.move.id === prior.move.id) {
            // This is the start of a set so do not allow dropping here. Dropping
            // into the next index after this would effectively be like dropping
            // inbetween two moves that are in a set
            invalidIndexes.push(index);
            invalid = true;
          }
        }
      }

      log(`[InvalidDropIndexes]   Checking ${index} (${enrichedDriveMove.id})... ${invalid ? '❌' : '✅'}`);
    });

    return invalidIndexes;
  };

  const applyMoveChangesThenRunScheduler = (enrichedMove, changes) => {
    if (!enrichedMove) {
      return;
    }
    applyMoveChanges(enrichedMove, changes);
    const moveId = enrichedMove.move ? enrichedMove.move.id : 'undefined move';
    runScheduler(`Changes applied to move ${moveId}: ${JSON.stringify(changes)}`);
  };

  const revertMoveChangesThenRunScheduler = (enrichedMove, changes) => {
    revertMoveChanges(enrichedMove, changes);
    const { move } = enrichedMove;
    runScheduler(`Changes reverted from move ${move.id}: ${JSON.stringify(changes)}`);
  };

  const refreshPlan = enrichedPlan => {
    log('🧱 Refreshing plan...', enrichedPlan);
    if (!enrichedPlan) return;
    let newWorkingPlan = { ...enrichedPlan };
    setWorkingPlan(newWorkingPlan);
  };

  //Custom callback function because we only want the suggestion flag to switch to false when the scheduler changes the ride's lane
  const refreshPlanAndSetSuggestionFlag = (ride, isPlaceholder) => {
    //If duration data was not found, ride can not be persisted and therefore must stay a suggestion
    //But we still run the scheduler so the failure flags will be set
    if (ride.googleEnrichmentStatus === 'successful') {
      ride.isPlaceholder = isPlaceholder;
    }
    refreshEnrichedMoves(workingPlan.enrichedMoves);
    refreshPlan(workingPlan);
    // runScheduler('Added new ride');
    // log(`Refreshing plan: enriching found ride lane.`, workingPlan);
  };

  const selectEnrichedMove = move => {
    if (move) {
      log('Selected a move for working with it', move);
      setSelectedEnrichedMoveId(move.id);
    } else {
      log('unselected the selected move');
      setSelectedEnrichedMoveId(null);
    }
  };

  const isSelected = enrichedMove => {
    try {
      return selectedEnrichedMoveId === enrichedMove.id;
    } catch (e) {
      return false;
    }
  };

  const selectPlan = plan => {
    isInitialLoad = true;
    if (plan) {
      const mutablePlan = JSON.parse(JSON.stringify(plan));
      const newEnrichedPlan = buildEnrichedPlan(mutablePlan);
      log('Selected enriched plan:', newEnrichedPlan);
      setSelectedPlan(newEnrichedPlan);
      setSelectedEnrichedMoveId(null);
      refreshPlan(newEnrichedPlan);
    }
  };

  /**
   * Lookup a plan by plan.id in the plans array from the plans provider
   *
   * @param {Number} id The plan id to lookup
   * @returns The found plan object from the plans array else undefined
   */
  const selectPlanById = id => {
    let foundPlan;
    try {
      const planId = Number(id);
      foundPlan = plans.find(plan => plan.id === planId);
    } catch (error) {}
    if (foundPlan) {
      log(`Found a plan by id ${id}. Selecting it as the working plan...`, foundPlan);
      selectPlan(foundPlan);
    } else {
      log(`Unable to select plan by id ${id}`);
    }
    return foundPlan;
  };

  /**
   * Perform a safe find across the workingPlan.enrichedMoves array
   * by the passed in move id to return that to the caller.
   *
   * @param {Number} id The id of a move to find by
   * @returns {EnrichedMove} The enriched move found or null if no matches are found
   */
  const getLocalEnrichedMoveById = id => {
    try {
      let { enrichedMoves = [] } = workingPlan;
      enrichedMoves = enrichedMoves || [];
      const nextEnrichedMove = enrichedMoves.find(enrichedMove => {
        const { move = {} } = enrichedMove;
        return move.id === id;
      });
      return nextEnrichedMove;
    } catch (error) {
      log(`${error.message} occurred trying to find a move by id ${id}`);
      return null;
    }
  };

  /**
   * Get an array of move IDs for what is currently on the plan and
   * therefor showing in the scheduler. This is handy when trying to
   * determine what the user was dropping onto when dragging things
   * around within the schedule and onto the schedule.
   *
   * @returns {Array} An array of move id numbers representing what is planned.
   */
  const getDriveMoveIds = () => {
    let { enrichedMoves = [] } = workingPlan;
    enrichedMoves = enrichedMoves || [];
    return enrichedMoves
      .filter(enrichedMove => {
        const { move = {} } = enrichedMove;
        return move.move_type === 'drive';
      })
      .map(enrichedMove => {
        const { move = {} } = enrichedMove;
        return move.id;
      });
  };

  /**
   * Get the index of a drive move as it is currently positioned in
   * the workingPlan.enrichedMoves array.
   *
   * @param {Number} moveId Move ID to lookup and get the index position of
   * @returns {Number} Found position within the workingPlan.enrichedMoves array or -1 if not found
   */
  const getEnrichedMoveIndexById = moveId => {
    let { enrichedMoves = [] } = workingPlan;
    enrichedMoves = enrichedMoves || [];
    return enrichedMoves.findIndex(enrichedMove => {
      const { move = {} } = enrichedMove;
      return move.id === moveId;
    });
  };

  /**
   * Get the index of a drive move as it is currently positioned in
   * the on-screen schedule (array of drive move bundles only).
   *
   * @param {Number} moveId Move ID to lookup and get the index position of
   * @returns {Number} Found position within the workingPlan.enrichedMoves array or -1 if not found
   */
  const getDriveMoveIndexById = moveId => {
    const driveMoveIds = getDriveMoveIds();
    return driveMoveIds.findIndex(o => o === moveId);
  };

  /**
   * Get the next drive move found in the plan chronologically after
   * the position of the move id provided.
   *
   * @param {Number} moveId The id of the move to start at
   * @returns {EnrichedMove} Found enrichedMove or null if there is no next move found
   */
  // const getNextEnrichedDriveMoveById = moveId => {
  //   const driveMoveIds = getDriveMoveIds();
  //   const moveIdIndex = getDriveMoveIndexById(moveId);

  //   if (moveIdIndex >= driveMoveIds.length - 1) {
  //     // There is no next move
  //     return null;
  //   }

  //   const nextMoveId = driveMoveIds[moveIdIndex + 1];
  //   return getLocalEnrichedMoveById(nextMoveId);
  // };

  /**
   * Shift the position of a move in the plan. This will trigger a recalculation
   * of all placeholder return rides between moves as well as update the times
   * of each move at and below this one. This function should prevent a move
   * from being placed into a position that is in the past or before a move
   * that has already started.
   *
   * @param {EnrichedMove} enrichedMove
   * @param {Number} position
   */
  const reorderEnrichedMove = (enrichedMove, position = -1) => {
    let { enrichedMoves = [] } = workingPlan;
    enrichedMoves = enrichedMoves || [];
    let { withOverrides: move = {} } = enrichedMove;
    move = move || {};

    // Get an array of objects where each object represents a row that shows on the screen
    const bundledEnrichedDriveMoves = enrichedMoves.filter(o => o.move.move_type === 'drive');

    let nextEnrichedMove;
    if (position < bundledEnrichedDriveMoves.length - 1) {
      nextEnrichedMove = bundledEnrichedDriveMoves[position];
      log(`Dropping at position ${position} which is at move ${(nextEnrichedMove || {}).id}`);
      const { move: nextMove } = nextEnrichedMove;

      // TODO: Ensure times and move status' are being honored
      // Return early and do not splice anything if the rules don't pass

      // TODO: If a move is pinnable we use to allow it to be dragged
      // anywhere on the plan's timeline. Should we still or do we need
      // to prevent that at all times?

      // Prevent dragging before a move that is in progress or completed
      if (nextEnrichedMove.inProgress) {
        log(`The move ${nextMove.id} which is after the dropping position is already in progress`);
        toast.warning('Cannot drop before an in progress move', {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        return;
      }
      // Prevent dragging before a move that is in progress or completed
      if (nextEnrichedMove.isCompleted) {
        log(`The move ${nextMove.id} which is after the dropping position is already completed`);
        toast.warning('Cannot drop before an already completed move', {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        return;
      }

      if (!nextMove.pinnable) {
        // TODO: We need to expose the calculated additional durations
        // for each of these then reference those instead of just taking
        // what is on the move. The duration is not just what was planned,
        // but can be different once they start moving the car.
        let draggedMoveDuration =
          (move.lane.duration_sec + move.lane.pickup_inspection_sec + move.lane.delivery_inspection_sec) / 60;

        if (dayjs(nextMove.pickup_arrived).isBefore(dayjs())) {
          log('Move cannot be dropped into the past');
          toast('Move cannot be dropped into the past', {
            position: 'bottom-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          return;
        } else if (dayjs(nextMove.pickup_arrived).subtract(draggedMoveDuration, 'minutes').isBefore(dayjs())) {
          log('Placing move there would push its pickup time into the past');
          toast('Placing move there would push its pickup time into the past', {
            position: 'bottom-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          return;
        } else if (dayjs(nextMove.pickup_arrived).isBefore(dayjs(move.ready_by))) {
          log('Move cannot be dropped before its ready by time');
          toast('Move cannot be dropped before its ready by time', {
            position: 'bottom-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          return;
        } else if (
          dayjs(nextMove.pickup_arrived).subtract(draggedMoveDuration, 'minutes').isBefore(dayjs(move.ready_by))
        ) {
          log('Placing move there would place its pickup time before its ready by time');
          toast('Placing move there would place its pickup time before its ready by time', {
            position: 'bottom-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          return;
        }
      }
    } else {
      log(`We are dropping in the last position`);
    }

    const invalidDropIndexes = getInvalidDropIndexes(
      bundledEnrichedDriveMoves.filter(o => o.move.id !== enrichedMove.move.id)
    );

    if (invalidDropIndexes.includes(position)) {
      log(`Invalid drop location!`, position);
      return;
    }

    // Get the index of the move that is being dragged
    const indexToMove = getDriveMoveIndexById(move.id);

    // If it's just a single move being dragged, go ahead and move it then exit
    if (!enrichedMove.linkedEnrichedMove) {
      shiftMoveBundleSingle(bundledEnrichedDriveMoves, indexToMove, enrichedMove, move, position);
      return;
    }

    // Provide special treatment to dragging sets of moves such as swapping set order
    // and repositioning a set together in the schedule
    if (enrichedMove.linkedEnrichedMove) {
      let { withOverrides: linkedMove = {} } = enrichedMove.linkedEnrichedMove;
      linkedMove = linkedMove || {};
      const draggingMoveType = getDriveTypeFromMove(move);
      const linkedMoveType = getDriveTypeFromMove(linkedMove);

      // Get the actual index position of the moving set based on whether the user
      // picked up the second of the set or the first of the set. We always want the
      // first of the set's indexes. If the move linked to the one being dragged is
      // before the move being dragged (chronologically) then let's use that index
      // instead.
      let swapIndex;
      if (isLinkedMoveBefore(enrichedMove)) {
        swapIndex = indexToMove - 1;
      } else {
        swapIndex = indexToMove + 1;
      }

      // If all we did was drop it on the swap index, then we are gonna swap them then exit
      if (position === swapIndex) {
        if (
          draggingMoveType === 'concierge' ||
          draggingMoveType === 'loaner' ||
          linkedMoveType === 'concierge' ||
          linkedMoveType === 'loaner'
        ) {
          log(`Unable to swap the order of concierge move sets.`, { draggingMoveType, linkedMoveType });
          toast.info(`Unable to swap the order of concierge move sets.`);
          return;
        } else {
          swapMoveBundleSetOrder(bundledEnrichedDriveMoves, indexToMove, enrichedMove, move, position);
          return;
        }
      }

      // This is a customer created round trip move set. Ask first if the
      // dispatcher is trying to split the set up with this drag or if they
      // want to drag the set as a set.
      if (draggingMoveType === 'ops' || linkedMoveType === 'ops') {
        ifConfirmed({
          //title: 'Are you sure?',
          body: "You're dragging a round trip set. Should they be moved as a set or split up?",
          agreeButtonLabel: 'Keep the Set',
          disagreeButtonLabel: 'Split Them Up',
          onAgree: () => shiftMoveBundleSet(bundledEnrichedDriveMoves, indexToMove, enrichedMove, move, position),
          onDisagree: () => shiftMoveBundleSingle(bundledEnrichedDriveMoves, indexToMove, enrichedMove, move, position),
        });
        return;
      }

      // If we made it here without exiting then we know it's a set of moves
      // that are not ops and are not swapping sequence/position but actually
      // moving to another position in the schedule.

      // When dragging sets further down from their current position, the index
      // is offset by one because we need to account for the second move of the
      // set that is actually NOT dragging with it.
      let altPosition = position;
      if (position > indexToMove) {
        altPosition = position - 1;
      }
      shiftMoveBundleSet(bundledEnrichedDriveMoves, indexToMove, enrichedMove, move, altPosition);
    }
  };

  const isLinkedMoveBefore = enrichedMove => {
    let { withOverrides: linkedMove = {} } = enrichedMove.linkedEnrichedMove;
    let { withOverrides: move = {} } = enrichedMove;
    linkedMove = linkedMove || {};
    move = move || {};

    // Return true if the linked move is earlier than the one passed in
    return dayjs(linkedMove.pickup_time).format() <= dayjs(move.pickup_time).format();
  };

  const swapMoveBundleSetOrder = (bundledEnrichedDriveMoves, indexToMove, enrichedMove, move, position) => {
    // Shift the pair but keep the same order
    moveEnrichedMoveBundle(bundledEnrichedDriveMoves, indexToMove, 1, position);

    log(`Swapped the execution order of the linked set`);

    // Regenerate the enrichedMoves array to override in workingPlan
    const explodedEnrichedMoves = explodeBundledArray(bundledEnrichedDriveMoves);

    // Run the scheduler to apply our new version of enrichedMoves to the plan
    runScheduler(`🏓 Rescheduled single planned move ${move.id}`, explodedEnrichedMoves);
  };

  const shiftMoveBundleSet = (bundledEnrichedDriveMoves, indexToMove, enrichedMove, move, position) => {
    // Shift the pair but keep the same order
    let { withOverrides: linkedMove = {} } = enrichedMove.linkedEnrichedMove;
    linkedMove = linkedMove || {};

    // Get the index of the move that is linked to what's being dragged
    const linkedIndexToMove = getDriveMoveIndexById(linkedMove.id);

    log(`Found linked move to drag with it: ${linkedMove.id}`);

    // Ensure we keep them in the same order when dragging them together
    if (dayjs(linkedMove.pickup_time).format() <= dayjs(move.pickup_time).format()) {
      moveEnrichedMoveBundle(bundledEnrichedDriveMoves, linkedIndexToMove, 2, position);
    } else {
      moveEnrichedMoveBundle(bundledEnrichedDriveMoves, indexToMove, 2, position);
    }

    // Regenerate the enrichedMoves array to override in workingPlan
    const explodedEnrichedMoves = explodeBundledArray(bundledEnrichedDriveMoves);

    // Run the scheduler to apply our new version of enrichedMoves to the plan
    runScheduler(`🏓 Rescheduled planned move set [${move.id}, ${linkedMove.id}]`, explodedEnrichedMoves);
  };

  const shiftMoveBundleSingle = (bundledEnrichedDriveMoves, indexToMove, enrichedMove, move, position) => {
    moveEnrichedMoveBundle(bundledEnrichedDriveMoves, indexToMove, 1, position);

    // If we are splitting up a set of ops moves, then remove the parent_move_id
    if (enrichedMove.linkedEnrichedMove) {
      applyMoveChanges(enrichedMove, {
        return_ride_id: null,
        parent_move_id: null,
      });
      applyMoveChanges(enrichedMove.linkedEnrichedMove, {
        return_ride_id: null,
        parent_move_id: null,
      });
    }

    // Regenerate the enrichedMoves array to override in workingPlan
    const explodedEnrichedMoves = explodeBundledArray(bundledEnrichedDriveMoves);

    // Run the scheduler to apply our new version of enrichedMoves to the plan
    runScheduler(`🏓 Rescheduled single planned move ${move.id}`, explodedEnrichedMoves);
  };

  const explodeBundledArray = bundledArray => {
    let newEnrichedMoves = [];
    bundledArray.forEach(driveMove => {
      if (driveMove.enrichedRideBefore) newEnrichedMoves.push(driveMove.enrichedRideBefore);
      newEnrichedMoves.push(driveMove);
      if (driveMove.enrichedRideAfter) newEnrichedMoves.push(driveMove.enrichedRideAfter);
    });
    // try {
    //   log(
    //     `💥 Exploded the bundled drive moves array into the enrichedMoves array.\n`,
    //     '\nBundled Array\n',
    //     bundledArray.map(o => quickMoveDescriptor(o.withOverrides ?? {})).join('\n'),
    //     '\n\nEnriched Moves\n',
    //     enrichedMoves.map(o => quickMoveDescriptor(o.withOverrides ?? {})).join('\n'),
    //     '\n\nNew Enriched Moves\n',
    //     newEnrichedMoves.map(o => quickMoveDescriptor(o.withOverrides ?? {})).join('\n')
    //   );
    // } catch (error) {
    //   log(`💥 Exploded the bundled drive moves array into the enrichedMoves array.`);
    // }
    return newEnrichedMoves;
  };

  //Unused function
  // const quickMoveDescriptor = move => {
  //   try {
  //     // prettier-ignore
  //     return `${move.move_type}-${move.id ?? 'placeholder'} ${move.move_type === 'ride' ? '(' + (move.lane ? move.lane.description : 'unknown lane') + ')' : ''}`;
  //   } catch {
  //     return 'Unknown move descriptor';
  //   }
  // };

  const moveEnrichedMoveBundle = (enrichedMoves, indexToMove, numOfMoves, posToMoveTo) => {
    const removed = enrichedMoves.splice(indexToMove, numOfMoves);
    if (!removed) return; // For reordering unplanned moves
    enrichedMoves.splice(posToMoveTo, 0, ...removed); // Reinject the move(s) spliced out into the new pos
    log(
      `🧠 Moved enriched move bundle(s) ${JSON.stringify(
        removed.map(m => m.id)
      )} from position ${indexToMove} to position ${posToMoveTo}`
    );
  };

  // Add unplanned move to workingPlan
  const scheduleUnplannedMove = (unplannedMove, position = 999, isPair = false) => {
    let { enrichedMoves = [] } = workingPlan;
    enrichedMoves = enrichedMoves || [];

    //Skip this for paired moves so moves are not re-inserted into movesTounplanArray before re-render (and react state update)
    if (!isPair) {
      //Check if move to be planned are in unplanned array (were earlier unplanned and now being replanned)
      const filteredMovesToUnplanArray = movesToUnplanArray.filter(move => move.id !== unplannedMove.id);
      setMovesToUnplanArray(filteredMovesToUnplanArray);
    }

    const prevMoveWithOverrides = enrichedMoves ? withOverrides(enrichedMoves[enrichedMoves.length - 1]) : null;
    let enrichedUnplannedMove = buildEnrichedMove(unplannedMove, prevMoveWithOverrides);

    // If a move that a driver has already assigned or accepted is getting updated, keep the driver's status as is.
    // If it's a new move getting added to the plan, update driver_status to "draft"
    let driverStatus;
    if (
      enrichedUnplannedMove.move.driver_status === 'assigned' ||
      enrichedUnplannedMove.move.driver_status === 'accepted'
    ) {
      driverStatus = enrichedUnplannedMove.move.driver_status;
    } else driverStatus = 'draft';

    applyMoveChanges(enrichedUnplannedMove, {
      driver_app_version: workingPlan.plan.driver.driver_app_version,
      driver_id: workingPlan.plan.driver_id,
      driver_name: workingPlan.plan.driver_name,
      driver_status: driverStatus,
      plan_id: workingPlan.id,
      status: `dispatched`,
    });

    // Get the index of the original
    const sourceIndex = unassignedMoves.findIndex(um => um.id === unplannedMove.id);
    //unassignedMoves.splice(sourceIndex, 1);
    //enrichedMoves.splice(position, 0, enrichedUnplannedMove);
    const migrationResult = migrateBetweenLists(
      unassignedMoves,
      enrichedMoves,
      sourceIndex,
      position,
      enrichedUnplannedMove
    );
    //log(`Migrated move ${unplannedMove.id} from unassignedMoves@${sourceIndex} to enrichedMoves@${position}`, migrationResult);

    // Rob 11/25 - Removed below override of the unassigned moves array
    // because the subscription will trigger a refect from the server
    // once the user persists the change.

    // Update state of the unassinged moves array
    //setUnassignedMoves(migrationResult.sourceClone);

    runScheduler(`🗓️ Scheduling unplanned move: ${unplannedMove.id}`, migrationResult.destClone);
    // selectEnrichedMove(enrichedUnplannedMove);
  };

  /**
   * Moves an item from one list to another list.
   */
  const migrateBetweenLists = (source, destination, sourceIndex, destinationIndex, enrichedMove) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    // const [removed] = sourceClone.splice(sourceIndex, 1);

    destClone.splice(destinationIndex, 0, enrichedMove);

    const result = { sourceClone, destClone };

    return result;
  };

  /**
   *
   * @param {Move} move Move withOverrides to search for a relationship to
   */
  const findLinkedUnassignedMove = move => {
    let attachedDrive;
    //TODO: remove this after transistion to new parent move system
    const childDriveByReturnMoveId =
      move.moveByReturnRideId && move.moveByReturnRideId.move_type === 'drive' ? move.moveByReturnRideId.id : null;
    // Find a drive attached to the move
    const childDriveMoveId =
      move.childMoves &&
      Array.isArray(move.childMoves) &&
      move.childMoves.length &&
      move.childMoves[0].move_type === 'drive'
        ? move.childMoves[0].id
        : childDriveByReturnMoveId;
    if (move.parent_move_id || childDriveMoveId) {
      if (move.parent_move_id)
        attachedDrive = unassignedMoves.find(um => um.id === move.parent_move_id && um.move_type === `drive`);
      else attachedDrive = unassignedMoves.find(um => um.id === childDriveMoveId && um.move_type === `drive`);
    }
    return attachedDrive;
  };

  /**
   * Lookup any moves related to the unplannedMove parameter and
   * plan those along with the unplannedMove passed in.
   *
   * @param {Move} move The unplanned move that is to be added to the schedule
   * @param {Number} position The array index position to insert after
   * @returns {Array} Returns an array of moves that were inserted in the order they were inserted at
   */
  const scheduleUnplannedMoveWithSiblings = (move, position = 999) => {
    let { enrichedMoves = [] } = workingPlan;
    enrichedMoves = enrichedMoves || [];

    let attachedDrive = null;
    let plannedMoves = [];

    log(
      `🗓️ scheduleUnplannedMoveWithSiblings: BEFORE splicing in ${JSON.stringify(
        enrichedMoves.map(o => o.move.id)
      )} at position ${position}`
    );

    // Check for the working plan
    if (workingPlan && isSchedulerVisible) {
      // Find a drive attached to the move
      attachedDrive = findLinkedUnassignedMove(move);

      // Detect concierge + loaner combo and plan the moves
      if (attachedDrive) {
        //If moves have been unplanned and are now being replanned, remove them from the movesToUnplan array
        const filteredMovesToUnplanArray = movesToUnplanArray.filter(
          unplanMove => move.id !== unplanMove.id && attachedDrive.id !== unplanMove.id
        );
        setMovesToUnplanArray(filteredMovesToUnplanArray);
        // Determine the order the moves should be planned
        if (dayjs(move.ready_by).format() <= dayjs(attachedDrive.ready_by).format()) {
          scheduleUnplannedMove(move, position, true);
          scheduleUnplannedMove(attachedDrive, position + 1, true);
          plannedMoves = [move, attachedDrive];
        } else {
          scheduleUnplannedMove(attachedDrive, position, true);
          scheduleUnplannedMove(move, position + 1, true);
          plannedMoves = [attachedDrive, move];
        }
      }

      // Otherwise, plan the move
      else {
        scheduleUnplannedMove(move, position);
        plannedMoves = [move];
      }
    }

    log(
      `🗓️ scheduleUnplannedMoveWithSiblings: AFTER splicing in: ${JSON.stringify(
        enrichedMoves.map(o => o.move.id)
      )} at position ${position}`
    );

    return plannedMoves;
  };

  const planMove = (move, position = 999) => {
    if (!isSchedulerVisible) return;

    try {
      const plannedMoves = scheduleUnplannedMoveWithSiblings(move, position);
      if (Array.isArray(plannedMoves) && plannedMoves.length > 1) {
        toast.info(`Adding combo moves: #${plannedMoves[0].id} and #${plannedMoves[1].id}.`);
      }
    } catch (error) {
      toast.info(`Failed to plan move because ${error.message}`, error);
      log(`Failed to plan move because ${error.message}`, error);
    }
  };

  // Assign withOverrides move to the working selected plan
  const tryToPlanMove = (move, position = 999, isGroupable) => {
    if (!isSchedulerVisible) return;

    const { plan = {} } = workingPlan;

    // Show warning if driver does not fit the criteria
    let disqualifications = getDriverDisqualifications(plan.driver_id, move);
    const attachedDrive = findLinkedUnassignedMove(move);
    if (attachedDrive) {
      let disqualifications2 = getDriverDisqualifications(plan.driver_id, attachedDrive);
      // Combine the arrays with concat() but prevent duplicates with filter()
      disqualifications = disqualifications.concat(
        disqualifications2.filter(item => disqualifications.indexOf(item) < 0)
      );
    }

    if (isGroupable && disqualifications.length) {
      ifConfirmed({
        body: () => (
          <>
            <Typography>
              This move is potentially groupable with other moves. If you would like to plan it as a group, use the
              "Plan Group" action from the unassigned moves list. Driver also does not meet the criteria of this move!
              The driver is missing the following required capabilities:
            </Typography>
            {disqualifications.map(d => (
              <Chip label={d} style={{ marginTop: '10px', marginRight: '10px' }} />
            ))}
          </>
        ),
        onAgree: () => planMove(move, position),
      });
    } else if (isGroupable) {
      ifConfirmed({
        body: () => (
          <>
            <Typography>
              This move is potentially groupable with other moves. If you would like to plan it as a group, use the
              "Plan Group" action from the unassigned moves list. Are you sure you want to plan it alone?
            </Typography>
          </>
        ),
        onAgree: () => planMove(move, position),
      });
    } else if (disqualifications.length) {
      ifConfirmed({
        body: () => (
          <>
            <Typography>
              Driver does not meet the criteria of this move! The driver is missing the following required capabilities:
            </Typography>
            {disqualifications.map(d => (
              <Chip label={d} style={{ marginTop: '10px', marginRight: '10px' }} />
            ))}
          </>
        ),
        onAgree: () => planMove(move, position),
      });
    } else planMove(move, position);
  };

  // Updates move config on unplan. Other updates to config should occur here.
  const moveConfigChangeOnUnplan = config => {
    if (config && config.triangle_move) {
      config.triangle_move = false;
    }
    return config;
  };

  const unplanScheduledDrive = (enrichedMoveToUnplan, refresh = false, isCallback = false) => {
    //Only allow unplanning if status is "dispatched"
    if (
      !enrichedMoveToUnplan ||
      !enrichedMoveToUnplan.move ||
      (enrichedMoveToUnplan.move.status !== 'dispatched' &&
        enrichedMoveToUnplan.move.status !== null &&
        enrichedMoveToUnplan.move.status !== 'pickup started')
    ) {
      log('   ⛔ Cannot unplan this move');
      return;
    }
    let { enrichedMoves = [] } = workingPlan;
    enrichedMoves = enrichedMoves || [];
    //Set to 0 so it will never match a move (null or undefined could match another null or undefined)
    const moveId = enrichedMoveToUnplan && enrichedMoveToUnplan.move ? enrichedMoveToUnplan.move.id : 0;

    const rideBeforeToDeactivate = enrichedMoveToUnplan.enrichedRideBefore || null;
    const rideAfterToDeactivate = enrichedMoveToUnplan.enrichedRideAfter || null;

    const { config } = enrichedMoveToUnplan.move;
    const overrideConfig = moveConfigChangeOnUnplan(config);

    //Moves to be unplanned are set in an array that will be upserted
    applyMoveChanges(enrichedMoveToUnplan, {
      config: overrideConfig,
      driver_app_version: null,
      driver_id: null,
      driver_name: null,
      driver_status: `unassigned`,
      plan_id: null,
      rate_class_override: 0,
      status: null,
    });
    movesToUnplanArray.push(enrichedMoveToUnplan);
    setMovesToUnplanArray(movesToUnplanArray);
    if (rideAfterToDeactivate) deactivateRide(rideAfterToDeactivate, selectedEnrichedMoveId, true);
    if (rideBeforeToDeactivate) deactivateRide(rideBeforeToDeactivate, selectedEnrichedMoveId, false);

    const moveToUnplanIndex = enrichedMoves.findIndex(
      enrichedMove => enrichedMove.move && enrichedMove.move.id === moveId
    );
    enrichedMoves.splice(moveToUnplanIndex, 1);

    //check for attached drives (for moves in concierge/loaner or round-trip move pairs)
    const pairedDriveToDeactivate = enrichedMoveToUnplan.linkedEnrichedMove;

    if (!isCallback && pairedDriveToDeactivate) {
      const draggingMoveType = getDriveTypeFromMove(enrichedMoveToUnplan.move);
      // If this is part of an operational 'round trip' (not concierge-loaner), ask confirmation before removing set
      if (draggingMoveType === 'ops') {
        ifConfirmed({
          //title: 'Are you sure?',
          body: "You're unplanning a round trip set. Do you want to remove the paired move as well?",
          agreeButtonLabel: 'Unplan Them Both',
          disagreeButtonLabel: 'Only Unplan One',
          onAgree: () => {
            //We run the function within itself so that any rides attached to the paired drive will also be unplanned
            unplanScheduledDrive(pairedDriveToDeactivate, false, true);
          },
          onDisagree: () => {
            runScheduler(`Unplanned move: ${moveId}`);
            setSelectedEnrichedMoveId(null);
          },
        });
        return;
      } else {
        //We run the function within itself so that any rides attached to the paired drive will also be unplanned
        unplanScheduledDrive(pairedDriveToDeactivate, false, true);
      }
    } else {
      runScheduler(`Unplanned move: ${moveId}`);
      setSelectedEnrichedMoveId(null);
    }
  };

  /**
   *
   * @param {Object} enrichedRideToUnplan The enriched form of the ride to be deactivated
   * @param {Number} enrichedParentMoveId The ID of the associated drive of the ride to be deactivated
   * @param {Boolean} isAfter True if ride is after assoc. drive, false if ride is before (defaults to true)
   * @param {Boolean} refresh If true, scheduler with refresh after ride is deactivated (defaults to false)
   * @returns
   */
  const deactivateRide = (enrichedRideToUnplan = {}, enrichedParentMoveId, isAfter = true, refresh = false) => {
    if (!enrichedRideToUnplan) return;
    const rideWithOverrides = enrichedRideToUnplan.withOverrides;
    if (
      !rideWithOverrides ||
      (rideWithOverrides.status !== null && rideWithOverrides.status !== `dispatched`) ||
      rideWithOverrides.move_type !== `ride`
    ) {
      log('   ⛔ Cannot deactivate this ride');
      return;
    }
    let { enrichedMoves = [] } = workingPlan;
    enrichedMoves = enrichedMoves || [];
    const rideId = rideWithOverrides.id;
    if (rideId) {
      //Delete Existing Ride
      const moveToUnplanIndex = enrichedMoves.findIndex(enrichedMove => enrichedMove.id === rideId);
      enrichedMoves.splice(moveToUnplanIndex, 1);

      //Moves to be unplanned are set in an array that will be upserted
      applyMoveChanges(enrichedRideToUnplan, {
        active: 0,
        driver_app_version: null,
        driver_id: null,
        driver_name: null,
        driver_status: `unassigned`,
        plan_id: null,
        status: null,
      });
      movesToUnplanArray.push(enrichedRideToUnplan);
      setMovesToUnplanArray(movesToUnplanArray);
      refresh && runScheduler(` 🕳️ Unassigned Ride ${enrichedRideToUnplan.id}`);
    } else {
      //Find index of parent drive
      const enrichedParentMoveIndex = enrichedMoves.findIndex(enrichedMove => enrichedParentMoveId === enrichedMove.id);
      //If parentMove is not found, return to avoid splicing the wrong move
      if (enrichedParentMoveIndex === -1) return;
      //delete ride from its index in the array
      if (isAfter) {
        enrichedMoves.splice(enrichedParentMoveIndex + 1, 1);
      } else {
        enrichedMoves.splice(enrichedParentMoveIndex - 1, 1);
      }
      refresh && runScheduler(' 🕳️ Unassigned Ride');
    }
  };

  /** Unassign all moves on the plan */
  const unplanAllMoves = () => {
    let enrichedMovesArray = workingPlan.enrichedMoves || [];

    // Check for enrichedMoves array
    if (enrichedMovesArray.length > 0) {
      // Check if we can actually unplan all moves
      let canUnplanAll = true;
      enrichedMovesArray.forEach(em => {
        if (em.inProgress || em.isCompleted || em.isCanceled || em.isFailed) canUnplanAll = false;
      });

      // Start unplanning the moves if possible
      if (canUnplanAll) {
        enrichedMovesArray.forEach(em => {
          const moveWithOverrides = em.withOverrides || {};

          // Drive Moves
          if (moveWithOverrides.move_type === `drive`) {
            // if (moveWithOverrides.return_ride_id) {
            //   if (em.enrichedRideBefore && em.enrichedRideBefore.id === moveWithOverrides.return_ride_id)
            //     applyMoveChanges(em, { return_ride_id: null });
            //   if (em.enrichedRideAfter && em.enrichedRideAfter.id === moveWithOverrides.return_ride_id)
            //     applyMoveChanges(em, { return_ride_id: null });
            // }
            applyMoveChanges(em, {
              driver_app_version: null,
              driver_id: null,
              driver_name: null,
              driver_status: `unassigned`,
              plan_id: null,
              status: null,
            });
            movesToUnplanArray.push(em);
          }

          // Ride Moves
          if (moveWithOverrides.move_type === `ride`) {
            applyMoveChanges(em, {
              active: 0,
              driver_app_version: null,
              driver_id: null,
              driver_name: null,
              driver_status: `unassigned`,
              lyft_trigger_id: null,
              plan_id: null,
              status: null,
            });
            movesToUnplanArray.push(em);
          }
        });

        setMovesToUnplanArray(movesToUnplanArray);
        workingPlan.enrichedMoves = [];
        runScheduler(`Unplanned all moves on this plan.`);
      } else toast.warning(`Can't unplan all moves. Some are in-progress, completed, or canceled!`);
    } else toast.warning(`Can't unplan all moves. There are no moves to unplan!`);
  };

  /**
   * Save the pending changes on the plan.
   */
  const savePlanChanges = async (shouldCloseScheduler = true) => {
    log('Saving plan changes from:', selectedPlan);
    log('to...', workingPlan);
    let { enrichedMoves = [], id: planId } = workingPlan;

    //Move to handleUpsertMoves
    //Set plan_id of all moves to be upserted
    enrichedMoves.forEach(em => {
      applyMoveChanges(em, { plan_id: planId });
    });

    let unplannableMovesArray = [];
    movesToUnplanArray.forEach(em => {
      if (em.id && em.id !== null) {
        unplannableMovesArray.push(em);
      }
    });

    //remove rides before this upsert
    let updatedRes = await handleUpsertMoves(enrichedMoves, unplannableMovesArray, planId);
    let upsertedMoves = updatedRes.upsertedMoves;
    let updatedPlan = updatedRes.updatedPlan;

    if (!shouldCloseScheduler) {
      upsertedMoves = upsertedMoves.filter(upsertedMove => upsertedMove.plan_id !== null);
      //update the working plan's updatedat
      let newPlan = workingPlan.plan;
      newPlan.updatedat = updatedPlan.updatedat;
      delete newPlan.moves;
      newPlan.moves = upsertedMoves;
      let newEnrichedPlan = buildEnrichedPlan(newPlan);
      runScheduler('Plan in progress saved', undefined, newEnrichedPlan);
    } else {
      hideScheduler();
    }
  };

  // Find the locations on an enrichedPlan
  const findEnrichedPlanLocations = enrichedPlan => {
    if (enrichedPlan && enrichedPlan.enrichedMoves && enrichedPlan.enrichedMoves.length > 0) {
      // Find and collect all locations (filter out dupes)
      let foundLocations = [];
      enrichedPlan.enrichedMoves.forEach((enrichedMove, i) => {
        const move = enrichedMove.withOverrides;
        if (move.lane) {
          if (move.lane.pickup) {
            const foundLocationIds = foundLocations.map(loc => loc.id);
            if (!foundLocationIds.includes(move.lane.pickup.id)) foundLocations.push(move.lane.pickup);
          }
          if (move.lane.delivery) {
            const foundLocationIds = foundLocations.map(loc => loc.id);
            if (!foundLocationIds.includes(move.lane.delivery.id)) foundLocations.push(move.lane.delivery);
          }
        }
      });

      // Sort the found locations and return
      foundLocations.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });

      return foundLocations;
    } else return [];
  };

  /**
   * Check if a move can be assigned to a driver
   *
   * @param {Number} driverId The id of the driver to check
   * @param {Move} move The withOverrides move object to check against
   * @returns {Boolean} True if the move can be assigned to the driver
   */
  const checkMoveAssign = (driverId = null, move = null) => {
    if (!driverId || !move) return false;
    const disqualifications = getDriverDisqualifications(driverId);
    return disqualifications.length < 1;
  };

  /**
   * Get an array of all things that make this driver disqualified for being
   * assigned this move. If they are disqualified, then the array will be populated
   * with at least one string. The strings themselves will be the names of the tags
   * (qualifications) that they lack but are required for this move.
   *
   * @param {Number} driverId The id of the driver to check
   * @param {Move} move The withOverrides move object to check against
   * @returns {Array} Array of disqualification strings (derived from driver tag names)
   */
  const getDriverDisqualifications = (driverId, move) => {
    let disqualifications = [];
    if (!driverId || !move) return disqualifications;
    try {
      const driver = getDriverById(driverId);
      const tags =
        driver && driver.config && driver.config.attributes
          ? getDriverTagsFromAttributes(driver.config.attributes)
          : [];

      // Drivers that can drive a stick are flagged with a tag called manual
      if (move.manual_flag && !tags.includes(`manual`)) {
        disqualifications.push('manual');
      }

      //Drivers 

      // Drivers that have received the extra training to do concierge moves get a tag indicating that
      if (
        (move.consumer_pickup || move.consumer_type === `loaner`) &&
        !tags.includes(`concierge`) &&
        !tags.includes(`concierge trained`)
      ) {
        disqualifications.push('concierge');
      }

      // Drivers that have received the extra training to do railyard moves get a tag indicating that
      if (move?.hangtags?.length && move?.hangtags?.[0]?.type === 'yard' && !tags.includes(`railyard`)) {
        disqualifications.push('railyard');
      }

      // Carmax moves require custom training for drivers. Customer ids are 2 and 19 in both prod and test db's.
      const { customer = {} } = move;
      if (!tags.includes(`kmx`) && [2, 19].includes(customer.id)) {
        disqualifications.push('kmx');
      }

      //GMA moves require custom training for drivers. 
      //Their config should include a custom values entry with a key of "genesis_certified_driver" and a value of "true".
      //We'll check against this the driver's tags to see if they're qualified for the move.
      const customerConfig = sdk.configs.enrichCustomerConfig(move?.customer?.config, move?.customer?.organization?.config)
      if (!tags.includes(`genesis`) && (customerConfig?.customer_values?.gma_program === 'true' || customerConfig?.gma_program === true)) {
        disqualifications.push('genesis');
      }
    } catch (error) {
      log(`Failed to check driver disqualifications for move ${move.id} because ${error.message}`);
    } finally {
      return disqualifications;
    }
  };

  const openRideModal = (enrichedRide, enrichedParentMove, isAfter) => {
    setRideModal({ ...rideModal, open: true, input: { enrichedRide, enrichedParentMove, isAfter } });
  };
  const closeRideModal = () => {
    setRideModal({ ...rideModal, open: false, input: null });
  };

  const showScheduler = () => {
    setIsSchedulerVisible(true);
    setIsUnassignedListVisible(checkPlanIsInPast());
    setMovesToUnplanArray([]);
    setMovesToPlanArray([]);
    setIsPlanEditable(false);
    setIsPlanSavable(false);
    setNotSavableMessage(null);
    setUnsavableMoveIds([]);
  };
  const hideScheduler = () => {
    setIsSchedulerVisible(false);
    setIsUnassignedListVisible(false);
    setMovesToUnplanArray([]);
    setMovesToPlanArray([]);
    setIsPlanEditable(false);
    setIsPlanSavable(false);
    setNotSavableMessage(null);
    setUnsavableMoveIds([]);
    // Rob 11/17 - Added refetch here to refresh the unplanned moves array
    // after the scheduler planning session is completed so that the moves that
    // got planned but then maybe the user hit cancel still show in the unplanned
    // moves array on the next entry into the scheduler
    refetchPlansFromServer();
  };

  // Export state variables by adding them to context
  const context = {
    isInitialLoad,
    selectPlan,
    selectPlanById,
    selectedPlan,
    workingPlan,
    setWorkingPlan,
    runScheduler,
    refreshPlan,
    isSchedulerVisible,
    queueMoveForPlanning: move => {
      let movesToPlanArrayCopy = [...movesToPlanArray];
      movesToPlanArrayCopy.push(move);
      setMovesToPlanArray(movesToPlanArrayCopy);
    },
    showScheduler,
    hideScheduler,
    isUnassignedListVisible,
    setIsUnassignedListVisible,
    selectEnrichedMove,
    unselectEnrichedMove: () => selectEnrichedMove(null),
    selectedEnrichedMoveId,
    setSelectedEnrichedMoveId,
    isSelected,
    rideModal,
    openRideModal: openRideModal,
    closeRideModal: closeRideModal,
    savePlanChanges,
    applyMoveChangesThenRunScheduler,
    revertMoveChangesThenRunScheduler,
    checkMoveAssign,
    getDriverDisqualifications,
    scheduleUnplannedMove,
    scheduleUnplannedMoveWithSiblings,
    planMove,
    tryToPlanMove,
    unplanScheduledDrive,
    deactivateRide,
    unplanAllMoves,
    planLocations,
    findEnrichedPlanLocations,
    isBufferVisible,
    showBuffers: () => setIsBufferVisible(true),
    hideBuffers: () => setIsBufferVisible(false),
    isAddMoveButtonVisible,
    showAddMoveButton: () => setIsAddMoveButtonVisible(true),
    hideAddMoveButton: () => setIsAddMoveButtonVisible(false),
    getLocalEnrichedMoveById,
    getDriveMoveIds,
    getDriveMoveIndexById,
    getEnrichedMoveIndexById,
    reorderEnrichedMove,
    ifConfirmed,
    refreshPlanAndSetSuggestionFlag,
    isPlanEditable,
    setIsPlanEditable,
    isPlanSavable,
    setIsPlanSavable,
    findLinkedUnassignedMove,
    handleCancelModalOpen,
    notSavableMessage,
    unsavableMoveIds,
  };

  return (
    <SchedulerContext.Provider value={context}>
      <ConfirmationModal />
      <CancelMoveModal
        open={cancelModal.open}
        input={cancelModal.input}
        onSave={async cancelStatus => {
          handleCancelModalClose(cancelStatus);
        }}
        onClose={() => {
          handleCancelModalClose();
        }}
      />
      {children}
    </SchedulerContext.Provider>
  );
}

const useScheduler = () => useContext(SchedulerContext);

export { useScheduler, SchedulerProvider };
