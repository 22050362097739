// DEPENDENCIES -------------------------------------------------- //

import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useWorkflow } from '../WorkflowComponents/WorkflowProvider';
import { useMoveOutcome } from './MoveOutcomeProvider';

import WorkflowStepper from '../WorkflowComponents/WorkflowStepper';
import Step from '../WorkflowComponents/Step';
import MoveOutcomeReview from './MoveOutcomeReview';

// COMPONENT -------------------------------------------------- //

export default function MoveOutcomeContent({ workflow = {}, move }) {
  const cls = useStyles();

  const { workflowSteps, trackedScreens, currentScreen, handleInitialLoad } = useWorkflow();
  const { outcome, accessorials, setAccessorials, selectedAccessorialId, setSelectedAccessorialId } = useMoveOutcome();

  // Handle the initial load with a function that sets the state
  React.useEffect(() => {
    handleInitialLoad(workflow);
    // eslint-disable-next-line
  }, [workflow]);

  if (workflowSteps && workflowSteps.length) {
    return (
      <div className={cls.content}>
        <Spacer size='xl' />

        <WorkflowStepper screens={trackedScreens} selectedScreen={currentScreen} />

        <Spacer size='xl' />

        {workflowSteps?.map((step, i) => {
          return (
            <React.Fragment key={`step-id-${step?.id}`}>
              <Step step={step} />
            </React.Fragment>
          );
        })}

        {currentScreen?.id === `review` ? (
          <MoveOutcomeReview
            move={move}
            outcome={outcome}
            accessorials={accessorials}
            setAccessorials={setAccessorials}
            selectedAccessorialId={selectedAccessorialId}
            setSelectedAccessorialId={setSelectedAccessorialId}
          />
        ) : null}
      </div>
    );
  }
  return null;
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
  },
}));
