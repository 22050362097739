import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core/';

const GET_CUSTOMERS = gql`
  query get_customers {
    customers(order_by: { name: asc }) {
      id
      name
    }
  }
`;

export default function CustomerSelect(props) {
  const { data } = useQuery(GET_CUSTOMERS);

  if (data && data.customers && data.customers.length > 0) {
    const customers = data.customers;
    return (
      <TextField
        fullWidth
        select
        disabled={props.disabled ? props.disabled : false}
        required={props.required ? props.required : false}
        name='customer'
        label={props.label ? props.label : 'Customer'}
        placeholder='Select a customer...'
        size='small'
        variant='outlined'
        value={props.value}
        onChange={props.onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                group
              </Icon>
            </InputAdornment>
          ),
        }}
      >
        {props.selectAllItem ? (
          <MenuItem key={'All Customers'} value={0}>
            All Customers
          </MenuItem>
        ) : (
          <MenuItem key={'Select a Customer'} value={0}>
            <em>Select a Customer</em>
          </MenuItem>
        )}
        {customers.map(customer => (
          <MenuItem key={customer.name} value={customer.id}>
            {customer.name} ({customer.id})
          </MenuItem>
        ))}
      </TextField>
    );
  }
  return null;
}
