export const buildDraftOrder = (moves, numberOfDrivers) => {
    // Group moves by lane ID
    const movesByLane = moves.reduce((acc, move) => {
        const laneId = move.lane.id;
        if (!acc[laneId]) {
            acc[laneId] = [];
        }
        acc[laneId].push(move);
        return acc;
    }, {});

    // Sort within each lane by SLA/batch
    Object.values(movesByLane).forEach(laneMoves => {
        laneMoves.sort((a, b) => {
            const timeA = a.deliver_by;
            const timeB = b.deliver_by;
            return timeA - timeB;
        });
    });

    const result = [];
    // Process one lane at a time completely before moving to the next
    Object.values(movesByLane).forEach(laneMoves => {
        result.push(...laneMoves);
    });

    return result;
};

const createDriverMove = (move) => ({
    move
});

const createDriverReturnRide = (move) => ({
    move: {
        ...move,
        id: null,
        move_type: 'ride',
        deliver_by: null,
        lane: move.lane?.inverse
    }
});

const createDriverExtraRide = (toLane) => ({
    move: {
        id: null,
        move_type: 'ride',
        deliver_by: null,
        lane: toLane
    }
});

const createChaseDriverMove = (type, move, chaseVehicle) => ({
    type,
    move: {
        id: null,
        move_type: 'drive',
        deliver_by: null,
        lane: type === 'drive' ? move.lane : move.lane?.inverse,
        customer: move.customer,
        vehicle_make: chaseVehicle.make,
        vehicle_model: chaseVehicle.model,
        vehicle_vin: chaseVehicle.vin,
    }
});

const shouldCreateChaseMoves = (isLastDriverInRotation, isLastMoveInLocation, isPickupLocationChange) => {
    // Create chase moves if:
    // 1. It's the last driver in rotation (unless it's also a pickup location change)
    // 2. It's the last move in a location
    // 3. It's a pickup location change (unless it's also the last driver in rotation)
    return (isLastDriverInRotation && !isPickupLocationChange) ||
           isLastMoveInLocation ||
           (isPickupLocationChange && !isLastDriverInRotation);
};

const handlePickupLocationChange = (move, currentMoveIndex, currentDriverIndex, drivers, draftMoves, driverMoves) => {
    const remainingMoves = draftMoves.slice(currentMoveIndex + 1);
    console.log(`lane change, remaining moves total: ${remainingMoves.length}`);

    // Calculate remaining drivers in current rotation
    const remainingDriversInRotation = drivers.length - currentDriverIndex;
    console.log(`remaining drivers in rotation: ${remainingDriversInRotation}`);

    if (remainingMoves.length > 0 && remainingDriversInRotation > 0) {
        // Add shuttle rides for remaining drivers
        for (let i = currentDriverIndex; i < drivers.length; i++) {
            console.log(`move: ${currentMoveIndex} adding shuttle ride for driver: ${i}`);
            // Add ride to new location
            driverMoves[i].moves.push(createDriverReturnRide(move));
            driverMoves[i].moves.push(createDriverExtraRide(move.lane));
        }
    }

    console.log(`move: ${currentMoveIndex} pickup location change: `, move.lane.pickup);
    return 0; // Reset currentDriverIndex
};

const assignMovesToDrivers = (drivers, draftMoves, chaseVehicle) => {
    const driverMoves = drivers.map(driver => ({
        driver,
        moves: [],
    }));
    const chaseMoves = [];

    let currentDriverIndex = 0;
    let lastPickupLocation = null;
    let currentMoveIndex = 0;

    draftMoves.forEach(move => {
        const currentPickupLocation = move.lane.pickup;
        const isPickupLocationChange = lastPickupLocation && lastPickupLocation !== currentPickupLocation;
        const isLastDriverInRotation = (currentDriverIndex + 1) % drivers.length === 0;
        const isLastMoveInLocation = !draftMoves.find(m => 
            m !== move && 
            m.lane.pickup === currentPickupLocation &&
            draftMoves.indexOf(m) > currentMoveIndex  // Only look at remaining moves
        ) || currentMoveIndex === draftMoves.length - 1;  // Also true if it's the last move overall

        // Handle pickup location change
        if (isPickupLocationChange) {
            currentDriverIndex = handlePickupLocationChange(
                move,
                currentMoveIndex,
                currentDriverIndex,
                drivers,
                draftMoves,
                driverMoves
            );
        }

        // Regular move assignment
        console.log(`move: ${currentMoveIndex} assigning move to driver: ${currentDriverIndex}`);
        driverMoves[currentDriverIndex].moves.push(createDriverMove(move));
        driverMoves[currentDriverIndex].moves.push(createDriverReturnRide(move));
        currentMoveIndex++;
        // Create chase moves if needed
        console.log(`Queue: isLastDriverInRotation: ${isLastDriverInRotation}, isLastMoveInLocation: ${isLastMoveInLocation}, isPickupLocationChange: ${isPickupLocationChange}`);
        if (shouldCreateChaseMoves(isLastDriverInRotation, isLastMoveInLocation, isPickupLocationChange)) {
            // Only create one set of chase moves per location change
            if (!isPickupLocationChange) {
                buildChaseDriverMoves(chaseMoves, move, chaseVehicle);
            }
        }

        // Update tracking variables
        lastPickupLocation = currentPickupLocation;
        currentDriverIndex = (currentDriverIndex + 1) % drivers.length;
        if (currentDriverIndex === 0) {
            console.log(`Queue: resetting driver index to 0`);
        }
    });

    return { driverMoves, chaseMoves };
};

const buildChaseDriverMoves = (chaseMoves, move, chaseVehicle) => {
    console.log(`Building chase moves, chaseVehicle: `, chaseVehicle, move
    );
    chaseMoves.push(createChaseDriverMove('drive', move, chaseVehicle));
    chaseMoves.push(createChaseDriverMove('hopdriver', move, chaseVehicle));
};

const logSimplifiedPlan = (plan) => {
    return;
    console.log('Chase Driver:', plan.chaseDriver.driver.id);
    console.log(plan.chaseDriver.moves.map(move => move.type));
    
    console.log('\nDrivers:');
    plan.drivers.forEach(driver => {
        console.log(`\nDriver ${driver.driver.id}:`); // Updated to use driver.driver.id
        console.log(driver.moves.map(move => ({
            type: move.type,
            moveId: move.move?.id || 'no-move'
        })));
    });
};

export const buildChasePlan = (chaseDriver, drivers, draftMoves, chaseVehicle) => {
    const { driverMoves, chaseMoves } = assignMovesToDrivers(drivers, draftMoves, chaseVehicle);

    const plan = {
        chaseDriver: {
            driver: chaseDriver,
            moves: chaseMoves,
        },
        drivers: driverMoves,
    };

    // Add debug logging
    logSimplifiedPlan(plan);

    return plan;
};

export const convertPlanToComponentFormat = (plan) => {
    const plans = [];
    
    // Only add chase driver plan if it exists
    if (plan.chaseDriver?.driver) {
        plans.push({
            driver: plan.chaseDriver.driver,
            moves: plan.chaseDriver.moves.map(moveWrapper => moveWrapper.move || moveWrapper),
            isSpecial: true
        });
    }

    // Add regular driver plans
    return [
        ...plans,
        ...plan.drivers.map(({ driver, moves }) => ({
            driver,
            moves: moves.map(moveWrapper => moveWrapper.move || moveWrapper),
            isSpecial: false
        }))
    ];
};

