//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Tooltip, IconButton, Icon } from '@material-ui/core';

import { useTools } from '../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function IconBack({ className = null, tooltipPlacement = null, ...rest }) {
  const cls = useStyles();
  const { goToPreviousPage } = useTools();

  const getClassNames = () => {
    let classNames = [cls.iconBtn];
    if (className) classNames.push(className);
    return classNames.join(` `);
  };

  return (
    <Tooltip placement={tooltipPlacement || 'top'} title='Back'>
      <IconButton className={getClassNames()} onClick={() => goToPreviousPage()} {...rest}>
        <Icon>arrow_back</Icon>
      </IconButton>
    </Tooltip>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  iconBtn: {
    padding: theme.spacing(1),
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
  },
}));
