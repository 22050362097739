import { gql } from 'graphql-tag';

const GET_CHASE_VEHICLES = gql`
  query get_chasevehicles {
    chasevehicles {
      id
      vin
      make
      model
      capacity
    }
  }
`;

const GET_ASSIGNED_CHASE_VEHICLES_BY_CHASE_ID = gql`
  query get_chasevehicles_assigned_by_chase_id($chase_id: bigint!) {
    chasevehiclesassigned(where: { chase_vehicle_id: { _eq: $chase_id } }) {
      id
      chase_vehicle_id
      vin
      make
      model
      capacity
      customer_id
      first_move_id
      last_move_id
      organization_id
      region_id
      status
    }
  }
`;

const UPDATE_CHASE_VEHICLE = gql`
  mutation update_chasevehicle($id: bigint!, $input: chasevehicles_set_input!) {
    update_chasevehicles(where: { id: { _eq: $id } }, _set: $input) {
      returning {
        id
      }
    }
  }
`;

const DELETE_CHASE_VEHICLE = gql`
  mutation delete_chasevehicle($id: bigint!) {
    delete_chasevehicles(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

const ADD_CHASE_VEHICLE = gql`
  mutation add_chasevehicle($input: chasevehicles_insert_input!) {
    insert_chasevehicles(objects: [$input]) {
      returning {
        id
      }
    }
  }
`;

const ADD_CHASE_VEHICLE_ASSIGNMENT = gql`
  mutation add_chasevehicle_assignment($input: chasevehiclesassigned_insert_input!) {
    insert_chasevehiclesassigned(objects: [$input]) {
      returning {
        id
      }
    }
  }
`;

const GET_CUSTOMERS = gql`
  query get_customers {
    customers(order_by: { name: asc }) {
      id
      name
      organization_id
      location {
        region_id
      }
    }
  }
`;

const GET_REGIONS = gql`
  query admin_userDetails_getRegions {
    regions(order_by: { name: asc }) {
      id
      name
    }
  }
`;

export {
  GET_CHASE_VEHICLES,
  GET_ASSIGNED_CHASE_VEHICLES_BY_CHASE_ID,
  UPDATE_CHASE_VEHICLE,
  DELETE_CHASE_VEHICLE,
  ADD_CHASE_VEHICLE,
  ADD_CHASE_VEHICLE_ASSIGNMENT,
  GET_CUSTOMERS,
  GET_REGIONS,
};
