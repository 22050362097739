//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { buildTimeObj, getTimezoneFromMove } from '../../../utils/time';
import { makeStyles, useTheme, Grid } from '@material-ui/core';

import { useTools } from '../../../hooks/useTools';
import { useSettings } from '../providers/SettingsProvider';
import { useRegions } from '../providers/RegionsProvider';

//////////////////////// COMPONENT ////////////////////////

export default function MoveTileTooltip({ enrichedMove = {}, useOverrides = false }) {
  const theme = useTheme();
  const cls = useStyles();

  const {
    capFirst,
    getFormattedStatusFromDrive,
    getFormattedStatusFromRide,
    getFormattedCancelStatusFromDrive,
    getFormattedStatusFromHangtag,
    getFormattedVehicleFromMove,
    getDriveTypeFromMove,
    getFormattedCountdown,
    getCleansedPhoneNumber,
  } = useTools();

  const { timelineDate, enableDebugTooltips } = useSettings();
  const { timezoneOverride } = useRegions();

  let { move = {} } = enrichedMove;
  if (useOverrides) move = enrichedMove.withOverrides;

  const { pickup_started, pickup_arrived, pickup_successful, delivery_started, delivery_arrived, delivery_successful } =
    move;

  const customer = move.customer || {};
  const lane = move.lane || {};
  const pickup = lane.pickup || {};
  const delivery = lane.delivery || {};

  const timezoneOfMove = getTimezoneFromMove(move);
  const noTimezoneMatch = timezoneOfMove !== timezoneOverride;

  // Format iso strings to a readable display format
  const formatTimeDebug = (iso = null) => {
    if (iso) {
      const timeObj = buildTimeObj(iso);
      return timeObj.format(`hh:mm.ss A z`);
    }
    return null;
  };
  const formatDate = (iso = null) => {
    if (iso) {
      const timeObj = buildTimeObj(iso);
      return timeObj.format(`MM/DD/YYYY`);
    }
    return null;
  };

  const formatTime = (iso = null, showTimezone = true) => {
    if (iso) {
      const timeObj = buildTimeObj(iso, timezoneOfMove);
      return timeObj.format(`h:mm A${showTimezone ? ` z` : ``}`);
    }
    return null;
  };
  const formatDateTime = (iso = null) => {
    if (iso) {
      const timeObj = buildTimeObj(iso, timezoneOfMove);
      return timeObj.format(`M/D h:mm A z`);
    }
    return null;
  };

  const formatTimeOverride = (iso = null, showTimezone = true) => {
    if (iso) {
      const timeObj = buildTimeObj(iso, timezoneOverride);
      return timeObj.format(`h:mm A${showTimezone ? ` z` : ``}`);
    }
    return null;
  };
  const formatDateTimeOverride = (iso = null) => {
    if (iso) {
      const timeObj = buildTimeObj(iso, timezoneOverride);
      return timeObj.format(`M/D h:mm A z`);
    }
    return null;
  };

  // Get a readable string to show the delay on a duration
  const getDelayDurationString = (durationExtra = 0) => {
    const durationExtraMin = Number(durationExtra / 60).toFixed(0);

    if (durationExtraMin === `0`) return `Right on time`;
    if (durationExtraMin > 0) return `Behind by ${durationExtraMin} min`;
    if (durationExtraMin < 0) return `Ahead by ${Math.abs(Number(durationExtraMin))} min`;
  };

  // Debug tooltip component
  const DebugTooltip = () => {
    if (enableDebugTooltips) {
      return (
        <>
          <hr className={cls.debugDivider} />

          <Grid container spacing={2} className={cls.debugArea}>
            <Grid item xs={6}>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Minimum&nbsp;Duration:&nbsp;</Grid>
                <Grid item>{enrichedMove.minDuration || 0} sec</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Maximum&nbsp;Duration:&nbsp;</Grid>
                <Grid item>{enrichedMove.maxDuration || 0} sec</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Wait&nbsp;Delay:&nbsp;</Grid>
                <Grid item>{enrichedMove.waitDelay || 0} sec</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Pickup&nbsp;Delay:&nbsp;</Grid>
                <Grid item>{enrichedMove.pickupDelay || 0} sec</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Drive&nbsp;Delay:&nbsp;</Grid>
                <Grid item>{enrichedMove.driveDelay || 0} sec</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Delivery&nbsp;Delay:&nbsp;</Grid>
                <Grid item>{enrichedMove.deliveryDelay || 0} sec</Grid>
              </Grid>

              <hr className={cls.divider} />

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Planned&nbsp;Start&nbsp;Time:&nbsp;</Grid>
                <Grid item>{formatTimeDebug(enrichedMove.plannedStartTime) || `-`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Actual&nbsp;Start&nbsp;Time:&nbsp;</Grid>
                <Grid item>{formatTimeDebug(enrichedMove.actualStartTime) || `-`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Accurate&nbsp;Start&nbsp;Time:&nbsp;</Grid>
                <Grid item>{formatTimeDebug(enrichedMove.accurateStartTime) || `-`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Visual&nbsp;Start&nbsp;Time:&nbsp;</Grid>
                <Grid item>{formatTimeDebug(enrichedMove.visualStartTime) || `-`}</Grid>
              </Grid>

              <hr className={cls.divider} />

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Has&nbsp;Ops&nbsp;Tag:&nbsp;</Grid>
                <Grid item>{enrichedMove.hasOpsTag ? `Yes` : `No`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Has&nbsp;Concierge&nbsp;Tag:&nbsp;</Grid>
                <Grid item>{enrichedMove.hasConciergeTag ? `Yes` : `No`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Has&nbsp;Loaner&nbsp;Tag:&nbsp;</Grid>
                <Grid item>{enrichedMove.hasLoanerTag ? `Yes` : `No`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Has&nbsp;Manual&nbsp;Tag:&nbsp;</Grid>
                <Grid item>{enrichedMove.hasManualTag ? `Yes` : `No`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Has&nbsp;Railyard&nbsp;Tag:&nbsp;</Grid>
                <Grid item>{enrichedMove.hasRailyardTag ? `Yes` : `No`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Has&nbsp;SLA&nbsp;Tag:&nbsp;</Grid>
                <Grid item>{enrichedMove.hasSlaTag ? `Yes` : `No`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Has&nbsp;Notes&nbsp;Tag:&nbsp;</Grid>
                <Grid item>{enrichedMove.hasNotesTag ? `Yes` : `No`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Has&nbsp;More&nbsp;Tag:&nbsp;</Grid>
                <Grid item>{enrichedMove.hasMoreTag ? `Yes` : `No`}</Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Minimum&nbsp;Width:&nbsp;</Grid>
                <Grid item>{enrichedMove.minWidth || 0} px</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Maximum&nbsp;Width:&nbsp;</Grid>
                <Grid item>{enrichedMove.maxWidth || 0} px</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Wait&nbsp;Width&nbsp;Extra:&nbsp;</Grid>
                <Grid item>{enrichedMove.waitWidthExtra || 0} px</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Pickup&nbsp;Width&nbsp;Extra:&nbsp;</Grid>
                <Grid item>{enrichedMove.pickupWidthExtra || 0} px</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Drive&nbsp;Width&nbsp;Extra:&nbsp;</Grid>
                <Grid item>{enrichedMove.driveWidthExtra || 0} px</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Delivery&nbsp;Width&nbsp;Extra:&nbsp;</Grid>
                <Grid item>{enrichedMove.deliveryWidthExtra || 0} px</Grid>
              </Grid>

              <hr className={cls.divider} />

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Planned&nbsp;End&nbsp;Time:&nbsp;</Grid>
                <Grid item>{formatTimeDebug(enrichedMove.plannedEndTime) || `-`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Actual&nbsp;End&nbsp;Time:&nbsp;</Grid>
                <Grid item>{formatTimeDebug(enrichedMove.actualEndTime) || `-`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Accurate&nbsp;End&nbsp;Time:&nbsp;</Grid>
                <Grid item>{formatTimeDebug(enrichedMove.accurateEndTime) || `-`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Visual&nbsp;End&nbsp;Time:&nbsp;</Grid>
                <Grid item>{formatTimeDebug(enrichedMove.visualEndTime) || `-`}</Grid>
              </Grid>

              <hr className={cls.divider} />

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Pixels&nbsp;From&nbsp;Start:&nbsp;</Grid>
                <Grid item>{enrichedMove.startPx || 0} px</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Pixels&nbsp;From&nbsp;Previous:&nbsp;</Grid>
                <Grid item>{enrichedMove.gapPx || 0} px</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Z&#8211;Index:&nbsp;</Grid>
                <Grid item>{enrichedMove.zIndex || 0}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>isDraggable:</Grid>
                <Grid item>{enrichedMove.isDraggable ? 'Yes' : 'No'}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
    return null;
  };

  // Standard tooltip component
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Internal&nbsp;Move&nbsp;ID:&nbsp;</Grid>
            <Grid item>#{move.id || `-`}</Grid>
          </Grid>
          {move.move_type === `drive` ? (
            <>
              {move.reference_num ? (
                <Grid container justifyContent='space-between' alignItems='flex-start'>
                  <Grid item>Reference&nbsp;ID:&nbsp;</Grid>
                  <Grid item>{move.reference_num || `-`}</Grid>
                </Grid>
              ) : null}
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Drive&nbsp;Type:&nbsp;</Grid>
                <Grid item>{capFirst(getDriveTypeFromMove(move)) || `-`}</Grid>
              </Grid>
            </>
          ) : (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Ride&nbsp;Type:&nbsp;</Grid>
              <Grid item>{capFirst(move.ride_type) || `-`}</Grid>
            </Grid>
          )}
          {move.move_type === `drive` ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Status:&nbsp;</Grid>
              <Grid item>{getFormattedStatusFromDrive(move)}</Grid>
            </Grid>
          ) : (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Status:&nbsp;</Grid>
              <Grid item>{getFormattedStatusFromRide(move)}</Grid>
            </Grid>
          )}
          {move.cancel_status ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Cancel&nbsp;Status:&nbsp;</Grid>
              <Grid item>{getFormattedCancelStatusFromDrive(move)}</Grid>
            </Grid>
          ) : null}

          <hr className={cls.divider} />

          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Customer:&nbsp;</Grid>
            <Grid item>{customer.name || `-`}</Grid>
          </Grid>
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Lane:&nbsp;</Grid>
            <Grid item>
              {pickup.name && delivery.name
                ? `${pickup.name} to ${delivery.name}`
                : lane && lane.description
                ? lane.description
                : `-`}
            </Grid>
          </Grid>
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Distance:&nbsp;</Grid>
            <Grid item>{Number(lane.distance_miles).toFixed(1)} mi</Grid>
          </Grid>

          {move.move_type === `drive` ? (
            <>
              <hr className={cls.divider} />

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Vehicle:&nbsp;</Grid>
                <Grid item>{getFormattedVehicleFromMove(move) || `-`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Transmission:&nbsp;</Grid>
                <Grid item>{move.manual_flag ? `Manual` : `Automatic`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>VIN:&nbsp;</Grid>
                <Grid item>{move.vehicle_vin || `-`}</Grid>
              </Grid>
              {move.stock ? (
                <Grid container justifyContent='space-between' alignItems='flex-start'>
                  <Grid item>Stock:&nbsp;</Grid>
                  <Grid item>{move.stock || `-`}</Grid>
                </Grid>
              ) : null}
              {move?.hangtags?.[0]?.hash_id ? (
                <Grid container justifyContent='space-between' alignItems='flex-start'>
                  <Grid item>Hangtag:&nbsp;</Grid>
                  <Grid item>
                    <div className={cls.timeVal}>
                      {move?.hangtags?.[0]?.hash_id} ({getFormattedStatusFromHangtag(move?.hangtags?.[0])})
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {move?.hadHangtag?.aggregate?.count && !move?.hangtags?.[0]?.hash_id ? (
                <Grid container justifyContent='space-between' alignItems='flex-start'>
                  <Grid item>Prior Hangtags:&nbsp;</Grid>
                  <Grid item>
                    <div className={cls.timeVal}>{move?.hadHangtag?.aggregate?.count}</div>
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : null}

          <hr className={cls.divider} />

          {move.move_type === `drive` ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Class:&nbsp;</Grid>
              <Grid item>{capFirst(move.class) || `-`}</Grid>
            </Grid>
          ) : null}
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Chargeable&nbsp;to&nbsp;Customer:&nbsp;</Grid>
            <Grid item>{move.chargeable ? `Yes` : `No`}</Grid>
          </Grid>
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Payable&nbsp;to&nbsp;Driver:&nbsp;</Grid>
            <Grid item>{move.payable ? `Yes` : `No`}</Grid>
          </Grid>

          <hr className={cls.divider} />

          {move.move_type === `drive` ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Return&nbsp;Ride&nbsp;ID:&nbsp;</Grid>
              <Grid item>{move.return_ride_id || `-`}</Grid>
            </Grid>
          ) : (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Lyft&nbsp;Trigger&nbsp;ID:&nbsp;</Grid>
              <Grid item>{move.lyft_trigger_id || `-`}</Grid>
            </Grid>
          )}
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Parent&nbsp;Move&nbsp;ID:&nbsp;</Grid>
            <Grid item>{move.parent_move_id ? move.parent_move_id : `-`}</Grid>
          </Grid>

          <hr className={cls.divider} />

          {move.move_type === `drive` ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Driver&nbsp;Status:&nbsp;</Grid>
              <Grid item>{capFirst(move.driver_status) || `-`}</Grid>
            </Grid>
          ) : null}
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Driver&nbsp;App&nbsp;Version:&nbsp;</Grid>
            <Grid item>
              {move.driver_app_version && move.driver_app_version.startsWith('1')
                ? 'Tookan'
                : move.driver_app_version && !move.driver_app_version.startsWith('1')
                ? move.driver_app_version
                : `-`}
            </Grid>
          </Grid>

          {move.tookan_relationship_id || move.pickup_stop_id || move.delivery_stop_id ? (
            <>
              <hr className={cls.divider} />

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Tookan&nbsp;Relationship&nbsp;ID:&nbsp;</Grid>
                <Grid item>{move.tookan_relationship_id || `-`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Tookan&nbsp;Pickup&nbsp;Stop&nbsp;ID:&nbsp;</Grid>
                <Grid item>{move.pickup_stop_id || `-`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Tookan&nbsp;Delivery&nbsp;Stop&nbsp;ID:&nbsp;</Grid>
                <Grid item>{move.delivery_stop_id || `-`}</Grid>
              </Grid>
            </>
          ) : null}

          {move.move_details ? (
            <>
              <hr className={cls.divider} />

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Notes/Instructions:&nbsp;</Grid>
                <Grid item>{move.move_details || `-`}</Grid>
              </Grid>
            </>
          ) : null}
        </Grid>

        <Grid item xs={6}>
          {move.consumer_name || move.consumer_phone ? (
            <>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Consumer&nbsp;Name:&nbsp;</Grid>
                <Grid item>{move.consumer_name || `-`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Consumer&nbsp;Phone:&nbsp;</Grid>
                <Grid item>{getCleansedPhoneNumber(move.consumer_phone) || `-`}</Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Consumer&nbsp;is&nbsp;at&nbsp;Pickup:&nbsp;</Grid>
                <Grid item>{move.consumer_at_pickup ? `Yes` : `No`}</Grid>
              </Grid>

              <hr className={cls.divider} />
            </>
          ) : null}

          {enrichedMove.actualStartTime && enrichedMove.actualEndTime ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Actual&nbsp;Window:&nbsp;</Grid>
              <Grid item>
                <div className={cls.timeVal}>
                  {formatTimeOverride(enrichedMove.actualStartTime, false)} -{' '}
                  {formatTimeOverride(enrichedMove.actualEndTime)}
                </div>
                {noTimezoneMatch ? (
                  <div className={cls.timeVal2}>
                    {formatTime(enrichedMove.actualStartTime, false)} - {formatTime(enrichedMove.actualEndTime)}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          ) : null}
          {enrichedMove.plannedStartTime ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Planned&nbsp;Window:&nbsp;</Grid>
              <Grid item>
                <div className={cls.timeVal}>
                  {formatTimeOverride(enrichedMove.plannedStartTime, false)} -{' '}
                  {formatTimeOverride(enrichedMove.plannedEndTime)}
                </div>
                {noTimezoneMatch ? (
                  <div className={cls.timeVal2}>
                    {formatTime(enrichedMove.plannedStartTime, false)} -{' '}
                    {enrichedMove.plannedEndTime ? formatTime(enrichedMove.plannedEndTime) : `Unknown`}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          ) : null}
          {enrichedMove.accurateStartTime !== enrichedMove.visualStartTime ||
          enrichedMove.accurateEndTime !== enrichedMove.visualEndTime ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Visual&nbsp;Window:&nbsp;</Grid>
              <Grid item>
                <div className={cls.timeVal}>
                  {formatTimeOverride(enrichedMove.visualStartTime, false)} -{' '}
                  {formatTimeOverride(enrichedMove.visualEndTime)}
                </div>
                {noTimezoneMatch ? (
                  <div className={cls.timeVal2}>
                    {formatTime(enrichedMove.visualStartTime, false)} - {formatTime(enrichedMove.visualEndTime)}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          ) : null}

          <hr className={cls.divider} />

          {enrichedMove.actualDuration ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Actual&nbsp;Duration:&nbsp;</Grid>
              <Grid item>{Number(enrichedMove.actualDuration / 60).toFixed(0)} min</Grid>
            </Grid>
          ) : null}
          {enrichedMove.plannedDuration ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Planned&nbsp;Duration:&nbsp;</Grid>
              <Grid item>{Number(enrichedMove.plannedDuration / 60).toFixed(0)} min</Grid>
            </Grid>
          ) : null}

          {move.move_type === `ride` && enrichedMove.actualWaitDuration > 0 ? (
            <>
              <hr className={cls.divider} />

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Wait&nbsp;Duration:&nbsp;</Grid>
                <Grid item>{Number(enrichedMove.actualWaitDuration / 60).toFixed(0)} min</Grid>
              </Grid>
            </>
          ) : null}
          {move.move_type === `ride` &&
          enrichedMove.actualWaitDuration > 0 &&
          (enrichedMove.extraWaitDuration || enrichedMove.extraWaitDuration === 0) ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Wait&nbsp;Extra:&nbsp;</Grid>
              <Grid item>{getDelayDurationString(enrichedMove.extraWaitDuration)}</Grid>
            </Grid>
          ) : null}
          {move.move_type === `drive` && enrichedMove.actualPickupDuration > 0 ? (
            <>
              <hr className={cls.divider} />

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item>Pickup&nbsp;Inspection:&nbsp;</Grid>
                <Grid item>{Number(enrichedMove.actualPickupDuration / 60).toFixed(0)} min</Grid>
              </Grid>
            </>
          ) : null}
          {move.move_type === `drive` &&
          enrichedMove.actualPickupDuration > 0 &&
          (enrichedMove.extraPickupDuration || enrichedMove.extraPickupDuration === 0) ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Pickup&nbsp;Extra:&nbsp;</Grid>
              <Grid item>{getDelayDurationString(enrichedMove.extraPickupDuration)}</Grid>
            </Grid>
          ) : null}
          {enrichedMove.actualDriveDuration > 0 ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Drive&nbsp;Duration:&nbsp;</Grid>
              <Grid item>{Number(enrichedMove.actualDriveDuration / 60).toFixed(0)} min</Grid>
            </Grid>
          ) : null}
          {enrichedMove.actualDriveDuration > 0 &&
          (enrichedMove.extraDriveDuration || enrichedMove.extraDriveDuration === 0) ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Drive&nbsp;Extra:&nbsp;</Grid>
              <Grid item>{getDelayDurationString(enrichedMove.extraDriveDuration)}</Grid>
            </Grid>
          ) : null}
          {move.move_type === `drive` && enrichedMove.actualDeliveryDuration > 0 ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Delivery&nbsp;Inspection:&nbsp;</Grid>
              <Grid item>{Number(enrichedMove.actualDeliveryDuration / 60).toFixed(0)} min</Grid>
            </Grid>
          ) : null}
          {move.move_type === `drive` &&
          enrichedMove.actualDeliveryDuration > 0 &&
          (enrichedMove.extraDeliveryDuration || enrichedMove.extraDeliveryDuration === 0) ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Delivery&nbsp;Extra:&nbsp;</Grid>
              <Grid item>{getDelayDurationString(enrichedMove.extraDeliveryDuration)}</Grid>
            </Grid>
          ) : null}

          <hr className={cls.divider} />

          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Pickup&nbsp;Started:&nbsp;</Grid>
            <Grid item>{pickup_started ? formatTimeOverride(pickup_started) : `-`}</Grid>
          </Grid>
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Pickup&nbsp;Arrived:&nbsp;</Grid>
            <Grid item>{pickup_arrived ? formatTimeOverride(pickup_arrived) : `-`}</Grid>
          </Grid>
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Pickup&nbsp;Successful:&nbsp;</Grid>
            <Grid item>{pickup_successful ? formatTimeOverride(pickup_successful) : `-`}</Grid>
          </Grid>

          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Delivery&nbsp;Started:&nbsp;</Grid>
            <Grid item>{delivery_started ? formatTimeOverride(delivery_started) : `-`}</Grid>
          </Grid>
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Delivery&nbsp;Arrived:&nbsp;</Grid>
            <Grid item>{delivery_arrived ? formatTimeOverride(delivery_arrived) : `-`}</Grid>
          </Grid>
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Delivery&nbsp;Successful:&nbsp;</Grid>
            <Grid item>{delivery_successful ? formatTimeOverride(delivery_successful) : `-`}</Grid>
          </Grid>

          <hr className={cls.divider} />

          {enrichedMove.accurateStartTime ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Start&nbsp;Time:&nbsp;</Grid>
              <Grid
                item
                style={{
                  color:
                    formatDate(enrichedMove.accurateStartTime) !== formatDate(timelineDate)
                      ? theme.palette.error.main
                      : `inherit`,
                }}
              >
                <div className={cls.timeVal}>{formatDateTimeOverride(enrichedMove.accurateStartTime)}</div>
                {noTimezoneMatch ? (
                  <div className={cls.timeVal2}>{formatDateTime(enrichedMove.accurateStartTime)}</div>
                ) : null}
              </Grid>
            </Grid>
          ) : null}

          {move.ready_by ? (
            <Grid className={cls.backdrop} container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Ready&nbsp;By:&nbsp;</Grid>
              <Grid item>
                <div className={cls.timeVal}>{formatDateTimeOverride(move.ready_by)}</div>
                {noTimezoneMatch ? <div className={cls.timeVal2}>{formatDateTime(move.ready_by)}</div> : null}
              </Grid>
            </Grid>
          ) : null}

          {move.deliver_by ? (
            <Grid className={cls.backdrop} container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Deliver&nbsp;By:&nbsp;</Grid>
              <Grid item>
                <div className={cls.timeVal}>{formatDateTimeOverride(move.deliver_by)}</div>
                {noTimezoneMatch ? <div className={cls.timeVal2}>{formatDateTime(move.deliver_by)}</div> : null}
              </Grid>
            </Grid>
          ) : null}

          {move.synced_with_tookan ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Last&nbsp;Synced:&nbsp;</Grid>
              <Grid item>
                <div className={cls.timeVal}>{formatDateTimeOverride(move.synced_with_tookan)}</div>
                {noTimezoneMatch ? <div className={cls.timeVal2}>{formatDateTime(move.synced_with_tookan)}</div> : null}
              </Grid>
            </Grid>
          ) : null}

          {move.updatedat ? (
            <Grid container justifyContent='space-between' alignItems='flex-start'>
              <Grid item>Last&nbsp;Updated:&nbsp;</Grid>
              <Grid item>
                <div className={cls.timeVal}>{formatDateTimeOverride(move.updatedat)}</div>
                {noTimezoneMatch ? <div className={cls.timeVal2}>{formatDateTime(move.updatedat)}</div> : null}
              </Grid>
            </Grid>
          ) : null}

          <hr className={cls.divider} />

          <Grid className={cls.backdrop} container justifyContent='space-between' alignItems='flex-start'>
            <Grid item>Time&nbsp;Left:&nbsp;</Grid>
            <Grid
              item
              style={{
                color: enrichedMove.deadlineMs < 0 ? theme.palette.error.main : `inherit`,
              }}
            >
              <div className={cls.timeVal}>
                {getFormattedCountdown(enrichedMove.deadlineMs, { format: `dhm` }) || `-`}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DebugTooltip />
    </>
  );
}

//////////////////////// STYLES ////////////////////////
const useStyles = makeStyles(theme => ({
  debugArea: {
    opacity: 0.5,
  },
  divider: {
    width: '100%',
    opacity: 0.5,
  },
  debugDivider: {
    width: '100%',
    opacity: 0.25,
  },
  timeVal: {
    textAlign: 'right',
  },
  timeVal2: {
    opacity: 0.5,
    textAlign: 'right',
  },
}));
